import { useEffect, useRef, useState } from 'react';

import { SellerVendorState, useConnectAccountContext } from 'context/connectAccountContext';
import {
  PublicPartnerType,
  PublicPartnersDocument,
  useConnectAdvertisingMutation,
  useConnectPartnerMutationMutation,
} from 'graphql/types';
import { createRedirectUri } from 'helpers/oauth';
import { exhaustiveCheckNoError } from 'helpers/types';
import { gql } from '@apollo/client';

/**
 * @description Form fields collected to connect a new account.
 */
export type ConnectAccountFormState = {
  /**
   * @description Brand account product type.
   */
  accountType: PublicPartnerType;

  /**
   * @description User-provided brand name.
   */
  brandName: string;

  /**
   * @description Marketplace the brand is in.
   */
  marketplace: string;
};

export enum ConnectAccountSteps {
  /**
   * @description Give the user basic information about the process.
   */
  Information = 'information',

  /**
   * @description Step where use confirms and starts the connection flow.
   */
  Confirmation = 'confirm',

  /**
   * @description Flow is complete; connect authorization and display success/error
   * to the user.
   */
  Complete = 'complete',

  /**
   * @description User selects an advertising profile.
   */
  AdvertisingProfile = 'advertisingprofile',
}

type ConnectState = {
  error: string;
  success: boolean;
};

export type OAuthCallbackState = {
  connect: () => Promise<void>;
  success: boolean;
  errorMessage: string;
};

export function useCompleteConnection(skip?: boolean): OAuthCallbackState {
  const connectCalled = useRef<boolean>(false);

  const { accountType, brandId, oauth, setState } = useConnectAccountContext();
  const { data, errorMessage: oauthError } = oauth || {};

  const [{ error, success }, setConnectState] = useState<ConnectState>({
    error: '',
    success: false,
  });

  const [connectPartner, { error: gqlError, loading: gqlLoading }] = useConnectPartnerMutationMutation({
    refetchQueries: [{ query: PublicPartnersDocument }],
  });

  const [connectAdvertisingPartner, { error: advertisingGqlError, loading: advertisingGqlLoading }] =
    useConnectAdvertisingMutation({
      refetchQueries: [{ query: PublicPartnersDocument }],
    });

  const errorMessage = gqlError?.message || advertisingGqlError?.message || error;

  const tryConnect = async () => {
    if (gqlLoading || advertisingGqlLoading || skip || !brandId || oauthError) {
      return;
    }

    switch (accountType) {
      case PublicPartnerType.Advertising:
        if (!data || !data.code) {
          return;
        }

        await connectAdvertisingPartner({
          variables: {
            code: data.code,
            partnerId: brandId,
            redirectUri: createRedirectUri(),
          },
        });
        setConnectState({
          error: '',
          success: true,
        });
        setState((oldState) => ({
          ...oldState,
          isAuthenticating: false,
        }));
        break;

      case PublicPartnerType.Sellercentral:
      case PublicPartnerType.Vendorcentral:
        const svData = oauth?.data as SellerVendorState;

        if (!svData || !svData.code || !svData.sellingPartnerId) {
          return;
        }

        await connectPartner({
          variables: {
            code: svData.code,
            partnerId: brandId,
            redirectUri: createRedirectUri(),
            sellingPartnerId: svData.sellingPartnerId,
          },
        });
        setConnectState({
          error: '',
          success: true,
        });
        setState((oldState) => ({
          ...oldState,
          isAuthenticating: false,
        }));
        break;

      default:
        exhaustiveCheckNoError(accountType);
    }
  };

  const connect = async () => {
    try {
      await tryConnect();
    } catch (e) {
      setConnectState({ error: 'Failed to connect partner', success: false });
    }
  };

  useEffect(() => {
    if (connectCalled.current || skip) return;

    connectCalled.current = true;
    connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gqlLoading || advertisingGqlLoading || skip || !brandId || oauthError]);

  return {
    connect,
    success,
    errorMessage,
  };
}

export const PublicPartnerGqlQueries = gql`
  mutation ConnectPartnerMutation(
    $code: String!
    $partnerId: String!
    $redirectUri: String!
    $sellingPartnerId: String!
  ) {
    connectPublicPartner(
      code: $code
      partnerId: $partnerId
      redirectUri: $redirectUri
      sellingPartnerId: $sellingPartnerId
    )
  }

  mutation ConnectAdvertising($partnerId: String!, $code: String!, $redirectUri: String!) {
    connectAdvertisingPublicPartner(code: $code, partnerId: $partnerId, redirectUri: $redirectUri)
  }
`;
