import { DataReportUploadFileDefinition } from 'graphql/types';
import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { NodeTableView } from './parts/NodeTableView';
import { useAppSelector } from 'store/hooks';
import { trackGoogleAnalyticsPageView } from 'helpers/analytics';
import { useParams } from 'react-router-dom';

export const NodeView: FC = () => {
  const { property } = useParams<{ property: string }>();

  const selectedNodeData = useAppSelector((state) => state.app.selectedNodeData);
  const data = selectedNodeData ? JSON.parse(selectedNodeData) as DataReportUploadFileDefinition[] : [];

  useEffect(() => {
    if (property) {
      trackGoogleAnalyticsPageView(`/data/${property}`, `Manage Your Data - ${property}`);
    }
  }, [property]);

  return (
    <StyledContainer>
      <NodeTableView
        data={data}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 32px;
`;