import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { FC, PropsWithChildren, useEffect, useMemo, useRef } from 'react';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const APOLLO_CLIENT = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export const ApolloClientProvider: FC<PropsWithChildren> = ({ children }) => {
  const { getIdTokenClaims } = useAuth0();

  const getToken = useRef(getIdTokenClaims);

  useEffect(() => {
    getToken.current = getIdTokenClaims;
  });

  const link = useMemo(() => {
    return setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${(await getToken.current())?.__raw}`,
        },
      };
    });
  }, []);

  useEffect(() => {
    APOLLO_CLIENT.setLink(link.concat(httpLink));
  }, [link]);

  return <ApolloProvider client={APOLLO_CLIENT}>{children}</ApolloProvider>;
};
