import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

export const ManageYourData: FC = () => {
  const { user } = useAuth0();

  return (
    <StyledContainer>
      <StyledBox 
        sx={{ml: '159px', mr: '159px', height: '67px'}}>
        <PlaceholdTypography>[ ANNOUNCEMENT SPACE: ISSUES, PLANNED DOWNTIME, PROMOTIONS ETC. ]</PlaceholdTypography>
        <PlaceholdTypography> [ IF NO ANNOUNCEMENT, THIS DOES NOT DISPLAY]</PlaceholdTypography>
      </StyledBox>
      <Typography sx={{ mt: '12px', fontSize: 12, fontWeight: 800, color: 'black' }} textAlign={'center'}>Welcome {user?.nickname}</Typography>
      <StyledBox 
        sx={{ml: '159px', mr: '159px', mt: '45px', height: '183px'}}>
        <PlaceholdTypography>[DATA HEALTH KPIs]</PlaceholdTypography>
      </StyledBox>			
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledBox = styled(Box)({
  backgroundColor: '#D9D9D9',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
});

const PlaceholdTypography = styled(Typography)({
  fontSize: 12,
  fontWeight: 400,
  color: 'black',
  width: 'fit-content'
});