import { gql } from '@apollo/client';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import styled from 'styled-components';

import { ConnectAccountContextType, useConnectAccountContext } from 'context/connectAccountContext';
import { useMarketplaces } from 'hooks/useMarketplaces';
import { GetLwaClientIdQuery, MarketplaceFragment, PublicPartnerType, useGetLwaClientIdQuery } from 'graphql/types';
import { createRedirectUri, toBase64State } from 'helpers/oauth';
import { LWA_BETA_MODE } from 'models/environment';
import { getAdsAuthorizationUrlFromMarketplace } from 'helpers/amazon';

export type AuthorizeButtonProps = {
  className?: string;
  disabled?: boolean;
  error?: string;
};

export const AuthorizeButton: FC<AuthorizeButtonProps> = ({ className, disabled, error }) => {
  const connectAccountContext = useConnectAccountContext();
  const { getMarketplaceUrl } = useMarketplaces();
  const { data: applicationIdResponse } = useGetLwaClientIdQuery();

  const url = getMarketplaceUrl(connectAccountContext.accountType, connectAccountContext.marketplace);

  const authUrl = createOAuthUrl(connectAccountContext.accountType, url, applicationIdResponse, connectAccountContext);

  let errorMessage = '';
  if (error) {
    errorMessage = error;
  } else if (!authUrl) {
    errorMessage = 'Failed to load authorization url';
  }

  const hasError = !!error;

  return (
    <StyledButtonContainer className={className}>
      <div>
        <Button
          disableElevation
          disabled={!!disabled || !authUrl}
          variant={'contained'}
          href={authUrl || ''}
          target={'_blank'}
        >
          Authorize
        </Button>
      </div>
      <Typography color={hasError ? 'error' : 'caption'} variant={'caption'}>
        {hasError ? errorMessage : 'opens in a new window'}
      </Typography>
    </StyledButtonContainer>
  );
};

function createOAuthUrl(
  accountType: PublicPartnerType,
  url: MarketplaceFragment | undefined,
  applicationId: GetLwaClientIdQuery | undefined,
  context: ConnectAccountContextType
) {
  if (!url || !applicationId) {
    return undefined;
  }

  let authUrl = url?.baseUrl;
  if (accountType === PublicPartnerType.Advertising) {
    authUrl = getAdsAuthorizationUrlFromMarketplace(url);
  }

  if (!authUrl) {
    return undefined;
  }

  const base64 = toBase64State({
    id: context.brandId as string,
    isReauthentication: context.isReauthentication,
    type: accountType,
  });

  const params = new URLSearchParams();

  params.append('state', base64);
  params.append('redirect_uri', createRedirectUri());

  let path: string;
  if (accountType === PublicPartnerType.Advertising) {
    params.append('scope', 'advertising::campaign_management');
    params.append('response_type', 'code');
    params.append('client_id', applicationId?.clientIds.advertisingClientId);
    path = 'ap/oa';
  } else {
    params.append('application_id', applicationId?.clientIds.sellerVendorClientId);
    path = 'apps/authorize/consent';
  }

  if (LWA_BETA_MODE) {
    params.append('version', 'beta');
  }

  // Remove trailing slash if necessary
  if (authUrl.endsWith('/')) {
    authUrl = authUrl.slice(0, -1);
  }

  return `${authUrl}/${path}?${params.toString()}`;
}

const StyledButtonContainer = styled.div`
  align-items: center;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const GetClientIdQuery = gql`
  query GetLWAClientId {
    clientIds {
      advertisingClientId
      sellerVendorClientId
    }
  }
`;
