import { Button, CircularProgress, IconButton, TextField, Typography } from '@mui/material';
import { Modal } from 'components/modal/modal';
import { UserBrand } from 'helpers/userPermissions';
import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import _ from 'lodash';
import { useUserManagementAddPublicUserMutation } from 'graphql/types';
import { green, grey } from '@mui/material/colors';
import { useMuiNotifications } from 'context/muiNotificationContext';

type AddNewUserModalProps = {
  open: boolean;
  brands: UserBrand[];
  email?: string;
  onClose: (reload: boolean) => void;
};

export const AddNewUserModal: FC<AddNewUserModalProps> = ({ open, brands, email, onClose }) => {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const { createSuccessNotification, createFailureNotification } = useMuiNotifications();
  const [publicUserAddMutation, { loading: adding }] = useUserManagementAddPublicUserMutation();
  const [newEmail, setNewEmail] = useState<string | null>(null);
  const [newName, setNewName] = useState<string | null>(null);
  const [data, setData] = useState<UserBrand[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<UserBrand[]>([]);
  const [addable, setAddable] = useState(false);

  const defaultHeight = useMemo(() => {
    if (!email) {
      return 500;
    } else {
      return 400;
    }
  }, [email]);

  const handleAddUser = async () => {
    try {
      if (!!email) {
        const brandIds = selectedBrands.map((e) => e.publicPartnerId);
        const result = await publicUserAddMutation({
          variables: {
            name: email,
            email: email,
            brandIds: brandIds,
          },
        });

        if (result && result.data?.addPublicUser) {
          createSuccessNotification(`Added brand${selectedBrands.length === 1 ? '' : 's'}.`);
          onClose(true);
        }
      } else {
        if (!newName || !newEmail) {
          return;
        }

        const brandIds = selectedBrands.map((e) => e.publicPartnerId);
        const result = await publicUserAddMutation({
          variables: {
            name: newName,
            email: newEmail,
            brandIds: brandIds,
          },
        });

        if (result && result.data?.addPublicUser) {
          createSuccessNotification(`Added brand${selectedBrands.length === 1 ? '' : 's'}.`);
          onClose(true);
        }
      }
    } catch (e) {
      createFailureNotification(`Failed to perform operation. Please contact support. ${e}`);
    }
  };

  useMemo(() => {
    const reference = _.cloneDeep(brands);
    setData(reference);
  }, [brands]);

  useEffect(() => {
    const selected = data.filter((e) => e.isManagingData);
    setSelectedBrands(selected);

    let enableAddUserButton = false;
    if (!!email) {
      enableAddUserButton = data?.some((row) => row.isManagingData);
    } else {
      enableAddUserButton =
        !!newEmail?.trim()?.length && !!newName?.trim()?.length && data?.some((row) => row.isManagingData);
    }

    setAddable(enableAddUserButton);
  }, [data, newEmail, newName, email]);

  const Columns = useMemo(() => {
    const handleSelectDataAccess = (index: number) => {
      const newItem = data[index];
      newItem.isManagingData = !newItem.isManagingData;
      const newData = data.map((item, itemIndex) => (itemIndex === index ? { ...item, ...newItem } : item));
      setData(newData);
    };

    return [
      {
        label: 'Brand Name',
        name: 'name',
      },
      {
        label: 'UUID',
        name: 'reasonPartnerUuid',
      },
      {
        label: 'Type',
        name: 'type',
      },
      {
        label: 'Country',
        name: 'country',
      },
      {
        label: 'Data Access',
        name: 'isManagingData',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex: number) => {
            const checked = data[dataIndex].isManagingData;

            return (
              <StyledIconContainer>
                <IconButton
                  aria-label="user-admin"
                  sx={{ color: checked ? green[500] : grey[500] }}
                  onClick={() => {
                    handleSelectDataAccess(dataIndex);
                  }}
                >
                  <Checkbox checked={checked} color={checked ? 'success' : 'default'} disabled={checked === false} />
                </IconButton>
              </StyledIconContainer>
            );
          },
        },
      },
    ];
  }, [data]);

  const options: MUIDataTableOptions = {
    print: false,
    download: false,
    search: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    pagination: true,
    tableBodyMaxHeight: `${windowHeight - defaultHeight}px`,
    rowsPerPage: Math.trunc((windowHeight - defaultHeight) / 100),
  };

  const getChildren = () => {
    if (adding) {
      return (
        <CircularProgress
          size={40}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        />
      );
    } else {
      return (
        <StyledContainer>
          {!email && (
            <InputRowContainer>
              <InputColumnContainer>
                <InputLabel>Email address</InputLabel>
                <TextField
                  id="email"
                  variant="outlined"
                  size="small"
                  defaultValue={newEmail}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNewEmail(event.target.value);
                  }}
                />
              </InputColumnContainer>
              <InputColumnContainer>
                <InputLabel>Name</InputLabel>
                <TextField
                  id="name"
                  variant="outlined"
                  size="small"
                  defaultValue={newName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNewName(event.target.value);
                  }}
                />
              </InputColumnContainer>
            </InputRowContainer>
          )}
          <InputLabel>Brand Permissions</InputLabel>
          <MUIDataTable title="" columns={Columns} data={data} options={options} />
          <StyledButtonContainer>
            <StyledCancelButton onClick={() => onClose(false)}>CLOSE</StyledCancelButton>
            <StyledButton variant={'contained'} disabled={!addable} onClick={handleAddUser}>
              {!!email ? 'ADD NEW BRANDS' : 'ADD USER'}
            </StyledButton>
          </StyledButtonContainer>
        </StyledContainer>
      );
    }
  };

  const title = useMemo(() => {
    if (!!email) {
      return `Add new brands for ${email}`;
    } else {
      return 'Add a new user';
    }
  }, [email]);

  return (
    <Modal
      width={windowWidth - 480}
      height={windowHeight - 167}
      open={open}
      onClickOutside={() => onClose(false)}
      title={title}
    >
      {getChildren()}
    </Modal>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 24px;
  padding-bottom: 0;
`;

const InputRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  gap: 32px;
`;

const InputColumnContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

const InputLabel = styled(Typography)({
  fontSize: 16,
  fontWeight: 600,
  marginBottom: '4px',
});

const StyledIconContainer = styled.div`
  align-items: left;
  display: flex;
  gap: 8px;
`;

const StyledCancelButton = styled(Button)({
  textTransform: 'none',
  width: '160px',
  height: '40px',
  paddingRight: '16px',
});

const StyledButton = styled(Button)({
  textTransform: 'none',
  width: '160px',
  height: '40px',
  backgroundColor: '#23A4A4',
});
