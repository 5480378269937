import { LWAState } from 'models/lwaState';

export function createRedirectUri(): string {
  return `${window.location.origin}/connect/callback`;
}

export function toBase64State(state: LWAState): string {
  const json = JSON.stringify(state);
  const base64 = window.btoa(json);

  return base64;
}

export function fromBase64State(base64: string): LWAState {
  const json = window.atob(base64);
  const state = JSON.parse(json);

  return state as LWAState;
}
