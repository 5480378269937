import { PublicPartnerType } from 'graphql/types';
import { createContext, useMemo, useState, FC, PropsWithChildren, useContext } from 'react';
import { useLocation } from 'react-router-dom';

export type AdvertisingState = {
  accountType: PublicPartnerType.Advertising;
  code: string;
};
export type SellerVendorState = {
  accountType: PublicPartnerType.Sellercentral | PublicPartnerType.Vendorcentral;
  code: string;
  sellingPartnerId: string;
};

type SetStateType =
  | Partial<ConnectAccountContextType>
  | ((oldState: ConnectAccountContextType) => Partial<ConnectAccountContextType>);

export type ConnectAccountContextType = {
  accountType: PublicPartnerType;
  brandId: string | undefined;
  brandName: string;
  /** @description If true, the auth flow should be initiating a new oauth connection. */
  isAuthenticating: boolean;
  isReauthentication: boolean;
  oauth?: { errorMessage: string; success: boolean; data: SellerVendorState | AdvertisingState };
  marketplace: string;
  setState(newState: SetStateType): void;
};

export type ConnectAccountContextRouteData = {
  accountType: PublicPartnerType;
  id: string;
  brandName: string | undefined;
  isAuthenticating: boolean;
  isReauthentication: boolean;
  marketplace: string;
  oauth?: { errorMessage: string; success: boolean; data: SellerVendorState | AdvertisingState };
};

export const ConnectAccountContext = createContext<ConnectAccountContextType>({
  accountType: PublicPartnerType.Vendorcentral,
  brandId: undefined,
  brandName: '',
  isAuthenticating: false,
  isReauthentication: false,
  marketplace: '',
  setState: () => undefined,
});

export const ConnectAccountContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  const locationState = location.state as ConnectAccountContextRouteData;

  const [state, setState] = useState<Omit<ConnectAccountContextType, 'setState'>>({
    accountType: locationState?.accountType || PublicPartnerType.Vendorcentral,
    brandId: locationState?.id || '',
    brandName: locationState?.brandName || '',
    isAuthenticating: locationState?.isAuthenticating ?? false,
    isReauthentication: locationState?.isReauthentication ?? false,
    marketplace: locationState?.marketplace || '',
    oauth: locationState?.oauth,
  });

  const value = useMemo(() => {
    return {
      ...state,
      setState,
    };
  }, [state]);

  return <ConnectAccountContext.Provider value={value}>{children}</ConnectAccountContext.Provider>;
};

export const useConnectAccountContext = () => {
  return useContext(ConnectAccountContext);
};
