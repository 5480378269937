import { FC, useMemo } from 'react';

import { useConnectAccountContext } from 'context/connectAccountContext';
import { Wizard, WizardStep } from 'components/wizard';
import { ConnectAccountConfirmation } from './ConnectAccountConfirmation';
import { ConnectAccountWizardHeader } from './ConnectAccountWizardHeader';
import { ConnectAccountFormStep } from './ConnectAccountFormStep';
import { ConnectAccountComplete } from './ConnectAccountComplete';
import { PublicPartnerType } from 'graphql/types';
import { SelectAdvertisingProfileStep } from './SelectAdvertisingProfileStep';
import { SelectAdvertisingProfileStepHeader } from './SelectAdvertisingProfileStepHeader';
import { ConnectAccountSteps } from './common';

export const ConnectAccountWizard: FC = () => {
  const { accountType, isAuthenticating } = useConnectAccountContext();

  const steps = useMemo(() => {
    const s: WizardStep[] = [
      {
        label: 'Enter Information',
        name: ConnectAccountSteps.Information,
        header: ConnectAccountWizardHeader,
        render: ConnectAccountFormStep,
      },
      {
        disableNavigateBack: isAuthenticating,
        name: ConnectAccountSteps.Confirmation,
        header: ConnectAccountWizardHeader,
        render: ConnectAccountConfirmation,
      },
    ];

    if (accountType === PublicPartnerType.Advertising) {
      s.push({
        disableNavigateBack: true,
        name: ConnectAccountSteps.AdvertisingProfile,
        label: 'Advertising Profile',
        header: SelectAdvertisingProfileStepHeader,
        render: SelectAdvertisingProfileStep,
      });
    }

    s.push({
      disableNavigateBack: true,
      name: ConnectAccountSteps.Complete,
      render: ConnectAccountComplete,
    });

    return s;
  }, [accountType, isAuthenticating]);

  return <Wizard steps={steps} />;
};
