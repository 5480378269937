import { FC } from 'react';

import { ConnectAccountForm } from './ConnectAccountForm';
import { WizardStepProps } from 'components/wizard';

export type ConnectAccountFormStepProps = WizardStepProps;

export const ConnectAccountFormStep: FC<ConnectAccountFormStepProps> = ({ nextStep }) => {
  return (
    <ConnectAccountForm
      onSubmit={() => {
        nextStep();
      }}
    />
  );
};
