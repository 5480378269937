import { IconButton } from '@mui/material';
import { DataReportBannerAlarmDefinition } from 'graphql/types';
import { FC } from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import parse from 'html-react-parser';
import './BannerAlarmView.css';
import { trackGoogleAnalyticsClickEvent } from 'helpers/analytics';

export type BannerAlarmViewProps = {
  data: DataReportBannerAlarmDefinition;
  isExpanded: boolean;
  onClose: (id: string) => void;
};

export const BannerAlarmView: FC<BannerAlarmViewProps> = ({ data, isExpanded, onClose }) => {
  return (
    <StyledContainer>
      <div style={{
        marginTop: '16px',
        marginBottom: '16px',
        marginLeft: '20px',
        marginRight: data.canBeDiscarded ? '40px' : '20px',
      }}>
        {parse(data.titleHtml ?? '', {
          trim: true,
        })}
      </div>
      {isExpanded && data.detailsHtml && (
        <ScrollableContainer
          className="alarm-html-container"
          style={{
            marginBottom: '16px',
            marginLeft: '20px',
          }}>
          {parse(data.detailsHtml ?? '', {
            trim: true,
          })}
        </ScrollableContainer>
      )}
      {data.canBeDiscarded && (
        <IconButton
          aria-label='close'
          onClick={() => {
            trackGoogleAnalyticsClickEvent('Banner Close', 'Banner');
            onClose(data.id);
          }}
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  flex-direction: column;
  position: relative;
`;

const ScrollableContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
`;
