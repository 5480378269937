import { FC } from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

type DeleteUserModalProps = {
  open: boolean;
  user: string;
  onClose: () => void;
  onDelete: () => void;
};

export const DeleteUserModal: FC<DeleteUserModalProps> = ({ open, user, onClose, onDelete }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          ⚠️ DELETE USER? ⚠️
        </Typography>
        <MessageLabel id="modal-modal-description" sx={{ mt: 2 }}>
          <Typography>
            <b>This cannot be undone!</b>
            <br />
            User <b>{user}</b> will immediately lose access to{' '}
            <StyledAction to={'https://app.reasonautomation.com'} target="_blank">
              app.reasonautomation.com
            </StyledAction>
            .
          </Typography>
        </MessageLabel>
        <StyledButtonContainer>
          <StyledCancelButton onClick={onClose}>Cancel</StyledCancelButton>
          <StyledDeleteButton variant={'contained'} onClick={onDelete}>
            ⚠️ DELETE ⚠️
          </StyledDeleteButton>
        </StyledButtonContainer>
      </Box>
    </Modal>
  );
};

const MessageLabel = styled(Typography)({
  fontSize: 16,
  fontWeight: 400,
  marginBottom: '4px',
  color: '#000',
});

const StyledAction = styled(Link)({
  fontSize: 16,
  fontWeight: 500,
  color: '#23A4A4',
});

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-top: 16px;
`;

const StyledDeleteButton = styled(Button)({
  textTransform: 'none',
  width: '200px',
  height: '48px',
  backgroundColor: '#f00',
  fontSize: 16,
});

const StyledCancelButton = styled(Button)({
  textTransform: 'none',
  width: '200px',
  height: '48px',
  color: '#000',
  fontSize: 16,
});
