import { GA_MEASUREMENT_ID } from 'models/environment';
import ReactGA4 from 'react-ga4';

const initializeGoogleAnalytics = () => {
  const tracking_id = GA_MEASUREMENT_ID;
  ReactGA4.initialize(tracking_id);
};

const trackGoogleAnalyticsEvent = (
  category: 'P0' | 'P1' | 'P2',
  action: string,
  label?: string
) => {
  ReactGA4.event({
    category: category,
    action: action,
    label: label,
  });
};

const trackGoogleAnalyticsClickEvent = (action: string,
  label?: string) => {
  trackGoogleAnalyticsEvent('P2', action, label);
};

const trackGoogleAnalyticsPageView = (
  path: string,
  title: string
) => {
  ReactGA4.send({
    hitType: 'pageview',
    category: 'P0',
    page: path,
    title: title
  });
};

export default initializeGoogleAnalytics;
export { initializeGoogleAnalytics, trackGoogleAnalyticsEvent, trackGoogleAnalyticsClickEvent, trackGoogleAnalyticsPageView };