import { Button, CircularProgress, IconButton, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { green, grey } from '@mui/material/colors';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { DashboardPermissionsModal } from './modals/DashboardPermissionsModal';
import {
  useUserManagementGetPublicUserQuery,
  useUserManagementSetBrandPermissionsPublicUserMutation,
} from 'graphql/types';
import { BrandPermissionStateProps, UserBrand, UserBrandPermission } from 'helpers/userPermissions';
import { AddNewUserModal } from './modals/AddNewUserModal';
import { useMuiNotifications } from 'context/muiNotificationContext';

export const BrandPermissionsView: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const publicUserId = useMemo(() => {
    const state = location.state as BrandPermissionStateProps;
    if (!state) {
      return '';
    }
    return state.userId;
  }, [location]);

  const allBrands = useMemo(() => {
    const state = location.state as BrandPermissionStateProps;
    if (!state) {
      return [];
    }
    return state.brands;
  }, [location]);

  const { createSuccessNotification, createFailureNotification, createInformationNotification } = useMuiNotifications();
  const [setBrandPermissionsMutation, { loading: saving }] = useUserManagementSetBrandPermissionsPublicUserMutation();
  const [email, setEmail] = useState('');
  const [data, setData] = useState<UserBrand[]>([]);
  const [showDashboardModal, setShowDashboardModal] = useState(false);
  const [selectedBrandIndex, setSelectedBrandIndex] = useState<number | null>(null);
  const [changed, setChanged] = useState(false);
  const [openAddBrandsModal, setOpenAddBrandsModal] = useState(false);

  const {
    data: publicUserResponse,
    loading,
    refetch: refetchPublicUser,
  } = useUserManagementGetPublicUserQuery({
    variables: {
      id: publicUserId,
    },
  });

  useEffect(() => {
    if (!publicUserResponse?.getPublicUser?.brands) {
      return;
    }

    const email = publicUserResponse.getPublicUser.email;
    const brands = publicUserResponse?.getPublicUser?.brands.map((brand) => new UserBrandPermission(brand, false));
    setEmail(email);
    setData(brands);
  }, [publicUserResponse]);

  useEffect(() => {
    if (!publicUserResponse?.getPublicUser?.brands) {
      return;
    }

    const brands = publicUserResponse?.getPublicUser?.brands;
    const modifiedBrands = data.filter((brand) => {
      const item = brands.find((e) => e.publicPartnerId === brand.publicPartnerId);
      if (!item) {
        return false;
      } else {
        return (
          item.isManagingData !== brand.isManagingData ||
          item.isAdmin !== brand.isAdmin ||
          item.isBillingAdmin !== brand.isBillingAdmin
        );
      }
    });
    setChanged(modifiedBrands.length > 0);
  }, [data, publicUserResponse]);

  const availableBrands = useMemo(() => {
    const brands = allBrands.filter((e) => !data.some((d) => d.publicPartnerId === e.publicPartnerId));
    return brands;
  }, [allBrands, data]);

  const onClickAddMoreBrands = () => {
    setOpenAddBrandsModal(true);
  };

  const handleAddedMoreBrands = (reload: boolean) => {
    setOpenAddBrandsModal(false);
    if (reload === true) {
      refetchPublicUser();
    }
  };

  const handleSaveChanges = async () => {
    if (!publicUserResponse?.getPublicUser?.brands) {
      return;
    }

    const brands = publicUserResponse?.getPublicUser?.brands;
    const modifiedBrands = data.filter((brand) => {
      const item = brands.find((e) => e.publicPartnerId === brand.publicPartnerId);
      if (!item) {
        return false;
      } else {
        return (
          item.isManagingData !== brand.isManagingData ||
          item.isAdmin !== brand.isAdmin ||
          item.isBillingAdmin !== brand.isBillingAdmin
        );
      }
    });

    let success = true;
    try {
      for (const brand of modifiedBrands) {
        const result = await setBrandPermissionsMutation({
          variables: {
            userId: publicUserId,
            brandId: brand.publicPartnerId,
            isAdmin: brand.isAdmin,
            isBillingAdmin: brand.isBillingAdmin,
            canManage: brand.isManagingData,
          },
        });
        if (result.data?.setBrandPermissionsPublicUser !== true) {
          success = false;
        }
      }
    } catch (e) {
      createFailureNotification(`Failed to perform operation. Please contact support. ${e}`);
    }

    if (success) {
      navigate(-1);
      createSuccessNotification(`Permissions updated.`);
    }
  };

  const handleBackToUsers = () => {
    navigate(-1);
  };

  const Columns = useMemo(() => {
    const handleSelectDataAccess = (index: number) => {
      const newItem = data[index];
      if (newItem.isManagingData && (newItem.isAdmin || newItem.isBillingAdmin)) {
        createInformationNotification('MANAGE DATA permission is required for Admin or Billing Admin privileges');
      } else {
        newItem.isManagingData = !newItem.isManagingData;

        const newData = data.map((item, itemIndex) => (itemIndex === index ? { ...item, ...newItem } : item));
        setData(newData);
      }
    };

    const handleSelectAdmin = (index: number) => {
      const newItem = data[index];
      newItem.isAdmin = !newItem.isAdmin;
      if (newItem.isAdmin) {
        newItem.isManagingData = true;
      }
      const newData = data.map((item, itemIndex) => (itemIndex === index ? { ...item, ...newItem } : item));
      setData(newData);
    };

    const handleSelectBillingAdmin = (index: number) => {
      const newItem = data[index];
      newItem.isBillingAdmin = !newItem.isBillingAdmin;
      if (newItem.isBillingAdmin) {
        newItem.isManagingData = true;
      }
      const newData = data.map((item, itemIndex) => (itemIndex === index ? { ...item, ...newItem } : item));
      setData(newData);
    };

    const handleSelectDashboard = (index: number) => {
      setSelectedBrandIndex(index);
      setShowDashboardModal(true);
    };

    return [
      {
        label: 'Brand Name',
        name: 'name',
      },
      {
        label: 'UUID',
        name: 'reasonPartnerUuid',
      },
      {
        label: 'Type',
        name: 'type',
      },
      {
        label: 'Country',
        name: 'country',
      },
      {
        label: 'Admin',
        name: 'isAdmin',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex: number) => {
            const checked = data[dataIndex].isAdmin;

            return (
              <StyledIconContainer>
                <IconButton
                  sx={{ color: checked ? green[500] : grey[500] }}
                  title="Administrator rights"
                  onClick={() => {
                    handleSelectAdmin(dataIndex);
                  }}
                >
                  <Checkbox checked={checked} color={checked ? 'success' : 'default'} disabled={checked === false} />
                </IconButton>
              </StyledIconContainer>
            );
          },
        },
      },
      {
        label: 'Billing Admin',
        name: 'isBillingAdmin',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex: number) => {
            const checked = data[dataIndex].isBillingAdmin;

            return (
              <StyledIconContainer>
                <IconButton
                  sx={{ color: checked ? green[500] : grey[500] }}
                  title="Billing Administrator rights"
                  onClick={() => {
                    handleSelectBillingAdmin(dataIndex);
                  }}
                >
                  <Checkbox checked={checked} color={checked ? 'success' : 'default'} disabled={checked === false} />
                </IconButton>
              </StyledIconContainer>
            );
          },
        },
      },
      {
        label: 'Manage Data',
        name: 'isManagingData',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex: number) => {
            const checked = data[dataIndex].isManagingData;

            return (
              <StyledIconContainer>
                <IconButton
                  sx={{ color: checked ? green[500] : grey[500] }}
                  title="Manage Data rights"
                  onClick={() => {
                    handleSelectDataAccess(dataIndex);
                  }}
                >
                  <Checkbox checked={checked} color={checked ? 'success' : 'default'} disabled={checked === false} />
                </IconButton>
              </StyledIconContainer>
            );
          },
        },
      },
      {
        label: 'Dashboards',
        name: 'dashboards',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex: number) => {
            const isEmptyDashboards = data[dataIndex].dashboards.length === 0;
            const dashboards = data[dataIndex].dashboards.filter((e) => e.isEnabled);

            const selectTitle = () => {
              if (dashboards && dashboards.length > 0) {
                return `${dashboards.length} selected`;
              } else {
                return isEmptyDashboards ? '' : 'Select';
              }
            };

            return (
              <StyledIconContainer>
                {selectTitle() ? (
                  <StyledRowButton
                    disabled={isEmptyDashboards}
                    variant="contained"
                    title={`Select the dashboard(s) ${email} has access to`}
                    onClick={() => {
                      handleSelectDashboard(dataIndex);
                    }}
                  >
                    {selectTitle()}
                  </StyledRowButton>
                ) : null}
              </StyledIconContainer>
            );
          },
        },
      },
    ];
  }, [data, createInformationNotification, email]);

  const options: MUIDataTableOptions = {
    print: false,
    download: false,
    search: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    pagination: false,
    sortOrder: {
      direction: 'asc',
      name: 'name',
    },
  };

  const getChildren = () => {
    if (loading || saving) {
      return (
        <CircularProgress
          size={40}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        />
      );
    } else {
      return (
        <>
          <StyledButtonContainer>
            <StyledButton variant={'contained'} style={{ width: '200px' }} onClick={onClickAddMoreBrands}>
              ADD MORE BRANDS
            </StyledButton>
          </StyledButtonContainer>
          <MUIDataTable title="" columns={Columns} data={data} options={options} />
          <StyledButtonContainer>
            <StyledButton disabled={!changed} variant={'contained'} onClick={handleSaveChanges}>
              SAVE CHANGES
            </StyledButton>
          </StyledButtonContainer>
          {selectedBrandIndex !== null ? (
            <DashboardPermissionsModal
              open={showDashboardModal}
              email={email}
              userId={publicUserId}
              brandId={data[selectedBrandIndex].publicPartnerId}
              brandName={data[selectedBrandIndex].name}
              dashboards={data[selectedBrandIndex].dashboards}
              onClose={(reload) => {
                setShowDashboardModal(false);
                setSelectedBrandIndex(null);
                if (reload) {
                  refetchPublicUser();
                }
              }}
              onRefreshRequested={() => {
                refetchPublicUser();
              }}
            />
          ) : null}
          <AddNewUserModal
            open={openAddBrandsModal}
            brands={availableBrands}
            email={email}
            onClose={(reload) => handleAddedMoreBrands(reload)}
          />
        </>
      );
    }
  };

  return (
    <StyledContainer>
      <Typography
        textAlign={'left'}
        sx={{
          fontSize: 30,
          fontWeight: 800,
          color: '#000000',
        }}
      >
        Brand Permissions{email ? ` for ${email}` : ''}
      </Typography>
      <StyledNormalTypography>
        Control this user's access to manage data connections and analytics dashboards by brand.
      </StyledNormalTypography>
      <StyledBackButtonContainer>
        <StyledBackButton disabled={loading || saving} onClick={handleBackToUsers}>
          Back to user list
        </StyledBackButton>
      </StyledBackButtonContainer>
      {getChildren()}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
  gap: 8px;
`;

const StyledIconContainer = styled.div`
  align-items: center;
  display: flex;
`;

const StyledRowButton = styled(Button)({
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: '400',
  backgroundColor: '#23A4A4',
});

const StyledNormalTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
});

const StyledBackButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: -4px;
  margin-bottom: 4px;
`;

const StyledBackButton = styled(Button)({
  textTransform: 'none',
  textDecoration: 'underline',
  color: '#23A4A4',
  paddingLeft: 0,
});

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const StyledButton = styled(Button)({
  textTransform: 'none',
  width: '160px',
  height: '40px',
  backgroundColor: '#23A4A4',
});
