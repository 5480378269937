import Paper from '@mui/material/Paper';
import styled from 'styled-components';

import { ConnectAccountContextProvider } from 'context/connectAccountContext';
import { useGetLwaClientIdQuery } from 'graphql/types';
import { ConnectAccountWizard } from './parts/ConnectAccountWizard';
import { useEffect } from 'react';
import { trackGoogleAnalyticsPageView } from 'helpers/analytics';

export const ConnectAccountView = () => {
  useGetLwaClientIdQuery();

  useEffect(() => {
    trackGoogleAnalyticsPageView('/connect', 'Connect Account');
  }, []);

  return (
    <ConnectAccountContextProvider>
      <StyledWizardContainer>
        <ConnectAccountWizard />
      </StyledWizardContainer>
    </ConnectAccountContextProvider>
  );
};

const StyledWizardContainer = styled(Paper)`
  margin: auto;
  padding: 16px;
  width: 50%;
  height: 480px;
  min-width: 450px;
  @media only screen and (max-width: 768px) {
    width: calc(100% - 32px);
  }
`;
