import { Formik, Form } from 'formik';
import { FC } from 'react';
import styled from 'styled-components';

import {
  PublicPartnerFragmentFragment,
  PublicPartnersDocument,
  useGetAdvertisingProfilesQuery,
  useSelectAdvertisingProfileMutation,
} from 'graphql/types';
import { AdvertisingProfileFormFields } from './parts/AdvertisingProfileFormFields';
import { Button } from '@mui/material';
import { useMuiNotifications } from 'context/muiNotificationContext';

export type AdvertisingProfileFormState = {
  id: string;
};

export type AdvertisingProfileFormProps = {
  className?: string;
  onSubmit?: () => void | (() => Promise<void>);
  partner: PublicPartnerFragmentFragment;
};

export const AdvertisingProfileForm: FC<AdvertisingProfileFormProps> = ({ className, onSubmit, partner }) => {
  const { createFailureNotification, createSuccessNotification } = useMuiNotifications();

  const [selectAdvertisingProfile] = useSelectAdvertisingProfileMutation({
    refetchQueries: [{ query: PublicPartnersDocument }],
  });

  const { data: getAdvertisingQueryResponse } = useGetAdvertisingProfilesQuery({
    variables: {
      partnerId: partner.id,
    },
  });

  return (
    <Formik<AdvertisingProfileFormState>
      enableReinitialize
      initialValues={{
        id: partner?.advertisingProfileId || getAdvertisingQueryResponse?.getAdvertisingProfiles?.[0]?.id || '',
      }}
      onSubmit={async (values, formik) => {
        try {
          await selectAdvertisingProfile({
            variables: {
              advertisingProfileId: values.id.toString(),
              partnerId: partner.id,
            },
          });
          createSuccessNotification(`Successfully selected Advertising Profile!`);

          await onSubmit?.();
        } catch (e) {
          createFailureNotification(`Failed to select Advertising Profile. Please try again`);
        }
        formik.setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form className={className} noValidate>
          <AdvertisingProfileFormFields partnerId={partner.id} />
          <StyledButtonContainer>
            <Button disabled={isSubmitting} variant={'contained'} type="submit">
              Continue
            </Button>
          </StyledButtonContainer>
        </Form>
      )}
    </Formik>
  );
};

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;
