import { FC } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DataReportUploadFileDefinition } from 'graphql/types';
import { Button, Typography } from '@mui/material';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

export type FileDownloadButtonProps = {
  partner: DataReportUploadFileDefinition | null;
  loading?: boolean;
  onClicked: () => void;
};

export const FileDownloadButton: FC<FileDownloadButtonProps> = ({ partner, onClicked, loading = false }) => {
  return (
    <StyledButton disabled={loading} onClick={() => onClicked()}>
      <FileDownloadIcon sx={{ color: 'black' }} />
      <StyledTypography sx={{ pl: '8px' }}>Click to download</StyledTypography>
      <StyledFileNameTypography>{partner?.tableName ?? ''}.csv{partner?.hasDataUploaded === true ? '' : ' template'}</StyledFileNameTypography>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </StyledButton>
  );
};

const StyledButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  textTransform: 'none',
});

const StyledTypography = styled(Typography)({
  fontSize: 15,
  fontWeight: 500,
  color: '#23A4A4',
});

const StyledFileNameTypography = styled(StyledTypography)({
  fontWeight: 800,
});