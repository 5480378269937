import countries from 'countries-phone-masks';
import countriesData from 'helpers/countries';
import { useCallback, useMemo } from 'react';

export interface Country {
  name: string;
  code: string;
  iso: string;
  flag: string;
  mask: string;
}

export function useCountries() {
  const getCountryStates = useCallback(
    (value: string) => {
      const country = countriesData.find(c => c.name === value);
      return country?.states ?? [];
    }, []
  );

  const getCountryByName = useCallback(
    (value: string) => {
      const country = countries.find(c => c.name === value);
      return country as Country;
    }, []
  );

  const us = useMemo(() => {
    return countries.find(c => c.iso === 'US') as Country;
  }, []);

  return {
    countries,
    us,
    getCountryStates,
    getCountryByName,
  };
}