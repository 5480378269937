import CircularProgress from '@mui/material/CircularProgress';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer, { TableContainerTypeMap } from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTable, Column } from 'react-table';
import styled from 'styled-components';

export type TableProps<TData extends object> = {
  className?: string;
  columns: Column<TData>[];
  data: TData[];
  loading?: boolean;
};

export const Table = <TData extends object>({ className, columns, data, loading }: TableProps<TData>) => {
  const { rows, prepareRow, headerGroups } = useTable({
    columns,
    data,
  });

  return (
    <StyledTableContainer className={className} component={Paper}>
      <MuiTable>
        <TableHead>
          {headerGroups.map(headerGroup => {
            return (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  return <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row);

            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </MuiTable>
      {loading && (
        <StyledLoaderContainer>
          <CircularProgress />
        </StyledLoaderContainer>
      )}
    </StyledTableContainer>
  );
};

const StyledTableContainer = styled(TableContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
` as OverridableComponent<TableContainerTypeMap>;

const StyledLoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
