import { Modal } from 'components/modal/modal';
import { FC } from 'react';
import styled from 'styled-components';
import PaymentMethodSetupForm from './parts/PaymentMethodSetupForm';

type PaymentMethodModalProps = {
  open: boolean;
  onRequestClose?: () => void;
};

export const PaymentMethodModal: FC<PaymentMethodModalProps> = ({ open, onRequestClose }) => {
  return (
    <Modal onRequestClose={onRequestClose} width={420} height={850} open={open} title="Add Payment Method">
      <StyledContainer>
        <PaymentMethodSetupForm onRequestClose={onRequestClose} />
      </StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
