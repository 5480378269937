import { FC } from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { UserBrandDashboard } from 'helpers/userPermissions';

type SaveDashboardPermissionsModalProps = {
  open: boolean;
  email: string;
  brand: string;
  dashboards: UserBrandDashboard[];
  onClose: () => void;
  onSave: () => void;
};

export const SaveDashboardPermissionsModal: FC<SaveDashboardPermissionsModalProps> = ({
  open,
  email,
  brand,
  dashboards,
  onClose,
  onSave,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 480,
    bgcolor: '#fff',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
  };

  const listItems = dashboards.map((dashboard) => (
    <li>
      {dashboard.isEnabled ? 'Assign' : 'Unassign'} {dashboard.name} to {brand} for {email}.
    </li>
  ));

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Confirmation
        </Typography>
        <MessageLabel id="modal-modal-description" sx={{ mt: 2 }}>
          You are making the following changes:
        </MessageLabel>
        <ul id="modal-modal-description" style={{ paddingInlineStart: 24 }}>
          {listItems}
        </ul>
        <StyledButtonContainer>
          <StyledCancelButton onClick={onClose}>CANCEL</StyledCancelButton>
          <StyledSaveButton variant={'contained'} onClick={onSave}>
            SAVE CHANGES
          </StyledSaveButton>
        </StyledButtonContainer>
      </Box>
    </Modal>
  );
};

const MessageLabel = styled(Typography)({
  fontSize: 16,
  fontWeight: 400,
  marginBottom: '4px',
  color: '#000',
});

const StyledButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 24px;
  margin-top: 48px;
`;

const StyledSaveButton = styled(Button)({
  textTransform: 'none',
  width: '200px',
  height: '48px',
  backgroundColor: '#23A4A4',
  fontSize: 16,
});

const StyledCancelButton = styled(Button)({
  textTransform: 'none',
  width: '200px',
  height: '48px',
  color: '#000',
  fontSize: 16,
});
