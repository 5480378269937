import { FC, createContext, useContext, useState, useMemo, PropsWithChildren } from 'react';

export type NotificationType = 'danger' | 'information' | 'processing' | 'success' | 'warning';

export type MuiNotification = {
  /**
   * Notification message.
   */
  message: string;
  /**
   * Optional duration the notification will be shown (ms).
   *
   * If not set, notifications are persistant.
   */
  duration?: number;
  /**
   * Type/style of the notification to show.
   */
  notificationType: NotificationType;
};

/**
 * Mui notification context type.
 */
export type MuiNotificationContextType = {
  /** List of notifications. */
  notifications: MuiNotification[];
  /** Create a notification, specifying all options. */
  createNotification(notification: MuiNotification): void;
  /** Remove the currently displayed notification. */
  removeNotification(): void;
  /** Directly update notification state. */
  setNotifications(fn: (oldState: MuiNotification[]) => MuiNotification[]): void;

  /** Shortcut for creating an information notification. */
  createInformationNotification(message: string, duration?: number): void;
  /** Shortcut for creating a failure notification. */
  createFailureNotification(message: string, duration?: number): void;
  /** Shortcut for creating a success notification. */
  createSuccessNotification(message: string, duration?: number): void;
};

const NOOP = () => undefined;
export const MuiNotificationContext = createContext<MuiNotificationContextType>({
  notifications: [],
  createNotification: NOOP,
  removeNotification: NOOP,
  setNotifications: NOOP,

  createInformationNotification: NOOP,
  createFailureNotification: NOOP,
  createSuccessNotification: NOOP,
});

/**
 * Hook which returns the current mui notification context value.
 *
 * @returns Mui notification context value.
 */
export function useMuiNotifications(): MuiNotificationContextType {
  return useContext(MuiNotificationContext);
}

export const MuiNotificationContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [notifications, setNotifications] = useState<MuiNotification[]>([]);

  const context = useMemo<MuiNotificationContextType>(() => {
    function createNotification(notification: MuiNotification): void {
      const newNotification: MuiNotification = {
        duration: 3000,
        ...notification,
      };

      setNotifications(n => [...n, newNotification]);
    }

    return {
      notifications,
      createNotification,
      removeNotification: () => {
        setNotifications(n => (n.length ? n.slice(1, n.length) : n));
      },
      setNotifications,

      createInformationNotification: (message, duration) =>
        createNotification({
          message,
          duration,
          notificationType: 'information',
        }),
      createFailureNotification: (message, duration) =>
        createNotification({
          message,
          duration,
          notificationType: 'danger',
        }),
      createSuccessNotification: (message, duration) =>
        createNotification({
          message,
          duration,
          notificationType: 'success',
        }),
    };
  }, [notifications, setNotifications]);

  return <MuiNotificationContext.Provider value={context}>{children}</MuiNotificationContext.Provider>;
};
