import { useRef, useEffect } from 'react';

/**
 * Hook which memoizes the previous version of the input value.
 *
 * @param value Value to store.
 * @returns The previous version of the value, before the input changed.
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T | undefined>(undefined);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
