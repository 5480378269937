import { FC, useEffect, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button, Checkbox, FormControlLabel, Typography, Link } from '@mui/material';
import styled from 'styled-components';
import { actions } from 'store/reducers/app';
import { SetupIntent } from '@stripe/stripe-js';
import { useAppDispatch } from 'store/hooks';

type PaymentMethodSetupFormProps = {
  onRequestClose?: () => void;
};

const PaymentMethodSetupForm: FC<PaymentMethodSetupFormProps> = ({ onRequestClose }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [checked, setChecked] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [status, setStatus] = useState<SetupIntent.Status | null>(null);
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!status) {
      return;
    }
    if (!paymentMethodId) {
      return;
    }

    dispatch(actions.setPaymentMethodAddingStatus(status));
    dispatch(actions.setPaymentMethodAddingMethodId(paymentMethodId));

    if (onRequestClose) {
      onRequestClose();
    }
  }, [status, paymentMethodId, onRequestClose, dispatch]);

  const handleCheck = () => {
    setChecked(!checked);
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    dispatch(actions.setPaymentMethodSetDefault(checked));

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    stripe
      .confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/account/billing`,
        },
        redirect: 'if_required',
      })
      .then(function (result) {
        if (result.error) {
          setErrorMessage(result.error.message);
          return;
        }

        const setupIntent = result.setupIntent;
        if (!setupIntent) {
          return;
        }

        setStatus(setupIntent.status);

        if (setupIntent.status === 'succeeded') {
          const methodId = setupIntent.payment_method as string;
          if (!!methodId) {
            setPaymentMethodId(methodId);
          }
        }
      });
  };

  return (
    <StyledContainer>
      <PaymentElement
        options={{
          layout: {
            type: 'tabs',
            defaultCollapsed: false,
          },
        }}
      />
      <StyledHWrapper>
        <FormControlLabel
          control={<Checkbox defaultChecked value={checked} onChange={handleCheck} />}
          label={<StyledBoldTypography>Use as default payment method</StyledBoldTypography>}
        />
      </StyledHWrapper>
      <StyledTPWrapper>
        <StyledSpan>You can review important information from Reason Automation LLC on their </StyledSpan>
        <StyledLink href="https://www.reasonautomation.com/terms-of-use" target="blank" underline="none" rel="noopener">
          Terms of Service
        </StyledLink>
        <StyledSpan> and </StyledSpan>
        <StyledLink href="https://www.reasonautomation.com/privacy" target="blank" underline="none" rel="noopener">
          Privacy Policy
        </StyledLink>
        <StyledSpan> pages.</StyledSpan>
      </StyledTPWrapper>
      <StyledContainer>
        <StyledButton
          sx={{ mt: '16px' }}
          variant="contained"
          disabled={!stripe || !elements}
          onClick={() => handleSubmit()}
        >
          Add
        </StyledButton>
        <StyledButton variant="outlined" onClick={() => onRequestClose?.()}>
          Go back
        </StyledButton>
        {errorMessage && <StyledErrorContainer>{errorMessage}</StyledErrorContainer>}
      </StyledContainer>
    </StyledContainer>
  );
};

export default PaymentMethodSetupForm;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledHWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTPWrapper = styled.div`
  line-height: 1.1;
`;

const StyledErrorContainer = styled.div`
  color: red;
  margin-top: 8px;
`;

const StyledBoldTypography = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: ${(p) => p.theme.palette.text.secondary};
  align-self: left;
`;

const StyledLink = styled(Link)`
  font-size: 13px;
  font-weight: 500;
  color: ${(p) => p.theme.palette.text.secondary};
  align-self: left;
`;

const StyledSpan = styled.span`
  font-size: 13px;
  color: ${(p) => p.theme.palette.text.secondary};
  align-self: left;
`;

const StyledButton = styled(Button)`
  display: flex;
`;
