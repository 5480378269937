import { green, grey } from '@mui/material/colors';
import { Modal } from 'components/modal/modal';
import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Typography, Button } from '@mui/material';
import '../modals/css/InvoiceDetailsModal.css';
import { StripeCurrencyType, StripeInvoice } from 'graphql/types';
import moment from 'moment';

type InvoiceDetailsModalProps = {
  invoice: StripeInvoice;
  open: boolean;
  onRequestClose?: () => void;
};

export const InvoiceDetailsModal: FC<InvoiceDetailsModalProps> = ({ invoice, open, onRequestClose }) => {
  const handleDownloadInvoice = () => {
    const url = invoice.invoicePdf;
    if (!url) {
      return;
    }

    window.open(url, '_blank')?.focus();
  };

  const handleViewReceipt = () => {
    const url = invoice.receiptUrl;
    if (!url) {
      return;
    }

    window.open(url, '_blank')?.focus();
  };

  const priceString = useMemo(() => {
    let symbol = '$';
    switch (invoice.currency) {
      case StripeCurrencyType.Usd:
        symbol = '$';
        break;
    }
    return symbol + (invoice.amountPaid / 100).toFixed(2).toString();
  }, [invoice]);

  const dateString = useMemo(() => {
    if (!invoice.dueDate) {
      return '';
    }
    return moment(invoice.dueDate).format('MMMM D, YYYY');
  }, [invoice]);

  const paymentMethod = useMemo(() => {
    let name = '';
    if (invoice.cardType) {
      const cardType = invoice.cardType ?? '';
      name = cardType.charAt(0).toUpperCase() + cardType.slice(1);
    } else {
      const bankName = invoice.bankName ?? '';
      name = bankName.charAt(0).toUpperCase() + bankName.slice(1);
    }

    const lastFourDigits = invoice.cardLast4 ?? invoice.bankAccountLast4;

    name = name + ' ···· ' + lastFourDigits ?? '';

    return name;
  }, [invoice]);

  return (
    <Modal onRequestClose={onRequestClose} width={500} height={548} open={open} title={invoice.accountName}>
      <StyledContainer>
        <TopContainer>
          <StatusContainer>
            <span className="the-wrapper">
              <i className="fa-solid fa-file-invoice-dollar" style={{ color: grey[400], fontSize: 72, fontWeight: 400 }}></i>
              <div className="icon">
                <i className="fa-solid fa-circle-check" style={{ color: green[600], fontSize: 24, fontWeight: 400 }}></i>
              </div>
            </span>
          </StatusContainer>
          <LabelTypography>Invoice {invoice.status}</LabelTypography>
          <PriceTypography>{priceString}</PriceTypography>
          <LabelTypography>Invoice and payment details</LabelTypography>
          <InfoRowContainer style={{ paddingTop: 36 }}>
            <LabelTypography>Invoice number</LabelTypography>
            <DetailTypography>{invoice.invoiceNumber}</DetailTypography>
          </InfoRowContainer>
          <InfoRowContainer>
            <LabelTypography>Payment date</LabelTypography>
            <DetailTypography>{dateString}</DetailTypography>
          </InfoRowContainer>
          <InfoRowContainer>
            <LabelTypography>Payment method</LabelTypography>
            <DetailTypography>{paymentMethod}</DetailTypography>
          </InfoRowContainer>
        </TopContainer>
        <InfoRowContainer style={{ gap: 16 }}>
          <StyledButton variant='outlined' onClick={handleDownloadInvoice}>Download invoice</StyledButton>
          <StyledButton variant='contained' onClick={handleViewReceipt}>
            <StyledRowContainer>
              <ButtonTypography>View receipt</ButtonTypography>
              <i className="fa-solid fa-up-right-from-square fa-xs"></i>
            </StyledRowContainer>
          </StyledButton>
        </InfoRowContainer>
      </StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100%;
  padding: 16px;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StatusContainer = styled.div`
  text-align: center;
  padding-bottom: 24px;
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const InfoRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
`;

const LabelTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
  color: '#616161',
  textAlign: 'center',
});

const ButtonTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
  textAlign: 'center',
});

const DetailTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
});

const PriceTypography = styled(Typography)({
  fontSize: 36,
  fontWeight: 400,
  textAlign: 'center',
});

const StyledButton = styled(Button)({
  textTransform: 'none',
  display: 'flex',
  width: '100%',
});