import { FC } from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

type BackfillOrderConfirmPurchaseModalProps = {
  open: boolean;
  onClose: () => void;
  onPurchase: () => void;
};

export const BackfillOrderConfirmPurchaseModal: FC<BackfillOrderConfirmPurchaseModalProps> = ({ open, onClose, onPurchase }) => {
  const style = {
    position: 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 440,
    bgcolor: '#333',
    border: '2px solid #333',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: '#fff', marginBottom: 2, textAlign: 'center', }}>
          Confirm purchase
        </Typography>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: '#fff', marginBottom: 4, textAlign: 'center', }}>
          $35
        </Typography>
        <MessageLabel id="modal-modal-description" sx={{ mt: 2 }}>
          Your backfill will be billed and begin immediately after submitting. We are unable to cancel or refund backfill orders.
        </MessageLabel>
        <StyledButtonContainer>
          <StyledButton variant={'contained'} onClick={onPurchase}>
            Start backfill
          </StyledButton>
        </StyledButtonContainer>
      </Box>
    </Modal>
  );
};

const MessageLabel = styled(Typography)({
  fontSize: 16,
  fontWeight: 400,
  marginBottom: '4px',
  color: '#fff',
});

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)({
  textTransform: 'none',
  width: '200px',
  height: '48px',
  backgroundColor: '#23A4A4',
  fontSize: 16,
  marginTop: 48,
});