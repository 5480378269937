import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import {
  MarketplaceFragment,
  MarketplaceUrlProductType,
  PublicPartnerType,
  useGetMarketplacesQuery,
} from 'graphql/types';

const PRODUCTS: MarketplaceUrlProductType[] = [
  MarketplaceUrlProductType.Sellercentral,
  MarketplaceUrlProductType.Vendorcentral,
  MarketplaceUrlProductType.Advertising,
];

export function useMarketplaces() {
  const { data, loading } = useGetMarketplacesQuery();

  const { marketplaceByProduct } = useMemo(() => {
    const marketplaceByProduct: Record<PublicPartnerType, MarketplaceFragment[]> = {
      SELLERCENTRAL: [],
      VENDORCENTRAL: [],
      ADVERTISING: [],
    };

    if (!data?.marketplaceUrls) {
      return { marketplaceByProduct };
    }

    for (const mp of data?.marketplaceUrls) {
      marketplaceByProduct[mp.productType].push(mp);
    }

    marketplaceByProduct.SELLERCENTRAL.sort((a, b) => a.marketplace.localeCompare(b.marketplace));
    marketplaceByProduct.VENDORCENTRAL.sort((a, b) => a.marketplace.localeCompare(b.marketplace));
    marketplaceByProduct.ADVERTISING.sort((a, b) => a.marketplace.localeCompare(b.marketplace));

    return { marketplaceByProduct };
  }, [data?.marketplaceUrls]);

  const getMarketplaceUrl = useCallback(
    (product: PublicPartnerType, marketplace: string) => {
      const obj = marketplaceByProduct[product];

      return obj?.find(m => m.marketplace === marketplace);
    },
    [marketplaceByProduct]
  );

  return {
    marketplaceByProduct,
    getMarketplaceUrl,
    products: PRODUCTS,
    loading,
  };
}

export const GetMarketplacesQuery = gql`
  query GetMarketplaces {
    marketplaceUrls {
      ...Marketplace
    }
  }

  fragment Marketplace on MarketplaceUrl {
    id
    baseUrl
    baseAdvertisingUrl
    marketplace
    productType
  }
`;
