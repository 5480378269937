import { IconButton, Typography } from '@mui/material';
import { PublicPartnerCredentialsPostgreSql } from 'graphql/types';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { FC, useMemo } from 'react';
import styled from 'styled-components';
import '../../../assets/css/fontawesome.css';
import '../../../assets/css/brands.css';
import '../../../assets/css/solid.css';

export type PostgresDatabaseProps = {
  data: PublicPartnerCredentialsPostgreSql[];
};

export const PostgresDatabase: FC<PostgresDatabaseProps> = ({ data }) => {
  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRowsHideCheckboxes: true,
  };

  const columns = useMemo(() => {
    const columns = [
      {
        label: 'Host',
        name: 'host',
        options: {
          filter: true,
          sort: true,
          customBodyRenderLite: (dataIndex: number) => {
            const original = data[dataIndex];
            return (
              <StyledHContainer>
                <Typography>{original.host}</Typography>
                <IconButton onClick={() => {
                  navigator.clipboard.writeText(original.host);
                }}>
                  <i className="fa-solid fa-copy"></i>
                </IconButton>
              </StyledHContainer>
            );
          },
        },
      },
      {
        label: 'Port',
        name: 'port',
        options: {
          filter: true,
          sort: true,
          customBodyRenderLite: (dataIndex: number) => {
            const original = data[dataIndex];
            return (
              <StyledHContainer>
                <Typography>{original.port}</Typography>
                <IconButton onClick={() => {
                  navigator.clipboard.writeText('' + original.port);
                }}>
                  <i className="fa-solid fa-copy"></i>
                </IconButton>
              </StyledHContainer>
            );
          },
        },
      },
      {
        label: 'Database',
        name: 'database',
        options: {
          filter: true,
          sort: true,
          customBodyRenderLite: (dataIndex: number) => {
            const original = data[dataIndex];
            return (
              <StyledHContainer>
                <Typography>{original.database}</Typography>
                <IconButton onClick={() => {
                  navigator.clipboard.writeText(original.database);
                }}>
                  <i className="fa-solid fa-copy"></i>
                </IconButton>
              </StyledHContainer>
            );
          },
        },
      },
      {
        label: 'User Name',
        name: 'userName',
        options: {
          filter: true,
          sort: true,
          customBodyRenderLite: (dataIndex: number) => {
            const original = data[dataIndex];
            return (
              <StyledHContainer>
                <Typography>{original.userName}</Typography>
                <IconButton onClick={() => {
                  navigator.clipboard.writeText(original.userName);
                }}>
                  <i className="fa-solid fa-copy"></i>
                </IconButton>
              </StyledHContainer>
            );
          },
        },
      },
      {
        label: 'Password',
        name: 'password',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex: number) => {
            const original = data[dataIndex];
            const hpwd = '********';

            return (
              <StyledHContainer>
                <Typography>{hpwd}</Typography>
                <IconButton onClick={() => {
                  navigator.clipboard.writeText(original.password);
                }}>
                  <i className="fa-solid fa-copy"></i>
                </IconButton>
              </StyledHContainer>
            );
          },
        },
      },
    ];
    return columns;
  }, [data]);

  return <MUIDataTable title='POSTGRESQL' columns={columns} data={data} options={options} />;
};

const StyledHContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
