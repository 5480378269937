import { Grid } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { Providers } from 'components/providers/Providers';
import { PrivateRoute } from 'components/privateRoute/PrivateRoute';
import { Drawer } from 'components/drawer/Drawer';
import { trackGoogleAnalyticsEvent } from 'helpers/analytics';

export const AppLayout: FC = () => {
  trackGoogleAnalyticsEvent('P0', 'Load App');
  return (
    <Providers>
      <PrivateRoute>
        <Layout>
          <Outlet />
        </Layout>
      </PrivateRoute>
    </Providers>
  );
};

const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Drawer>
      <StyledPageContainer container padding={1} flexDirection={'column'}>
        {children}
      </StyledPageContainer>
    </Drawer>
  );
};

const StyledPageContainer = styled(Grid)`
  height: 100%;
`;
