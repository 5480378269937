import { FC, useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
  LinkAuthenticationElement,
} from '@stripe/react-stripe-js';

export const CheckoutForm: FC = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const handleSubmit = async () => {
    if (elements == null || stripe == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError?.message) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }
    const clientSecret = 'sk_test_51OejC6BKE8O1M4uf1uaZLogSC06IwaMlVRYvudo8FZQoJQ5ozY3uRHYe3jJWsecdx7PvFBjoHZc1cYEZSrYW1HXZ00u0vRMtlU';
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}/success`,
      },
    });

    if (result.error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit} className='px-4'>
      <h3>Contact info</h3>
      <LinkAuthenticationElement />
      <h3>Payment</h3>
      <PaymentElement
        options={{
          defaultValues: {
            billingDetails: {
              address: {
                postal_code: '10001',
                country: 'US',
              }
            },
          },
        }}
      />
      <button type="submit" disabled={!stripe || !elements}>
        Pay
      </button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};
