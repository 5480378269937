import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { ConnectAccountSteps } from 'components/connectAccountView/parts/common';
import { PublicPartnerType } from 'graphql/types';
import { fromBase64State } from 'helpers/oauth';
import { exhaustiveCheckNoError } from 'helpers/types';
import { LWAState } from 'models/lwaState';
import { AdvertisingState, ConnectAccountContextRouteData, SellerVendorState } from 'context/connectAccountContext';

type ConnectState = {
  data: AdvertisingState | SellerVendorState | undefined;
  isReauthentication: boolean;
  partnerId: string;
  error: string;
  success: boolean;
  initialized: boolean;
};

export const LWACallback = () => {
  const [params] = useSearchParams();

  const [connectState, setConnectState] = useState<ConnectState>({
    data: undefined,
    partnerId: '',
    isReauthentication: false,
    error: '',
    success: false,
    initialized: false,
  });

  useEffect(() => {
    const base64State = params.get('state');

    let state: LWAState | undefined = undefined;
    try {
      state = fromBase64State(base64State || '');
    } catch {}

    if (!state) {
      setConnectState((s) => ({
        ...s,
        initialized: true,
        error: 'Missing required parameters',
      }));

      return;
    }

    let code: string | null;
    let partnerId: string | null;
    switch (state.type) {
      case PublicPartnerType.Advertising:
        code = params.get('code');

        if (!code || !state.id) {
          setConnectState((s) => ({
            ...s,
            initialized: true,
            error: 'Missing required parameters',
          }));
          return;
        }

        const adsState: AdvertisingState = { code, accountType: PublicPartnerType.Advertising };

        setConnectState({
          data: adsState,
          partnerId: state.id,
          error: '',
          initialized: true,
          success: true,
          isReauthentication: state.isReauthentication,
        });
        break;

      case PublicPartnerType.Sellercentral:
      case PublicPartnerType.Vendorcentral:
        partnerId = params.get('selling_partner_id');
        code = params.get('spapi_oauth_code');

        if (!code || !partnerId || !state.id) {
          setConnectState((s) => ({
            ...s,
            initialized: true,
            error: 'Missing required parameters',
          }));
          return;
        }

        const svState: SellerVendorState = {
          sellingPartnerId: partnerId,
          code,
          accountType: state.type,
        };

        setConnectState({
          data: svState,
          partnerId: state.id,
          error: '',
          initialized: true,
          success: true,
          isReauthentication: state.isReauthentication,
        });
        break;

      default:
        exhaustiveCheckNoError(state.type);
    }
  }, [params]);

  if (!connectState.initialized || !connectState.data) {
    return <CircularProgress />;
  }

  const step =
    connectState.data.accountType === PublicPartnerType.Advertising && !connectState.isReauthentication
      ? ConnectAccountSteps.AdvertisingProfile
      : ConnectAccountSteps.Complete;

  const context: ConnectAccountContextRouteData = {
    accountType: connectState.data.accountType,
    id: connectState.partnerId,
    brandName: '',
    isAuthenticating: true,
    isReauthentication: connectState.isReauthentication,
    oauth: {
      data: connectState.data,
      errorMessage: connectState.error,
      success: connectState.success,
    },
    marketplace: '',
  };

  return <Navigate replace to={`/connect/${step}`} state={context} />;
};
