import Typography from '@mui/material/Typography';
import { FC, PropsWithChildren } from 'react';

export type ValueProps = {
  label: string;
};

export const Value: FC<PropsWithChildren<ValueProps>> = ({ label, children }) => {
  return (
    <>
      <Typography variant={'body2'}>{label}</Typography>
      <Typography variant={'body2'}>{children}</Typography>
    </>
  );
};
