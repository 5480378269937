import MuiTextField, { TextFieldVariants } from '@mui/material/TextField';
import { useField } from 'formik';
import { FC } from 'react';

export type TextFieldProps = {
  className?: string;
  fullWidth?: boolean;
  label: string;
  name: string;
  required?: boolean;
  placeholder?: string | undefined;
  variant?: TextFieldVariants;
  shrink?: boolean;
};

export const TextField: FC<TextFieldProps> = ({ className, fullWidth = true, label, name, required, placeholder = undefined, variant = 'outlined', shrink = undefined }) => {
  const [fieldProps, { error }] = useField(name);

  return (
    <MuiTextField
      fullWidth={fullWidth}
      className={className}
      label={label}
      {...fieldProps}
      error={!!error}
      helperText={error}
      required={required}
      placeholder={placeholder}
      variant={variant}
      InputLabelProps={{ shrink: shrink }}
    />
  );
};