import { gql } from '@apollo/client';
import { CircularProgress, Typography } from '@mui/material';
import { usePpGetPbiTokenMutation } from 'graphql/types';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { PowerBIReportView } from './parts/PowerBIReportView';
import { trackGoogleAnalyticsPageView } from 'helpers/analytics';

export const ReportView: FC = () => {
  const { groupId, id } = useParams<{ groupId: string, id: string }>();
  const [getPbiTokenMutation, { loading, data, error }] = usePpGetPbiTokenMutation();

  useEffect(() => {
    if (groupId && id) {
      trackGoogleAnalyticsPageView(`/reports/${groupId}/${id}`, 'PBI Report');

      getPbiTokenMutation({
        variables: {
          groupId: groupId,
          reportId: id
        }
      });
    }
  }, [groupId, id, getPbiTokenMutation]);

  return (
    <StyledContainer>
      {loading ? (
        <CircularProgress
          size={40}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        />
      ) :
        data ?
          (
            <PowerBIReportView
              reportId={id}
              groupId={groupId}
              accessToken={data?.generatePowerBIReportContext?.accessToken}
              embedUrl={data?.generatePowerBIReportContext?.embedUrl} />
          ) : (
            <Typography>{error?.message ?? ''}</Typography>
          )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const PowerBITokenMutation = gql`
  mutation pp_getPBIToken($reportId: String!, $groupId: String!){
    generatePowerBIReportContext(reportId: $reportId, groupId: $groupId) {
      embedUrl
      accessToken
    }
  }
`;