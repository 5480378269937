import { Marketplace, MarketplaceFragment } from 'graphql/types';
import { exhaustiveCheckNoError } from './types';

/**
 * @description Map a marketplace to an advertising baseUrl to use for authorization.
 *
 * @param marketplace User-specified marketplace.
 * @returns {string} BaseUrl to use to authorize to ads api.
 */
export function getAdsAuthorizationUrlFromMarketplace(marketplace: MarketplaceFragment): string {
  // Url reference: https://advertising.amazon.com/API/docs/en-us/guides/get-started/create-authorization-grant#determine-the-url-prefix-for-your-region
  // Mapping of regions: https://developer-docs.amazon.com/amazon-shipping/docs/sp-api-endpoints
  switch (marketplace.marketplace) {
    case Marketplace.Ca:
    case Marketplace.Mx:
    case Marketplace.Us:
    case Marketplace.Br:
    case Marketplace.Za:
    case 'BR' as unknown as Marketplace:
      return 'https://www.amazon.com';

    case Marketplace.Ae:
    case Marketplace.Be:
    case Marketplace.De:
    case Marketplace.Eg:
    case Marketplace.Es:
    case Marketplace.Eu:
    case Marketplace.Fr:
    case Marketplace.Gb:
    case Marketplace.In:
    case Marketplace.It:
    case Marketplace.Nl:
    case Marketplace.Pl:
    case Marketplace.Sa:
    case Marketplace.Se:
    case Marketplace.Tr:
    case Marketplace.Uk:
      return 'https://eu.account.amazon.com';

    case Marketplace.Au:
    case Marketplace.Jp:
    case Marketplace.Sg:
      return 'https://apac.account.amazon.com';

    default:
      exhaustiveCheckNoError(marketplace.marketplace);
  }

  return '';
}
