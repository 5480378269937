import { Button, CircularProgress, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { gql } from '@apollo/client';
import { AlertDialog, AlertType } from 'components/dialog/AlertDialog';
import {
  StripeCurrencyType,
  StripePeriodType,
  useStripeCancelPlanMutation,
  useStripeGetPlanInformationQuery,
} from 'graphql/types';
import { useAppSelector } from 'store/hooks';
import moment from 'moment';
import { ExpandLess } from '@mui/icons-material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { BillingCurrentPlanSeatsModal } from '../modals/BillingCurrentPlanSeatsModal';
import { useMuiNotifications } from 'context/muiNotificationContext';

export const BillingCurrentPlanView: FC = () => {
  const { createSuccessNotification, createFailureNotification } = useMuiNotifications();
  const partnerId = useAppSelector((state) => state.app.partnerId);
  const [stripeCancelPlanMutation, { loading: loadingCancel }] = useStripeCancelPlanMutation();
  const [showDetails, setShowDetails] = useState(false);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showDoneAlert, setShowDoneAlert] = useState(false);
  const [showSeatsModal, setShowSeatsModal] = useState(false);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.Warning);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [selectedDashboardName, setSelectedDashboardName] = useState('');
  const [selectedBrandId, setSelectedBrandId] = useState('');
  const [selectedBrandName, setSelectedBrandName] = useState('');

  const {
    data: planInformationResponse,
    loading: loadingPlanInfo,
    refetch: refetchPlanInformationQuery,
  } = useStripeGetPlanInformationQuery({
    variables: {
      partnerId: partnerId ?? '',
    },
  });

  useEffect(() => {
    refetchPlanInformationQuery();
  }, [partnerId, refetchPlanInformationQuery]);

  const handleCancelPlan = useCallback(() => {
    setAlertType(AlertType.Warning);
    setAlertTitle('Plan cancellation');
    setAlertMessage('Are you sure you want to cancel your plan?');
    setShowConfirmAlert(true);
  }, []);

  const handleConfirmOK = useCallback(async () => {
    setShowConfirmAlert(false);

    if (!partnerId) {
      return;
    }

    try {
      const result = await stripeCancelPlanMutation({
        variables: {
          partnerId: partnerId,
        },
      });

      if (result && result.data?.cancelPlanStripe === true) {
        setAlertType(AlertType.Success);
        setAlertTitle('Plan cancellation request submitted');
        setAlertMessage(
          'Your plan cancellation request has been submitted. We will be contacting you for the next steps.'
        );
        setShowDoneAlert(true);
        createSuccessNotification(`Cancelled plan request submitted.`);
      }
    } catch (e) {
      createFailureNotification(`Failed to perform operation. Please contact support. ${e}`);
    }
  }, [partnerId, stripeCancelPlanMutation, createFailureNotification, createSuccessNotification]);

  const handleConfirmCancel = useCallback(() => {
    setShowConfirmAlert(false);
  }, []);

  const handleDoneOK = useCallback(() => {
    setShowDoneAlert(false);
    refetchPlanInformationQuery();
  }, [refetchPlanInformationQuery]);

  const handleViewDetails = useCallback(() => {
    if (showDetails) {
      setShowDetails(false);
    } else {
      setShowDetails(true);
    }
  }, [showDetails]);

  const handleLinkDashboard = useCallback((dashboardName: string, brandId: string, brandName: string) => {
    setSelectedBrandId(brandId);
    setSelectedDashboardName(dashboardName);
    setSelectedBrandName(brandName);
    setShowSeatsModal(true);
  }, []);

  const handleCloseSeatsModal = () => {
    setSelectedBrandId('');
    setSelectedDashboardName('');
    setSelectedBrandName('');
    setShowSeatsModal(false);
  };

  const subscriptions = useMemo(() => {
    const information = planInformationResponse?.getPlanInformationStripe;
    if (!information) {
      return [];
    }
    return information.subscriptions;
  }, [planInformationResponse]);

  const seats = useMemo(() => {
    const information = planInformationResponse?.getPlanInformationStripe;
    if (!information) {
      return [];
    }
    return information.seats;
  }, [planInformationResponse]);

  const carouselChildren = useMemo(() => {
    const subscriptionView = (subscription: typeof subscriptions[0], index: number) => {
      const name = subscription.name ?? '';

      const resCurrency = subscription.renewAmountCurrency;
      let currency = '$';
      if (!!resCurrency) {
        switch (resCurrency) {
          case StripeCurrencyType.Usd:
            currency = '$';
            break;
        }
      }

      const amount = (subscription.renewAmount ?? 0) / 100;
      const amountString = amount.toFixed(2).toString();

      let period = 'month';
      const resPeriod = subscription.renewPeriodType;
      if (!!resPeriod) {
        switch (resPeriod) {
          case StripePeriodType.Monthly:
            period = 'month';
            break;
        }
      }

      const priceString = currency + amountString + ' per ' + period;

      let renewDate = '';
      const resRenewOn = subscription.renewOn;
      if (!!resRenewOn) {
        renewDate = `Your plan renews on ${moment(resRenewOn).format('MMMM D, YYYY')}`;
      }

      return (
        <StyledSliderContainer key={`subs_${index} `}>
          <AnalyticsTypography>{name}</AnalyticsTypography>
          <PriceTypography>{priceString}</PriceTypography>
          <PlanTypography>{renewDate}</PlanTypography>
          <CancelButton variant="outlined" onClick={handleCancelPlan}>
            Cancel plan
          </CancelButton>
        </StyledSliderContainer>
      );
    };

    const seatView = (seat: typeof seats[0], index: number) => {
      const name = seat.name;
      const brandId = seat.brand.publicPartnerId;
      const brandName = seat.brand.name;
      const dashboard = seat.brandDashboard.name as string;
      const seatAssignment = (seat.brandDashboardSeat.assignedToEmail as string) ?? '';
      console.log(`seat`, seat);
      const resCurrency = seat.renewAmountCurrency;
      let currency = '$';
      if (!!resCurrency) {
        switch (resCurrency) {
          case StripeCurrencyType.Usd:
            currency = '$';
            break;
        }
      }

      const amount = (seat.renewAmount ?? 0) / 100;
      const amountString = amount.toFixed(2).toString();

      let period = 'month';
      const resPeriod = seat.renewPeriodType;
      if (!!resPeriod) {
        switch (resPeriod) {
          case StripePeriodType.Monthly:
            period = 'month';
            break;
        }
      }

      const priceString = currency + amountString + ' per ' + period;

      let renewDate = '';
      const resRenewOn = seat.renewOn;
      if (!!resRenewOn) {
        renewDate = `Your seat ${seat.brandDashboardSeat.isSoftCancelled ? 'cancels on' : 'renews on'} ${moment(resRenewOn).format('MMMM D, YYYY')}`;
      }

      return (
        <StyledSliderContainer key={`seat_${index} `}>
          <AnalyticsTypography>{name}</AnalyticsTypography>
          <PriceTypography>{priceString}</PriceTypography>
          <PlanTypography>{renewDate}</PlanTypography>
          {dashboard.length > 0 && (
            <StyledBrandButtonContainer style={{ marginTop: -8 }}>
              <StyledBrandButton onClick={() => handleLinkDashboard(dashboard, brandId, brandName)}>
                {dashboard}
              </StyledBrandButton>
            </StyledBrandButtonContainer>
          )}
          {seatAssignment.length > 0 && (
            <StyledBrandButtonContainer style={{ marginTop: -8, marginBottom: 8 }}>
              <StyledBrandButton onClick={() => handleLinkDashboard(dashboard, brandId, brandName)}>
                {seatAssignment}
              </StyledBrandButton>
            </StyledBrandButtonContainer>
          )}
        </StyledSliderContainer>
      );
    };

    let children: JSX.Element[] = [];

    subscriptions.forEach((subscription, index) => {
      const view = subscriptionView(subscription, index);
      children = [...children, view];
    });

    seats.forEach((seat, index) => {
      const view = seatView(seat, index);
      children = [...children, view];
    });

    return children;
  }, [subscriptions, seats, handleCancelPlan, handleLinkDashboard]);

  const cardIcon = useMemo(() => {
    switch (planInformationResponse?.getPlanInformationStripe?.cardType?.toLowerCase()) {
      case 'visa':
        return <i className="fa-brands fa-cc-visa" style={{ color: '#00589b' }}></i>;
      case 'mastercard':
        return <i className="fa-brands fa-cc-mastercard" style={{ color: '#00589b' }}></i>;
      case 'discover':
        return <i className="fa-brands fa-cc-discover" style={{ color: '#00589b' }}></i>;
      case 'amex':
        return <i className="fa-brands fa-cc-amex" style={{ color: '#00589b' }}></i>;
      case 'diners':
        return <i className="fa-brands fa-cc-diners-club" style={{ color: '#00589b' }}></i>;
      case 'jcb':
        return <i className="fa-brands fa-cc-jcb" style={{ color: '#00589b' }}></i>;
      case 'Apple Pay':
        return <i className="fa-brands fa-cc-apple-pay" style={{ color: '#00589b' }}></i>;
      case 'Google Pay':
        return <i className="fa-brands fa-google-pay" style={{ color: '#00589b' }}></i>;
      default:
        return <i className="fa-solid fa-credit-card" style={{ color: '#00589b' }}></i>;
    }
  }, [planInformationResponse]);

  const methodName = useMemo(() => {
    let name = '';
    if (planInformationResponse?.getPlanInformationStripe?.cardType) {
      const cardType = planInformationResponse?.getPlanInformationStripe?.cardType ?? '';
      name = cardType.charAt(0).toUpperCase() + cardType.slice(1);
    } else {
      const bankName = planInformationResponse?.getPlanInformationStripe?.bankName ?? '';
      name = bankName.charAt(0).toUpperCase() + bankName.slice(1);
    }

    return name;
  }, [planInformationResponse]);

  const cardView = useMemo(() => {
    return (
      <CardView>
        <StyledTypography>{methodName}</StyledTypography>
        <Typography sx={{ fontWeight: 'bold', fontSize: 22 }}>····</Typography>
        <StyledTypography>
          {planInformationResponse?.getPlanInformationStripe?.cardLast4 ??
            planInformationResponse?.getPlanInformationStripe?.bankAccountLast4}
        </StyledTypography>
      </CardView>
    );
  }, [planInformationResponse, methodName]);

  const getChildren = () => {
    if (loadingCancel || loadingPlanInfo) {
      return (
        <CircularProgress
          size={40}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        />
      );
    } else {
      return (
        <>
          {(subscriptions.length > 0 || seats.length > 0) && (
            <CarouselPlaceholder>
              <Carousel
                autoPlay={true}
                showArrows={false}
                infiniteLoop={true}
                showStatus={false}
                showIndicators={true}
                swipeable={true}
                showThumbs={false}
                stopOnHover={true}
                emulateTouch={true}
                interval={10000}
                renderIndicator={(
                  onClickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
                  isSelected: boolean,
                  index: number,
                  label: string
                ) => (
                  <StyledIndicator
                    style={{ opacity: isSelected ? '0.6' : '0.3' }}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    aria-label={`${label} ${index + 1}`}
                  />
                )}
              >
                {carouselChildren}
              </Carousel>
            </CarouselPlaceholder>
          )}
          {subscriptions.length === 0 && seats.length === 0 && (
            <StyledTypography>No active plans. Please add reports.</StyledTypography>
          )}
          {(subscriptions.length > 0 || seats.length > 0) && (
            <ViewButton
              variant="text"
              endIcon={showDetails ? <ExpandLess /> : <ExpandMoreIcon />}
              onClick={handleViewDetails}
            >
              {showDetails ? 'Hide details' : 'View details'}
            </ViewButton>
          )}
          {showDetails && methodName.length > 0 && (
            <StyledColumnView>
              {cardIcon}
              {cardView}
            </StyledColumnView>
          )}
          <AlertDialog
            showDialog={showConfirmAlert}
            type={alertType}
            title={alertTitle}
            message={alertMessage}
            okTitle="Yes"
            onOK={handleConfirmOK}
            onClose={handleConfirmCancel}
          />
          <AlertDialog
            showDialog={showDoneAlert}
            type={alertType}
            title={alertTitle}
            message={alertMessage}
            okTitle="Ok"
            onOK={handleDoneOK}
          />
          {selectedDashboardName ? (
            <BillingCurrentPlanSeatsModal
              open={showSeatsModal}
              brandId={selectedBrandId}
              brandName={selectedBrandName}
              dashboardName={selectedDashboardName}
              onClose={handleCloseSeatsModal}
            />
          ) : null}
        </>
      );
    }
  };

  return <StyledContainer>{getChildren()}</StyledContainer>;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  padding-top: 8px;
  padding-bottom: 60px;
  max-width: 800px;
`;

const StyledSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  text-align: left;
  padding-bottom: 32px;
`;

const StyledColumnView = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 24px;
  gap: 10px;
  width: 30%;
  align-items: center;
`;

const CarouselPlaceholder = styled.div`
  width: 100%;
`;

const AnalyticsTypography = styled(Typography)({
  fontSize: 18,
  fontWeight: 400,
  marginTop: '8px',
});

const PriceTypography = styled(Typography)({
  fontSize: 20,
  fontWeight: 600,
});

const StyledTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
});

const ViewButton = styled(Button)({
  fontSize: 12,
  fontWeight: 400,
  width: 'fit-content',
  textTransform: 'none',
  color: '#424242',
  padding: 0,
  minHeight: 0,
  minWidth: 0,
});

const PlanTypography = styled(Typography)({
  fontSize: 12,
  fontWeight: 400,
  marginTop: '4px',
});

const CancelButton = styled(Button)({
  fontSize: 14,
  fontWeight: 400,
  textTransform: 'none',
  color: 'black',
  width: '160px',
  height: '40px',
  position: 'absolute',
  top: 20,
  right: 20,
  borderColor: 'darkgrey',
});

const StyledBrandButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const StyledBrandButton = styled(Button)({
  textTransform: 'none',
  textDecoration: 'underline',
  paddingLeft: 0,
});

const CardView = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

const StyledIndicator = styled.li`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 8px;
  margin-right: 8px;
  background: #000;
  cursor: pointer;
`;

export const StripeGetPlanInformationQuery = gql`
  query StripeGetPlanInformation($partnerId: String!) {
    getPlanInformationStripe(partnerId: $partnerId) {
      subscriptions {
        ...PlanSubscription
      }
      seats {
        ...PlanSeat
      }
      renewUsingPaymentMethodId
      renewUsingPaymentMethodType
      cardType
      cardLast4
      cardExpirationMonth
      cardExpirationYear
      bankAccountType
      bankAccountLast4
      bankName
    }
  }

  fragment PlanSubscription on StripePlanInformationSubscription {
    name
    renewOn
    renewAmount
    renewPeriodType
    renewAmountCurrency
  }

  fragment PlanSeat on StripePlanInformationSeat {
    name
    brand {
      publicPartnerId
      name
    }
    brandDashboard {
      name
    }
    brandDashboardSeat {
      id
      isSoftCancelled
      isFreeSeat
      assignedToEmail
      assignedToPublicUser {
        id
        email
      }
    }
    renewOn
    renewAmount
    renewPeriodType
    renewAmountCurrency
  }
`;

export const StripeCancelPlanMutation = gql`
  mutation StripeCancelPlan($partnerId: String!) {
    cancelPlanStripe(partnerId: $partnerId)
  }
`;

export const StripeGetPublicPartnerDashboardQuery = gql`
  query StripeGetPublicPartnerDashboard($partnerId: String!, $name: String!) {
    publicPartnerDashboard(partnerId: $partnerId, name: $name) {
      name
      isEnabled
      seats {
        id
        isSoftCancelled
        isFreeSeat
        assignedToEmail
        assignedToPublicUser {
          id
          email
        }
      }
    }
  }
`;

export const PPGetPublicPartnerUsersQuery = gql`
  fragment PPPublicPartnerUser on PublicPartnerUser {
    id
    email
    status
    isManagingData
  }

  query PPGetPublicPartnerUsers($partnerId: String!) {
    publicPartner(partnerId: $partnerId) {
      users {
        ...PPPublicPartnerUser
      }
    }
  }
`;

// cSpell:word swipeable
