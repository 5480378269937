import { FC } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

import { MuiNotification } from 'context/muiNotificationContext';

export type NotificationProps = MuiNotification & {
  onRequestClose(): void;
};

export const Notification: FC<NotificationProps> = ({ onRequestClose, ...notification }) => {
  let severity: AlertColor;
  let color = 'white';
  switch (notification.notificationType) {
    case 'information':
    case 'processing':
    default:
      severity = 'info';
      break;

    case 'warning':
      severity = 'warning';
      color = 'black';
      break;

    case 'success':
      severity = 'success';
      break;

    case 'danger':
      severity = 'error';
      break;
  }

  return (
    <StyledAlert
      action={
        <IconButton aria-label={'Dismiss Notification'} color={'inherit'} onClick={onRequestClose} size={'small'}>
          <CloseIcon />
        </IconButton>
      }
      fontcolor={color}
      severity={severity}
      variant={'filled'}
    >
      {notification.message}
    </StyledAlert>
  );
};

const StyledAlert = styled(Alert) <{ fontcolor: string }>`
  bottom: 24px;
  color: ${p => p.fontcolor};
  position: fixed;
  left: 24px;
  width: 400px;
  z-index: 10000000;

  @media screen and (max-width: 768px) {
    bottom: 12px;
    left: 12px;
    right: 12px;
    width: unset;
  }
`;
