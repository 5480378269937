import IconButton from '@mui/material/IconButton';
import { FC } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DataReportUploadFileDefinition } from 'graphql/types';

export type DownloadIconButtonProps = {
  partner: DataReportUploadFileDefinition;
  onDownload: (data: DataReportUploadFileDefinition) => void;
};

export const DownloadIconButton: FC<DownloadIconButtonProps> = ({ partner, onDownload }) => {
  return (
    <IconButton
      onClick={() => {
        onDownload(partner);
      }}
      title={'Template or Download'}
    >
      <FileDownloadIcon/>
    </IconButton>
  );
};