import { Box, Button, IconButton, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import '../../../../assets/css/fontawesome.css';
import '../../../../assets/css/brands.css';
import '../../../../assets/css/solid.css';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { actions } from 'store/reducers/app';
import {
  useStripeGetPaymentMethodsQuery,
  useStripeRemovePaymentMethodMutation,
  useStripeSetPaymentMethodAsDefaultMutation,
} from 'graphql/types';
import { PaymentMethodModal } from '../modals/PaymentMethodModal';
import { SPaymentMethod } from '../../../../helpers/payments';
import { AlertDialog, AlertType } from 'components/dialog/AlertDialog';
import { useMuiNotifications } from 'context/muiNotificationContext';

export const BillingPaymentMethodView = () => {
  const { createSuccessNotification, createFailureNotification } = useMuiNotifications();
  const partnerId = useAppSelector((state) => state.app.partnerId);
  const reloadPaymentMethods = useAppSelector((state) => state.app.reloadPaymentMethods);
  const [stripeRemovePaymentMethodMutation, { loading: removeLoading }] = useStripeRemovePaymentMethodMutation();
  const [stripeSetPaymentMethodAsDefaultMutation, { loading: defaultLoading }] =
    useStripeSetPaymentMethodAsDefaultMutation();

  const [cards, setCards] = useState<SPaymentMethod[]>([]);
  const [showModal, setShowModal] = useState(false);

  const [selectedMethod, setSelectedMethod] = useState<SPaymentMethod | null>(null);
  const [showRemoveConfirmAlert, setShowRemoveConfirmAlert] = useState(false);
  const [showDefaultConfirmAlert, setShowDefaultConfirmAlert] = useState(false);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.Warning);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const dispatch = useAppDispatch();

  const {
    data: methodsResponse,
    refetch: refetchMethodsQuery,
    loading: fetchLoading,
  } = useStripeGetPaymentMethodsQuery({
    variables: {
      partnerId: partnerId ?? '',
    },
  });

  const loading = useMemo(() => {
    return fetchLoading || removeLoading || defaultLoading;
  }, [fetchLoading, removeLoading, defaultLoading]);

  const handleSetPaymentMethodAsDefault = useCallback((method: SPaymentMethod) => {
    let name = '';
    if (method.type === 'card') {
      const cardType = method.cardType ?? '';
      name = cardType.charAt(0).toUpperCase() + cardType.slice(1);
    } else {
      const bankName = method.bankName ?? '';
      name = bankName.charAt(0).toUpperCase() + bankName.slice(1);
    }

    const lastFour = method.cardLast4 ?? method.bankAccountLast4 ?? '';
    const message = 'Are you sure you want to set the ' + name + '····' + lastFour + ' payment method as default?';

    setSelectedMethod(method);
    setAlertType(AlertType.Warning);
    setAlertTitle('Set payment method as default');
    setAlertMessage(message);
    setShowDefaultConfirmAlert(true);
  }, []);

  const handleDefaultConfirmOK = useCallback(async () => {
    setShowDefaultConfirmAlert(false);

    if (!partnerId) {
      return;
    }

    if (!selectedMethod) {
      return;
    }

    try {
      const resultDefault = await stripeSetPaymentMethodAsDefaultMutation({
        variables: {
          partnerId: partnerId,
          paymentMethodId: selectedMethod.paymentMethodId,
        },
      });

      if (resultDefault && resultDefault.data?.setPaymentMethodAsDefaultStripe === true) {
        refetchMethodsQuery();
        createSuccessNotification(`Updated default payment method.`);
      }
    } catch (e) {
      createFailureNotification(`Failed to perform operation. Please contact support. ${e}`);
    }
  }, [
    partnerId,
    selectedMethod,
    stripeSetPaymentMethodAsDefaultMutation,
    refetchMethodsQuery,
    createFailureNotification,
    createSuccessNotification,
  ]);

  const handleDefaultConfirmCancel = useCallback(() => {
    setSelectedMethod(null);
    setShowDefaultConfirmAlert(false);
  }, []);

  const handleRemove = useCallback((method: SPaymentMethod) => {
    let name = '';
    if (method.type === 'card') {
      const cardType = method.cardType ?? '';
      name = cardType.charAt(0).toUpperCase() + cardType.slice(1);
    } else {
      const bankName = method.bankName ?? '';
      name = bankName.charAt(0).toUpperCase() + bankName.slice(1);
    }

    const lastFour = method.cardLast4 ?? method.bankAccountLast4 ?? '';
    const message = 'Are you sure you want to delete the ' + name + '····' + lastFour + ' payment method?';

    setSelectedMethod(method);
    setAlertType(AlertType.Warning);
    setAlertTitle('Delete payment method');
    setAlertMessage(message);
    setShowRemoveConfirmAlert(true);
  }, []);

  const handleRemoveConfirmOK = useCallback(async () => {
    setShowRemoveConfirmAlert(false);

    if (!partnerId) {
      return;
    }

    if (!selectedMethod) {
      return;
    }

    try {
      const resultRemove = await stripeRemovePaymentMethodMutation({
        variables: {
          partnerId: partnerId,
          paymentMethodId: selectedMethod.paymentMethodId,
        },
      });

      if (resultRemove && resultRemove.data?.removePaymentMethodStripe === true) {
        refetchMethodsQuery();
        setSelectedMethod(null);
        createSuccessNotification(`Removed payment method method.`);
      }
    } catch (e) {
      createFailureNotification(`Failed to perform operation. Please contact support. ${e}`);
    }
  }, [
    partnerId,
    selectedMethod,
    stripeRemovePaymentMethodMutation,
    refetchMethodsQuery,
    createFailureNotification,
    createSuccessNotification,
  ]);

  const handleRemoveConfirmCancel = useCallback(() => {
    setSelectedMethod(null);
    setShowRemoveConfirmAlert(false);
  }, []);

  useEffect(() => {
    const methods = (methodsResponse?.getPaymentMethodsStripe as [SPaymentMethod]) ?? [];
    setCards(methods);
  }, [methodsResponse]);

  useEffect(() => {
    if (!partnerId || !reloadPaymentMethods) {
      return;
    }

    dispatch(actions.setReloadPaymentMethods(false));
    refetchMethodsQuery();
  }, [partnerId, reloadPaymentMethods, refetchMethodsQuery, dispatch]);

  return (
    <>
      <StyledContainer>
        {cards.map((c) => (
          <PaymentMethodView
            key={`${c.type}_${c.paymentMethodId}`}
            payment={c}
            loading={loading}
            onRemove={() => {
              handleRemove(c);
            }}
            onDefault={() => {
              handleSetPaymentMethodAsDefault(c);
            }}
          />
        ))}
        <AddButton startIcon={<AddIcon />} onClick={() => setShowModal(true)}>
          Add payment method
        </AddButton>
      </StyledContainer>
      <PaymentMethodModal open={showModal} onRequestClose={() => setShowModal(false)} />
      <AlertDialog
        showDialog={showRemoveConfirmAlert}
        type={alertType}
        title={alertTitle}
        message={alertMessage}
        okTitle="Yes"
        onOK={handleRemoveConfirmOK}
        onClose={handleRemoveConfirmCancel}
      />
      <AlertDialog
        showDialog={showDefaultConfirmAlert}
        type={alertType}
        title={alertTitle}
        message={alertMessage}
        okTitle="Yes"
        onOK={handleDefaultConfirmOK}
        onClose={handleDefaultConfirmCancel}
      />
    </>
  );
};

type PaymentMethodViewProps = {
  payment: SPaymentMethod;
  loading: boolean;
  onRemove: () => void;
  onDefault: () => void;
};

const PaymentMethodView: FC<PaymentMethodViewProps> = ({ payment, loading, onRemove, onDefault }) => {
  const cardIcon = useMemo(() => {
    switch (payment.cardType?.toLowerCase()) {
      case 'visa':
        return <i className="fa-brands fa-cc-visa" style={{ color: '#00589b' }}></i>;
      case 'mastercard':
        return <i className="fa-brands fa-cc-mastercard" style={{ color: '#00589b' }}></i>;
      case 'discover':
        return <i className="fa-brands fa-cc-discover" style={{ color: '#00589b' }}></i>;
      case 'amex':
        return <i className="fa-brands fa-cc-amex" style={{ color: '#00589b' }}></i>;
      case 'diners':
        return <i className="fa-brands fa-cc-diners-club" style={{ color: '#00589b' }}></i>;
      case 'jcb':
        return <i className="fa-brands fa-cc-jcb" style={{ color: '#00589b' }}></i>;
      case 'Apple Pay':
        return <i className="fa-brands fa-cc-apple-pay" style={{ color: '#00589b' }}></i>;
      case 'Google Pay':
        return <i className="fa-brands fa-google-pay" style={{ color: '#00589b' }}></i>;
      default:
        return <i className="fa-solid fa-credit-card" style={{ color: '#00589b' }}></i>;
    }
  }, [payment]);

  const methodName = useMemo(() => {
    let name = '';
    if (payment.type === 'card') {
      const cardType = payment.cardType ?? '';
      name = cardType.charAt(0).toUpperCase() + cardType.slice(1);
    } else {
      const bankName = payment.bankName ?? '';
      name = bankName.charAt(0).toUpperCase() + bankName.slice(1);
    }

    return name;
  }, [payment]);

  const cardView = useMemo(() => {
    return (
      <CardView>
        <StyledTypography>{methodName}</StyledTypography>
        <Typography sx={{ fontWeight: 'bold', fontSize: 22 }}>····</Typography>
        <StyledTypography>{payment.cardLast4 ?? payment.bankAccountLast4}</StyledTypography>
      </CardView>
    );
  }, [payment, methodName]);

  const expiresDate = useMemo(() => {
    let dateString = '';
    if (payment.type === 'card') {
      dateString = (payment.cardExpirationMonth ?? 0).toString() + '/' + (payment.cardExpirationYear ?? 0).toString();
    }

    return dateString;
  }, [payment]);

  return (
    <StyledCardContainer>
      <StyledColumnView>
        {cardIcon}
        {cardView}
        {payment.isDefault && <DefaultTypography>Default</DefaultTypography>}
      </StyledColumnView>
      <StyledColumnView>
        <StyledTypography>Expires {expiresDate}</StyledTypography>
        {!payment.isDefault && (
          <>
            <IconButton sx={{ ml: '20px' }} disabled={loading} onClick={onRemove}>
              <CloseIcon fontSize="small" style={{ color: '#bdbdbd' }} />
            </IconButton>
            <Button style={{ textTransform: 'none' }} disabled={loading} onClick={onDefault}>
              <Box border={1} bgcolor="#00589b" borderColor="#00589b" borderRadius="5px">
                <DefaultButtonTypography>Set as default</DefaultButtonTypography>
              </Box>
            </Button>
          </>
        )}
      </StyledColumnView>
    </StyledCardContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  padding-top: 10px;
  padding-bottom: 60px;
`;

const AddButton = styled(Button)({
  fontSize: 14,
  fontWeight: 400,
  width: 'fit-content',
  textTransform: 'none',
  color: '#424242',
  padding: 0,
  minHeight: 0,
  minWidth: 0,
});

const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const StyledColumnView = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 30%;
  align-items: center;
`;

const CardView = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

const DefaultTypography = styled(Typography)({
  fontSize: 12,
  fontWeight: 400,
  paddingLeft: '8px',
  paddingRight: '8px',
  paddingTop: '2px',
  paddingBottom: '2px',
  backgroundColor: '#e0e0e0',
  borderRadius: '5px',
});

const StyledTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
});

const DefaultButtonTypography = styled(Typography)({
  fontSize: 12,
  fontWeight: 400,
  color: 'white',
  paddingLeft: '8px',
  paddingRight: '8px',
  paddingTop: '2px',
  paddingBottom: '2px',
});
