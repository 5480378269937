import { FC } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { UsersAndPermissionsView } from './UsersAndPermissionsView';
import { BrandPermissionsView } from './BrandPermissionsView';
import { gql } from '@apollo/client';

export const UserManagementWrapView: FC = () => {
  const { type } = useParams<{ type: string }>();

  const getChildren = () => {
    if (!!type && type === 'brands') {
      return <BrandPermissionsView />;
    } else {
      return <UsersAndPermissionsView />;
    }
  };

  return <StyledContainer>{getChildren()}</StyledContainer>;
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 32px;
`;

export const UserManagementPublicUsersQuery = gql`
  fragment PublicUserBrandDashboardSeatFragment on PublicUserBrandDashboardSeat {
    id
    isSoftCancelled
    isFreeSeat
    assignedToEmail
    assignedToPublicUser {
      id
      email
    }
  }

  fragment PublicUserBrandDashboardFragment on PublicUserBrandDashboard {
    name
    isEnabled
    seats {
      ...PublicUserBrandDashboardSeatFragment
    }
  }

  fragment PublicUserBrandFragment on PublicUserBrand {
    id
    publicPartnerId
    name
    reasonPartnerUuid
    type
    country
    isManagingData
    isAdmin
    isBillingAdmin
    dashboards {
      ...PublicUserBrandDashboardFragment
    }
  }

  fragment PublicUserFragment on PublicUser {
    id
    email
    status
    brands {
      ...PublicUserBrandFragment
    }
  }

  query UserManagementPublicUsers {
    listPublicUsers {
      ...PublicUserFragment
    }
  }
`;

export const UserManagementGetPublicUserQuery = gql`
  query UserManagementGetPublicUser($id: String!) {
    getPublicUser(id: $id) {
      ...PublicUserFragment
    }
  }
`;

export const UserManagementAddPublicUserMutation = gql`
  mutation UserManagementAddPublicUser($name: String!, $email: String!, $brandIds: [String!]!) {
    addPublicUser(name: $name, email: $email, brandIds: $brandIds)
  }
`;

export const UserManagementRemovePublicUserMutation = gql`
  mutation UserManagementRemovePublicUser($id: String!) {
    removePublicUser(id: $id)
  }
`;

export const UserManagementReinvitePublicUserMutation = gql`
  mutation UserManagementReinvitePublicUser($id: String!) {
    reinvitePublicUser(id: $id)
  }
`;

export const UserManagementSetBrandPermissionsPublicUserMutation = gql`
  mutation UserManagementSetBrandPermissionsPublicUser(
    $userId: String!
    $brandId: String!
    $isAdmin: Boolean!
    $isBillingAdmin: Boolean!
    $canManage: Boolean!
  ) {
    setBrandPermissionsPublicUser(
      userId: $userId
      brandId: $brandId
      isAdmin: $isAdmin
      isBillingAdmin: $isBillingAdmin
      canManage: $canManage
    )
  }
`;

export const UserManagementAssignBrandDashboardSeatPublicUserMutation = gql`
  mutation UserManagementAssignBrandDashboardSeatPublicUser(
    $userId: String!
    $brandId: String!
    $dashboardName: String!
  ) {
    assignBrandDashboardSeatPublicUser(userId: $userId, brandId: $brandId, dashboardName: $dashboardName)
  }
`;

export const UserManagementUnassignBrandDashboardSeatPublicUserMutation = gql`
  mutation UserManagementUnassignBrandDashboardSeatPublicUser(
    $userId: String!
    $brandId: String!
    $dashboardName: String!
  ) {
    unassignBrandDashboardSeatPublicUser(userId: $userId, brandId: $brandId, dashboardName: $dashboardName)
  }
`;

export const UserManagementPurchaseBrandDashboardSeatPublicUserMutation = gql`
  mutation UserManagementPurchaseBrandDashboardSeatPublicUser(
    $brandId: String!
    $dashboardName: String!
    $evalOnly: Boolean!
  ) {
    purchaseBrandDashboardSeatPublicUser(brandId: $brandId, dashboardName: $dashboardName, evalOnly: $evalOnly) {
      id
      renewAmount
      renewPeriodType
      renewAmountCurrency
    }
  }
`;

export const UserManagementCancelBrandDashboardSeatPublicUserMutation = gql`
  mutation UserManagementCancelBrandDashboardSeatPublicUser(
    $brandId: String!
    $dashboardName: String!
    $id: String!
    $evalOnly: Boolean!
  ) {
    cancelBrandDashboardSeatPublicUser(brandId: $brandId, dashboardName: $dashboardName, id: $id, evalOnly: $evalOnly) {
      id
      renewAmount
      renewPeriodType
      renewAmountCurrency
      cancelOn
      didCancel
    }
  }
`;
