import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import { FC, ReactNode } from 'react';
import { useMatches, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { WizardStep } from './common';

export type WizardStepperProps = {
  steps: WizardStep[];
};

export const WizardStepper: FC<WizardStepperProps> = ({ steps }) => {
  const navigate = useNavigate();

  // When using the wizard, last segment should be the current step, denoted by '*' in parent route
  const matches = useMatches();
  const lastMatch = matches[matches.length - 1]?.params?.['*'];

  const activeStep = steps.findIndex(s => s.name === lastMatch);

  return (
    <Stepper activeStep={activeStep}>
      {steps.map((s, i) => {
        const completed = activeStep > i;

        const label = s.label ?? s.name;

        const disableBack = steps[activeStep]?.disableNavigateBack;

        let element: ReactNode;
        if (completed && !disableBack) {
          element = (
            <StyledStepButton
              onClick={() => {
                navigate(s.name, {
                  relative: 'route',
                });
              }}
            >
              {label}
            </StyledStepButton>
          );
        } else {
          element = <StyledStepLabel>{label}</StyledStepLabel>;
        }

        return (
          <Step key={s.name} completed={activeStep > i}>
            {element}
          </Step>
        );
      })}
    </Stepper>
  );
};

const StyledStepButton = styled(StepButton)`
  text-transform: capitalize;
`;

const StyledStepLabel = styled(StepLabel)`
  text-transform: capitalize;
`;
