import Typography from '@mui/material/Typography';
import { FC, useEffect, useState } from 'react';
import { Column } from 'react-table';
import styled from 'styled-components';
import { Table, TableProps } from 'components/table/Table';
import { DataReportUploadFileDefinition } from 'graphql/types';
import { Box, Button } from '@mui/material';
import { DownloadIconButton } from './DownloadIconButton';
import { FileDownloadDialog } from './FileDownloadDialog';
import { FileUploadButton } from './FileUploadButton';
import CircularProgress from '@mui/material/CircularProgress';
import { TimeAgoTypograph } from './TimeAgoTypography';
import { DataViewer } from './DataViewer';

export type NodeTableViewProps = {
  data: DataReportUploadFileDefinition[];
};

export const NodeTableView: FC<NodeTableViewProps> = ({ data }) => {
  const [downloadFileData, setDownloadFileData] = useState<DataReportUploadFileDefinition | null>(null);
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<DataReportUploadFileDefinition[]>([]);

  useEffect(() => {
    setUploadedFiles(data);
    return () => {
      setUploadedFiles([]);
    };
  }, [data]);

  const Columns: Column<DataReportUploadFileDefinition>[] = [
    {
      Header: 'Data type',
      accessor: 'dataTypeName',
    },
    {
      Header: 'Table name',
      accessor: 'tableName',
    },
    {
      Header: 'Download',
      accessor: 'downloadInstructions',
      Cell: ({ row: { original } }) => {
        return (
          <Box
            display={'flex'}
            sx={{
              width: '100%',
              height: '100%',
              paddingLeft: 1.5,
            }}
          >
            <DownloadIconButton
              partner={original}
              onDownload={data => {
                setDownloadFileData(data);
                setShowDownloadDialog(true);
              }}
            />
          </Box>
        );
      },
    },
    {
      Header: '',
      accessor: 'hasDataUploaded',
      Cell: ({ row: { original } }) => {
        if (original.hasDataUploaded === true) {
          return (
            <DataViewer
              partner={original} />
          );
        } else {
          return (<></>);
        }
      },
    },
    {
      Header: 'Last updated',
      accessor: 'lastUpdatedOn',
      Cell: ({ row: { original } }) => {
        return (
          <TimeAgoTypograph isoTimeString={original.lastUpdatedOn ?? ''} />
        );
      },
    },
    {
      Header: 'Upload',
      accessor: 'reportType',
      Cell: ({ value, row: { original } }) => {
        return (
          <FileUploadButton
            reportType={value}
            partner={original}
            setUploading={loading => setShowLoader(loading)}
            fileUploaded={data => {
              if (data) {
                const temp = [...uploadedFiles];
                const currentIndex = temp.findIndex(item => item.reportType === data);
                temp[currentIndex] = {
                  ...temp[currentIndex],
                  hasDataUploaded: true,
                  lastUpdatedOn: (new Date()).toISOString(),
                };
                setUploadedFiles(temp);
              }
            }}
          />
        );
      },
    },
  ];

  return (
    <StyledContainer>
      <Typography
        textAlign={'center'}
        sx={{
          fontSize: 30,
          fontWeight: 800,
          color: '#000000',
        }}>
        Upload Files to Your Database
      </Typography>
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 500,
          color: '#000000',
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 1,
          paddingBottom: 1,
        }}>
        Download Template to get started. This page allows you to upload CSV files that can be made available in your Postgresql database. Download a template, fill it out, and upload it from this interface!
      </Typography>
      <StyledButton sx={{ width: 'fit-content' }} href={`mailto:contact@support.com?subject=I need help with this page`}>I need help with this page</StyledButton>
      <StyledTable columns={Columns} data={uploadedFiles} />
      {showLoader && (
        <CircularProgress
          size={40}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        />
      )}
      <FileDownloadDialog
        partner={downloadFileData}
        open={showDownloadDialog} onClose={() => {
          setShowDownloadDialog(false);
        }}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const StyledTable = styled(Table)`
  flex: 1;
` as FC<TableProps<DataReportUploadFileDefinition>>;

const StyledButton = styled(Button)({
  gap: '4px',
  textTransform: 'none',
  fontSize: 12,
  fontWeight: 800,
  color: '#23A4A4',
  textAlign: 'center',
});