import { FC, useMemo } from 'react';

import { SelectField, SelectOption } from 'components/fields';
import { useGetAdvertisingProfilesQuery } from 'graphql/types';

export type AdvertisingProfileFormFieldsProps = {
  partnerId: string;
};

export const AdvertisingProfileFormFields: FC<AdvertisingProfileFormFieldsProps> = ({ partnerId }) => {
  const { data: getAdvertisingQueryResponse } = useGetAdvertisingProfilesQuery({
    variables: {
      partnerId,
    },
  });

  const options = useMemo<SelectOption[]>(() => {
    return (getAdvertisingQueryResponse?.getAdvertisingProfiles || []).map<SelectOption>((profile) => {
      return {
        label: `${profile.name} (${profile.countryCode})`,
        value: profile.id,
      };
    });
  }, [getAdvertisingQueryResponse?.getAdvertisingProfiles]);

  return (
    <>
      <SelectField name={'id'} label={'Advertising Profile'} options={options} />
    </>
  );
};
