import { CircularProgress } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

import { AdvertisingProfileForm } from 'components/advertisingProfileForm/AdvertisingProfileForm';
import { useConnectAccountContext } from 'context/connectAccountContext';
import { usePublicPartnersQuery } from 'graphql/types';
import { useCompleteConnection } from './common';
import { WizardStepProps } from 'components/wizard';
import { OAuthErrorInfo } from './OAuthErrorInfo';

export type SelectAdvertisingProfileStepProps = WizardStepProps;

export const SelectAdvertisingProfileStep: FC<SelectAdvertisingProfileStepProps> = ({ nextStep }) => {
  const { brandId, isAuthenticating, oauth } = useConnectAccountContext();
  const { data: publicPartnersQuery } = usePublicPartnersQuery();

  const oauthState = useCompleteConnection();

  const partner = publicPartnersQuery?.publicPartners?.find((p) => p.id === brandId);

  if (isAuthenticating && (!oauthState.success || !oauth?.success)) {
    return <OAuthErrorInfo {...oauthState} errorMessage={oauth?.errorMessage || oauthState.errorMessage} />;
  } else if (!partner) {
    return <CircularProgress />;
  }

  return <StyledForm onSubmit={() => nextStep()} partner={partner} />;
};

const StyledForm = styled(AdvertisingProfileForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
`;
