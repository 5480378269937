import { Button, CircularProgress, DialogActions, DialogContent } from '@mui/material';
import { RRDialog, RRDialogTitle } from 'components/dialog/RRDialog';
import { DataReportUploadFileDefinition, usePpDownloadCsvMutation } from 'graphql/types';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { FC, useCallback, useEffect, useState } from 'react';
import Papa from 'papaparse'
import { useMuiNotifications } from 'context/muiNotificationContext';
import { green } from '@mui/material/colors';
import { useAppSelector } from 'store/hooks';

export type DataViewerDialogProps = {
  open: boolean;
  partner: DataReportUploadFileDefinition;
  onClose: () => void;
};

export const DataViewerDialog: FC<DataViewerDialogProps> = ({ open, onClose, partner }) => {
  const { createFailureNotification } = useMuiNotifications();
  const [isLoading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState<any[]>([]);

  const partnerId = useAppSelector(state => state.app.partnerId);
  const [downloadCsvMutation] = usePpDownloadCsvMutation();

  const getSignedCsvUrl = useCallback(() => {
    const loadDataFromSignedUrl = (url: string) => {
      Papa.parse(url, {
        header: true,
        download: true,
        skipEmptyLines: true,
        delimiter: ',',
        complete: (results) => {
          setLoading(false);
          const rows = results.data as any[];
          setCsvData(rows);
        },
        error(error) {
          setLoading(false);
          createFailureNotification(`Failed: ${error.message}`);
        },
      })
    };

    if (partner?.reportType && partnerId) {
      setLoading(true);
      downloadCsvMutation({
        variables: {
          partnerId: partnerId,
          reportType: partner?.reportType
        },
        onCompleted(data) {
          if (data.generateDownloadFileUrlData.signedS3URL) {
            const url = data.generateDownloadFileUrlData.signedS3URL;
            loadDataFromSignedUrl(url);
          } else {
            setLoading(false);
            createFailureNotification('Cannot fetch uploaded data');
          }
        },
        onError(error) {
          setLoading(false);
          createFailureNotification(`Failed: ${error.message}`);
        },
      });
    }
  }, [partner, partnerId, createFailureNotification, downloadCsvMutation]);

  useEffect(() => {
    if (open) {
      getSignedCsvUrl();
    }
  }, [open, getSignedCsvUrl]);

  const handleClose = () => {
    onClose();
  };

  const columns = partner.fields.map(field => {
    return {
      name: field.name,
      options: {
        filter: true,
        sort: true,
      }
    };
  });

  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    print: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      body: {
        noMatch: isLoading || !open ? '' : 'Empty data',
      }
    }
  };

  return (
    <RRDialog
      fullWidth
      maxWidth={'lg'}
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <RRDialogTitle id='customized-dialog-title' onClose={handleClose}>
        DATA VIEWER {partner.reportType} [{partner.tableName}]
      </RRDialogTitle>
      <DialogContent dividers sx={{ maxHeight: '80%' }}>
        <MUIDataTable title={''} columns={columns} data={csvData} options={options} />
        {isLoading && (
          <CircularProgress
            size={40}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Ok
        </Button>
      </DialogActions>
    </RRDialog>
  );
}