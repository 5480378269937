import { Box, IconButton, Typography } from '@mui/material';
import { DataReportBannerAlarmDefinition } from 'graphql/types';
import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BannerAlarmView } from './BannerAlarmView';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useLocation } from 'react-router-dom';
import { trackGoogleAnalyticsClickEvent } from 'helpers/analytics';

export type BannerViewProps = {
  data: DataReportBannerAlarmDefinition[];
};

export const BannerView: FC<BannerViewProps> = ({ data }) => {
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isExpanded, setExpanded] = useState(false);
  const [banners, setBanners] = useState<DataReportBannerAlarmDefinition[]>([]);

  useEffect(() => {
    setExpanded(false);
    setCurrentPage(0);
  }, [location]);

  const getBanners = useCallback(() => {
    return data.filter(item => {
      let isValid = true;
      const now = Date.now();
      const discardedBanners = sessionStorage.getItem('discarded_banners')?.split(',') || [];

      if (item.activatedOn) {
        const activedOn = Date.parse(item.activatedOn);
        isValid = now > activedOn;
      }
      if (item.activatedUntil) {
        const activatedUntil = Date.parse(item.activatedUntil);
        isValid = now < activatedUntil;
      }
      if (discardedBanners.includes(item.id)) {
        isValid = false;
      }

      return isValid;
    });
  }, [data]);

  useEffect(() => {
    if (data) {
      const bannerList = getBanners();
      setBanners(bannerList);
      setCurrentPage(0);
      setExpanded(false);
    }
  }, [data, getBanners]);

  const saveDiscardedBanner = (bannerId: string) => {
    const banners = sessionStorage.getItem('discarded_banners')?.split(',') || [];
    const temp = [...banners];
    if (!temp.includes(bannerId)) {
      temp.push(bannerId);
    }
    const sBanner = temp.join(',');

    sessionStorage.setItem('discarded_banners', sBanner);
    const bannerList = getBanners();
    setBanners(bannerList);
    setCurrentPage(0);
    setExpanded(false);
  };

  const bannerViews = banners.map(item => {
    return (
      <BannerAlarmView
        data={item}
        isExpanded={isExpanded}
        onClose={id => saveDiscardedBanner(id)}
      />
    );
  });

  const onPrev = () => {
    trackGoogleAnalyticsClickEvent('Banner Previous', 'Banner');
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const onNext = () => {
    trackGoogleAnalyticsClickEvent('Banner Next', 'Banner');
    if (currentPage + 1 < banners.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <StyledBox
      sx={{
        ml: '120px',
        mr: '250px',
        pb: (banners.length > 1 || (banners.length > currentPage && !!banners[currentPage].detailsHtml)) ? '25px' : '0px'
      }}>
      {banners.length > currentPage && (
        <>
          {bannerViews[currentPage]}
          {banners[currentPage].detailsHtml && (
            <IconButton
              aria-label='expand'
              onClick={() => {
                trackGoogleAnalyticsClickEvent('Banner Expand', 'Banner');
                setExpanded(!isExpanded);
              }}
              sx={{
                position: 'absolute',
                right: '4px',
                bottom: '4px',
              }}  >
              {isExpanded ? (
                <KeyboardArrowUpIcon fontSize='small' />
              ) : (
                <KeyboardArrowDownIcon fontSize='small' />
              )}
            </IconButton>
          )}
        </>
      )}
      {
        banners.length > 1 && (
          <Box component="main" sx={{ display: 'flex', alignItems: 'center', position: 'absolute', left: '14px', bottom: '4PX', }}>
            <IconButton
              aria-label='back'
              onClick={onPrev}
              disabled={currentPage === 0}
              sx={{
                width: '36px',
                height: '36px',
              }}
            >
              {currentPage === 0 ? (
                <></>
              ) : (
                <ArrowBackIosIcon fontSize='small' />
              )}
            </IconButton>
            <PageTypography textAlign={'center'}>
              {currentPage + 1} / {banners.length}
            </PageTypography>
            <IconButton
              aria-label='forward'
              onClick={onNext}
              disabled={currentPage === banners.length - 1}
              sx={{
                width: '36px',
                height: '36px',
              }}>
              {currentPage === banners.length - 1 ? (
                <></>
              ) : (
                <ArrowForwardIosIcon fontSize='small' />
              )}
            </IconButton>
          </Box>
        )
      }
    </StyledBox >
  );
};

const StyledBox = styled(Box)({
  backgroundColor: '#F6F5C6',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  marginTop: '16px',
});

const PageTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
  color: 'black',
  width: '50px',
});