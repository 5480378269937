import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { HelpReportProblemView } from './HelpReportProblemView';
import { trackGoogleAnalyticsPageView } from 'helpers/analytics';

export const HelpWrapView: FC = () => {
  const { property } = useParams<{ property: string }>();

  useEffect(() => {
    if (property) {
      trackGoogleAnalyticsPageView(`/help/${property}`, 'Report a problem');
    }
  }, [property]);

  const children = () => {
    if (!!property) {
      if (property === 'report_problem') {
        return <HelpReportProblemView />
      }
    }
    return null;
  };

  return (
    <StyledContainer>
      {children()}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 32px;
`;