import { DataReportUploadFileDefinition } from 'graphql/types';
import { FC, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import TableViewIcon from '@mui/icons-material/TableView';
import { DataViewerDialog } from './DataViewerDialog';

export type DataViewerProps = {
  partner: DataReportUploadFileDefinition;
};

export const DataViewer: FC<DataViewerProps> = ({ partner }) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <div>
      <IconButton
        onClick={() => {
          setOpenDialog(true);
        }}
        title={'View data'}
      >
        <TableViewIcon />
      </IconButton>
      <DataViewerDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        partner={partner}
      />
    </div>
  );
}
