import { FC } from 'react';
import styled from 'styled-components';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';
import { CheckoutForm } from './parts/CheckoutForm';

const options: StripeElementsOptions = {
  mode: 'payment',
  amount: 1099,
  currency: 'usd',
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK ?? '');

export const Checkout: FC = () => {
  return (
    <StyledContainer>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
      </Elements>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
`;
