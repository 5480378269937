import IconButton from '@mui/material/IconButton';
import ConnectIcon from '@mui/icons-material/ConnectWithoutContact';
import CheckIcon from '@mui/icons-material/CheckCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PublicPartnerConnectionStatus, PublicPartnerFragmentFragment, PublicPartnerType } from 'graphql/types';
import { ConnectAccountSteps } from 'components/connectAccountView/parts/common';
import { Dangerous, Warning } from '@mui/icons-material';
import { exhaustiveCheckNoError } from 'helpers/types';
import { ConnectAccountContextRouteData } from 'context/connectAccountContext';
import { trackGoogleAnalyticsClickEvent } from 'helpers/analytics';

export type ConnectExistingButtonProps = {
  partner: PublicPartnerFragmentFragment;
  isDisable?: boolean;
};

export const ConnectExistingButton: FC<ConnectExistingButtonProps> = ({ partner, isDisable = false }) => {
  const navigate = useNavigate();

  let icon: ReactNode;
  let title = '';
  let step = ConnectAccountSteps.Confirmation;
  let isReauthenticating = false;
  switch (partner.connectedStatus) {
    case PublicPartnerConnectionStatus.BeginAuthorization:
      icon = <ConnectIcon />;
      title = 'Connect with Amazon';
      break;

    // Authorized, but not connected to Reason
    case PublicPartnerConnectionStatus.AuthorizationProgress:
      // Still need to select their advertising profile, so allow that
      if (partner.type === PublicPartnerType.Advertising && !partner.advertisingProfileId) {
        icon = <ManageAccountsIcon />;
        step = ConnectAccountSteps.AdvertisingProfile;

        title = 'Select Advertising Profile';
      } else {
        // The user can't do anything about not being linked to reason, so show success
        return (
          <StyledIconContainer>
            <CheckIcon color={'success'} />
          </StyledIconContainer>
        );
      }
      break;

    case PublicPartnerConnectionStatus.Connected:
      return (
        <StyledIconContainer>
          <CheckIcon color={'success'} />
        </StyledIconContainer>
      );

    case PublicPartnerConnectionStatus.NeedsReauthorization:
      title = 'Reauthenticate soon';
      icon = <Warning color="warning" />;
      isReauthenticating = true;
      break;

    case PublicPartnerConnectionStatus.AuthorizationExpired:
      title = 'Authentication expired';
      icon = <Dangerous color="error" />;
      isReauthenticating = true;
      break;
    default:
      exhaustiveCheckNoError(partner.connectedStatus);
  }

  return (
    <IconButton
      disabled={isDisable}
      onClick={() => {
        const state: ConnectAccountContextRouteData = {
          accountType: partner.type,
          brandName: partner.name,
          id: partner.id,
          marketplace: partner.marketplace,
          isAuthenticating: isReauthenticating,
          isReauthentication: !!partner.isConnected,
        };

        trackGoogleAnalyticsClickEvent(title, `connect/${step}`);

        navigate(`/connect/${step}`, {
          state,
        });
      }}
      title={title}
    >
      {icon}
    </IconButton>
  );
};

const StyledIconContainer = styled.div`
  display: flex;
  padding-left: 8px;
`;
