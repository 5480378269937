import { Button, TextField, Typography } from '@mui/material';
import { Modal } from 'components/modal/modal';
import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';

type EditPartnerNameModalProps = {
  open: boolean;
  name: string;
  onClose: () => void;
  onOK: (name: string) => void;
};

export const EditPartnerNameModal: FC<EditPartnerNameModalProps> = ({ open, name, onOK, onClose }) => {
  const [newName, setNewName] = useState<string>('');

  const invalidName = useMemo(() => {
    return !newName || newName !== newName.trim();
  }, [newName]);

  return (
    <Modal width={480} height={325} open={open} onClickOutside={onClose} title="Change API Connection name">
      <StyledContainer>
        {newName ? (
          <Typography>
            Please confirm the update for the connection name from <b>{name}</b> to <b>{newName}</b>:
          </Typography>
        ) : (
          <Typography>
            Please enter the new connection name for <b>{name}</b>:
          </Typography>
        )}
        <InputLabel>New connection name:</InputLabel>
        <TextField
          id="name"
          variant="outlined"
          size="small"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setNewName(event.target.value);
          }}
        />
        <ButtonRowContainer>
          <StyledButton variant="outlined" onClick={onClose}>
            CANCEL
          </StyledButton>
          <StyledButton
            variant="contained"
            disabled={invalidName}
            onClick={() => {
              onOK(newName);
            }}
          >
            OK
          </StyledButton>
        </ButtonRowContainer>
      </StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  padding-top: 0px;
`;

const InputLabel = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
});

const ButtonRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-top: 8px;
`;

const StyledButton = styled(Button)({
  textTransform: 'none',
  display: 'flex',
  width: '100%',
});
