/**
 * @description Capitalize the first letter of each word in input string.
 *
 * @param str String to convert.
 * @returns String converted.
 */
export function capitalizeFirstLetterOfEachWord(str: string): string {
  if (!str) {
    return str;
  }

  return str.replaceAll(/\b\w/g, (match) => match.toUpperCase());
}
