import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, SvgIcon, Typography } from '@mui/material';

export type ModalProps = PropsWithChildren<{
  className?: string;
  onRequestClose?: () => void;
  onClickOutside?: () => void;
  open: boolean;
  title?: ReactNode;
  width?: number;
  height?: number;
}>;

export const Modal: FC<ModalProps> = ({ children, className, onRequestClose, onClickOutside, open, title, width = 660, height = 430 }) => {
  const styles = useMemo(
    () => ({
      overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1299,
      },
    }),
    []
  );

  const closeModalFn = () => {
    if (onClickOutside) {
      onClickOutside();
    }
  };

  function assertIsNode(e: EventTarget | null): asserts e is Node {
    if (!e || !('nodeType' in e)) {
      throw new Error(`Node expected`);
    }
  };

  return (
    <StyledGlobalModalStyles
      className={className}
      width={width}
      height={height}
      style={styles}
      isOpen={open}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={onClickOutside ? true : false}
      onRequestClose={onRequestClose}
      onAfterOpen={() => {
        const el = document.querySelector('.ReactModal__Overlay');
        const modalEl = document.querySelector('.ReactModal__Content');

        const handler = (event: Event) => {
          const target = event.target;
          assertIsNode(target);
          if (el && modalEl && target && !modalEl.contains(target)) {
            el.removeEventListener('click', handler);
            closeModalFn();
          }
        };

        el && el.addEventListener('click', handler);
      }}
    >
      <ModalContent>
        {onRequestClose && (
          <CloseButton onClick={onRequestClose}>
            <SvgIcon>
              <CloseIcon />
            </SvgIcon>
          </CloseButton>
        )}
        {title && (
          <ModalHeader>
            <Typography sx={{ fontWeight: '500' }} variant={'h5'}>{title}</Typography>
          </ModalHeader>
        )}
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </StyledGlobalModalStyles>
  );
};

const StyledGlobalModalStyles = styled(ReactModal) <{ width: number, height: number }>`
  bottom: 20px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  outline: none;
  background: ${(p) => p.theme.palette.background.default};
  overflow: auto;
  position: absolute;
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .ReactModal__Overlay {
    z-index: 10000;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.down('sm')}) {
    left: 20px;
  }
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
`;

export const ModalBody = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-top: 8px;
`;

export const ModalHeader = styled.header`
  // border-bottom: 1px solid #e0e0e0;
  padding: 24px;
`;

export const CloseButton = styled(IconButton)`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
`;
