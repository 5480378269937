import { gql } from '@apollo/client';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { useConnectAccountContext } from 'context/connectAccountContext';
import { WizardStepProps } from 'components/wizard';
import { OAuthErrorInfo } from './OAuthErrorInfo';
import { useCompleteConnection } from './common';
import { PublicPartnerType } from 'graphql/types';

export type ConnectAccountCompleteProps = WizardStepProps;

export const ConnectAccountComplete: FC<ConnectAccountCompleteProps> = () => {
  const { accountType, isAuthenticating, isReauthentication, oauth } = useConnectAccountContext();

  const oauthState = useCompleteConnection(
    !isAuthenticating || !accountType || (accountType === PublicPartnerType.Advertising && !isReauthentication)
  );

  const oauthRequiredAndComplete = oauthState.success && oauth?.success;

  return (
    <>
      {isAuthenticating && <OAuthErrorInfo {...oauthState} />}
      {(!isAuthenticating || oauthRequiredAndComplete) && (
        <StyledContainer>
          <Typography variant={'subtitle2'}>
            Congratulations. You have successfully connected Reason Automation to your Amazon account!
          </Typography>
          <div>
            <Button component={NavLink} to={'/'}>
              Complete
            </Button>
          </div>
        </StyledContainer>
      )}
    </>
  );
};

export const PublicPartnerGqlQueries = gql`
  mutation SelectAdvertisingProfile($partnerId: String!, $advertisingProfileId: String!) {
    selectAdvertisingProfile(advertisingProfileId: $advertisingProfileId, partnerId: $partnerId)
  }

  query GetAdvertisingProfiles($partnerId: String!) {
    getAdvertisingProfiles(partnerId: $partnerId) {
      id
      name
      type
      countryCode
    }
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
