import Typography from '@mui/material/Typography';
import { FC } from 'react';

import { WizardStepProps } from 'components/wizard';

export type ConnectAccountWizardHeaderProps = WizardStepProps;

export const ConnectAccountWizardHeader: FC<ConnectAccountWizardHeaderProps> = () => {
  return <Typography variant={'h5'}>Connect an Amazon Account</Typography>;
};
