import { useAuth0 } from '@auth0/auth0-react';
import { trackGoogleAnalyticsClickEvent } from 'helpers/analytics';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Logout: FC = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    trackGoogleAnalyticsClickEvent('LogOut');

    logout({
      returnTo: window.location.origin,
    });
  }, [logout, navigate]);

  return null;
};
