import { FC, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Typography } from '@mui/material';

export enum AlertType {
  Success, Failed, Warning
}

export type AlertDialogProps = {
  showDialog: boolean;
  type: AlertType;
  title?: string;
  message: string;
  okTitle: string;
  onOK: () => void;
  onClose?: () => void;
}

export const AlertDialog: FC<AlertDialogProps> = ({ showDialog, type, title, message, okTitle, onOK, onClose }) => {
  const alertTitle = useMemo(() => {
    if (!title) {
      switch (type) {
        case AlertType.Success:
          return 'Congratulations';
        case AlertType.Failed:
          return 'Oops';
        case AlertType.Warning:
          return 'Warning';
      }
    } else {
      return title;
    }
  }, [type, title]);

  return (
    <Dialog
      open={showDialog}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {alertTitle}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ whiteSpace: 'pre-line' }}>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        {onClose && (
          <Button onClick={onClose}>Cancel</Button>
        )}
        <Button onClick={onOK}>{okTitle}</Button>
      </DialogActions>
    </Dialog>
  );
};