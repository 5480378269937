import { FC } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { WizardStep, WizardStepProps } from './parts/common';
import { WizardLayout } from './parts/WizardLayout';
import { WizardStepper } from './parts/WizardStepper';

export type WizardProps = {
  steps: WizardStep[];
};

export const Wizard: FC<WizardProps> = ({ steps }) => {
  const navigate = useNavigate();

  return (
    <StyledContainer>
      <WizardStepper steps={steps} />
      <Routes>
        {steps.map((s, i) => {
          const Element = s.render;

          const previousStep = steps[i - 1];
          const nextStep = steps[i + 1];
          const hasPreviousStep = (s.disableNavigateBack == null || s.disableNavigateBack === false) && !!previousStep;
          const hasNextStep = !!nextStep;

          const stepProps: WizardStepProps = {
            hasNextStep,
            hasPreviousStep,
            nextStep: () => {
              if (!hasNextStep) {
                return;
              }

              navigate(nextStep.name, {
                relative: 'route',
              });
            },
            previousStep: () => {
              if (!hasPreviousStep) {
                return;
              }

              navigate(previousStep.name, {
                relative: 'route',
              });
            },
            stepCount: steps.length,
            stepIndex: i,
            stepName: s.name,
          };

          return (
            <Route
              index={i === 0}
              path={s.name}
              key={s.name}
              element={
                <WizardLayout header={s.header} {...stepProps}>
                  <Element {...stepProps} />
                </WizardLayout>
              }
            />
          );
        })}
        <Route path={'*'} element={<Navigate to={steps[0].name} replace />} />
      </Routes>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;

  main {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;
