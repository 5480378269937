import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { UserBrandDashboard } from 'helpers/userPermissions';
import { useUserManagementPurchaseBrandDashboardSeatPublicUserMutation } from 'graphql/types';
import { useMuiNotifications } from 'context/muiNotificationContext';

export type PurchaseSeatMode = 'NO_AVAILABLE_SEATS' | 'ADD_NEW_SEAT';

type PurchaseSeatModalProps = {
  open: boolean;
  brandId: string;
  dashboard: UserBrandDashboard | undefined;
  mode: PurchaseSeatMode;
  onClose: () => void;
  onPurchase: () => void;
};

export const PurchaseSeatModal: FC<PurchaseSeatModalProps> = ({
  open,
  brandId,
  dashboard,
  mode,
  onClose,
  onPurchase,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '42%',
    transform: 'translate(-50%, -50%)',
    width: 480,
    bgcolor: '#333',
    border: '2px solid #333',
    boxShadow: 24,
    p: 4,
  };

  const { createFailureNotification } = useMuiNotifications();
  const [purchaseBrandDashboardSeatMutation, { loading: evaluatingPrice }] =
    useUserManagementPurchaseBrandDashboardSeatPublicUserMutation();
  const [seatPriceInCents, setSeatPriceInCents] = useState<number>(0);

  useMemo(async () => {
    if (!dashboard) {
      return;
    }

    try {
      setSeatPriceInCents(0);
      const result = await purchaseBrandDashboardSeatMutation({
        variables: {
          brandId,
          dashboardName: dashboard.name,
          evalOnly: true,
        },
      });

      if (result?.data?.purchaseBrandDashboardSeatPublicUser?.renewAmount) {
        setSeatPriceInCents(result.data.purchaseBrandDashboardSeatPublicUser.renewAmount);
      }
    } catch (e) {
      createFailureNotification(`Failed to get price for new seat. Please contact support. ${e}`);
    }
  }, [brandId, dashboard, createFailureNotification, purchaseBrandDashboardSeatMutation]);

  const locale = new Intl.NumberFormat().resolvedOptions().locale;
  const getCurrencySymbol = (locale: string, currency: string) =>
    (0)
      .toLocaleString(locale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })
      .replace(/\d/g, '')
      .trim();

  const purchaseString = useMemo(() => {
    if (!dashboard) {
      return '';
    }
    if (evaluatingPrice || !seatPriceInCents) {
      return 'Fetching seat price...';
    }

    const currencySymbol = getCurrencySymbol(locale, dashboard.seatPriceCurrency);
    // return 'Do you want to purchase another seat?';
    return 'Do you want to purchase another seat for ' + currencySymbol + seatPriceInCents / 100 + '/month?';
  }, [dashboard, seatPriceInCents, evaluatingPrice, locale]);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ color: '#fff', marginBottom: 4, textAlign: 'center' }}
        >
          {mode === 'NO_AVAILABLE_SEATS' ? 'No available seats' : 'Purchase a seat'}
        </Typography>
        <MessageLabel id="modal-modal-description" sx={{ mt: 2 }}>
          {mode === 'NO_AVAILABLE_SEATS'
            ? 'All seats for this dashboard are assigned to other users.'
            : 'Add a new seat to this dashboard, to be assigned to other users.'}
        </MessageLabel>
        <MessageLabel id="modal-modal-description" sx={{ mt: 2 }}>
          {purchaseString}
        </MessageLabel>
        <StyledButtonContainer>
          <StyledCancelButton onClick={onClose}>CANCEL</StyledCancelButton>
          <StyledPurchaseButton
            variant={'contained'}
            disabled={evaluatingPrice || !seatPriceInCents}
            onClick={onPurchase}
          >
            PURCHASE SEAT
          </StyledPurchaseButton>
        </StyledButtonContainer>
      </Box>
    </Modal>
  );
};

const MessageLabel = styled(Typography)({
  fontSize: 16,
  fontWeight: 400,
  marginBottom: '4px',
  color: '#fff',
});

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCancelButton = styled(Button)({
  textTransform: 'none',
  width: '200px',
  height: '48px',
  fontSize: 16,
  marginTop: 24,
  marginRight: 16,
});

const StyledPurchaseButton = styled(Button)({
  textTransform: 'none',
  width: '200px',
  height: '48px',
  fontSize: 16,
  marginTop: 24,
});
