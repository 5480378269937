import { gql } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FC, forwardRef, useEffect, useMemo, useState } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { CircularProgress, IconButton } from '@mui/material';
import EditNote from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  PublicPartnerConnectionStatus,
  PublicPartnerFragmentFragment,
  PublicPartnerType,
  useDeletePublicPartnerMutation,
  usePublicPartnersQuery,
  useUpdatePartnerMutation,
} from 'graphql/types';
import { ConnectExistingButton } from './parts/ConnectExistingButton';
import { exhaustiveCheckNoError } from 'helpers/types';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { trackGoogleAnalyticsPageView } from 'helpers/analytics';
import { AlertDialog, AlertType } from 'components/dialog/AlertDialog';
import { EditPartnerNameModal } from './modal/EditPartnerNameModal';
import _ from 'lodash';
import { actions } from 'store/reducers/app';
import { pink } from '@mui/material/colors';

const LinkBehavior = forwardRef<any, Omit<LinkProps, 'to'>>((props, ref) => (
  <Link ref={ref} to="/connect" {...props} role={undefined} />
));

const DEFAULT_DATA: PublicPartnerFragmentFragment[] = [];

export const DashboardView: FC = () => {
  const { user } = useAuth0();
  const dispatch = useAppDispatch();
  const { data: publicPartnersResponse, loading: partnersLoading, refetch: refetchPartners } = usePublicPartnersQuery();
  const [deletePublicPartnerMutation, { loading: deleteLoading }] = useDeletePublicPartnerMutation();
  const [updatePublicPartnerMutation, { loading: updateLoading }] = useUpdatePartnerMutation();
  const currentPartnerName = useAppSelector((state) => state.app.partnerName);
  const currentPartnerUuid = useAppSelector((state) => state.app.partnerUUID);
  const currentPartnerId = useAppSelector((state) => state.app.partnerId);
  const [filterPartnerName, setFilterPartnerName] = useState<string | null>(null);
  const [filterPartnerUuid, setFilterPartnerUuid] = useState<string | null>(null);

  const [showDeletePartnerConfirmAlert, setShowDeletePartnerConfirmAlert] = useState(false);
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [editingPartnerId, setEditingPartnerId] = useState<string>();
  const [editingPartnerName, setEditingPartnerName] = useState<string>();
  const [alertType, setAlertType] = useState<AlertType>(AlertType.Warning);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const data = useMemo(() => {
    if (!publicPartnersResponse?.publicPartners) {
      return DEFAULT_DATA;
    } else {
      const users = publicPartnersResponse?.publicPartners;
      const readable = _.cloneDeep(users);
      readable.sort((a, b) => a.name.localeCompare(b.name));
      return readable;
    }
  }, [publicPartnersResponse]);

  const isDuplicatedPartner = data.filter((item) => item.name === currentPartnerName).length > 1;

  useEffect(() => {
    trackGoogleAnalyticsPageView('/', 'Dashboard');
  }, []);

  useEffect(() => {
    setFilterPartnerName(currentPartnerName);
    setFilterPartnerUuid(currentPartnerUuid);
  }, [currentPartnerName, currentPartnerUuid]);

  const handleDeletePartner = async () => {
    setShowDeletePartnerConfirmAlert(false);

    if (!editingPartnerId) {
      return;
    }

    const resultDelete = await deletePublicPartnerMutation({
      variables: {
        partnerId: editingPartnerId,
      },
    });
    if (resultDelete && resultDelete.data?.deletePublicPartner === true) {
      refetchPartners();
    }
    setEditingPartnerId(undefined);
  };

  const handleCancelDeletePartner = () => {
    setShowDeletePartnerConfirmAlert(false);
    setEditingPartnerId(undefined);
  };

  const handleRenamePartner = async (name: string) => {
    setOpenRenameModal(false);

    if (!editingPartnerId) {
      return;
    }

    const resultUpdate = await updatePublicPartnerMutation({
      variables: {
        partnerId: editingPartnerId,
        name: name,
      },
    });
    if (resultUpdate && resultUpdate.data?.updatePublicPartner) {
      if (editingPartnerId === currentPartnerId) {
        dispatch(actions.setPartnerName(name));
      }
      refetchPartners();
    }

    setEditingPartnerId(undefined);
    setEditingPartnerName(undefined);
  };

  const handleCancelRename = () => {
    setOpenRenameModal(false);
    setEditingPartnerId(undefined);
    setEditingPartnerName(undefined);
  };

  const onClickEdit = (index: number) => {
    const original = data[index];
    setEditingPartnerId(original.id);
    setEditingPartnerName(original.name);
    setOpenRenameModal(true);
  };

  const onClickDelete = (index: number) => {
    const original = data[index];
    setEditingPartnerId(original.id);
    setAlertType(AlertType.Warning);
    setAlertTitle(`⚠️ Delete connection ${original.name} ⚠️`);
    setAlertMessage(`This cannot be undone!

Are you sure you want to delete connection ${original.name} (${original.type}, ${original.marketplace})?`);
    setShowDeletePartnerConfirmAlert(true);
  };

  const Columns = [
    {
      label: 'Connection Name',
      name: 'name',
      options: {
        filter: true,
        sort: true,
        filterList: filterPartnerName ? [filterPartnerName] : [],
      },
    },
    {
      label: 'Connection Type',
      name: 'type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Connection Uuid',
      name: 'reasonPartnerUuid',
      options: {
        filter: true,
        sort: true,
        filterList: filterPartnerUuid && isDuplicatedPartner ? [filterPartnerUuid] : [],
      },
    },
    {
      label: 'Marketplace',
      name: 'marketplace',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Status',
      name: 'Connected',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: number) => {
          if (data.length > dataIndex) {
            const original = data[dataIndex];

            const requiresAdvertisingProfile =
              original.type === PublicPartnerType.Advertising && !original.advertisingProfileId;

            let displayString = '';
            switch (original.connectedStatus) {
              // Authorized, but not connected
              case PublicPartnerConnectionStatus.AuthorizationProgress:
                if (original.type === PublicPartnerType.Advertising && !original.advertisingProfileId) {
                  displayString = 'Select Advertising Profile';
                } else {
                  displayString = 'Onboarding in Progress';
                }
                break;

              // Not authorized or connected
              case PublicPartnerConnectionStatus.BeginAuthorization:
                displayString = 'Authorization Required';
                break;

              case PublicPartnerConnectionStatus.Connected:
                displayString = 'Connected';
                break;

              // Authorization will expire soon
              case PublicPartnerConnectionStatus.NeedsReauthorization:
                displayString = 'Needs re-authorization';
                break;

              // Authorization has expired
              case PublicPartnerConnectionStatus.AuthorizationExpired:
                displayString = 'Authorization expired';
                break;

              default:
                exhaustiveCheckNoError(original.connectedStatus);
                break;
            }

            const connectBtn =
              original.connectedStatus === PublicPartnerConnectionStatus.Connected && !requiresAdvertisingProfile ? (
                <ConnectExistingButton partner={original} isDisable />
              ) : (
                <ConnectExistingButton partner={original} />
              );

            if (!displayString && requiresAdvertisingProfile) {
              displayString = 'Select Advertising Profile';
            }

            return (
              <StyledIconContainer>
                {connectBtn}
                <Typography sx={{ fontSize: 12 }}>{displayString}</Typography>
              </StyledIconContainer>
            );
          }
        },
      },
    },
    {
      label: 'Actions',
      name: 'action',
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRenderLite: (dataIndex: number) => {
          if (data.length > dataIndex) {
            const original = data[dataIndex];

            const isDeletable = original.canBeDeleted;

            const editBtn = (
              <IconButton
                title="Rename API Connection"
                onClick={() => {
                  onClickEdit(dataIndex);
                }}
              >
                <EditNote />
              </IconButton>
            );
            const deleteBtn = (
              <IconButton
                title="Delete API Connection"
                sx={{ color: pink[500] }}
                onClick={() => {
                  onClickDelete(dataIndex);
                }}
              >
                <DeleteIcon />
              </IconButton>
            );

            return (
              <StyledIconContainer>
                {editBtn}
                {isDeletable && deleteBtn}
              </StyledIconContainer>
            );
          }
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRowsHideCheckboxes: true,
    onFilterChipClose: (index: number, removedFilter: string) => {
      if (removedFilter === filterPartnerName) {
        setFilterPartnerName(null);
      }
      if (removedFilter === filterPartnerUuid) {
        setFilterPartnerUuid(null);
      }
    },
  };

  const getChildren = () => {
    if (partnersLoading || deleteLoading || updateLoading) {
      return (
        <CircularProgress
          size={40}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        />
      );
    } else {
      if (data?.length) {
        return (
          <MUIDataTable
            title={`${currentPartnerName || 'API'} Connections`}
            columns={Columns}
            data={data}
            options={options}
          />
        );
      } else {
        return <Typography sx={{ fontSize: 20, fontWeight: 500, marginLeft: 8 }}>No Connections</Typography>;
      }
    }
  };

  return (
    <StyledContainer>
      <Typography textAlign={'center'}>Welcome {user?.email}</Typography>
      <CenteredContainer>
        <Button style={{ maxWidth: '33%' }} variant={'contained'} component={LinkBehavior}>
          Connect new Account
        </Button>
      </CenteredContainer>
      {getChildren()}
      <AlertDialog
        showDialog={showDeletePartnerConfirmAlert}
        type={alertType}
        title={alertTitle}
        message={alertMessage}
        okTitle="OK"
        onOK={handleDeletePartner}
        onClose={handleCancelDeletePartner}
      />
      <EditPartnerNameModal
        open={openRenameModal}
        name={editingPartnerName ?? ''}
        onOK={(name) => handleRenamePartner(name)}
        onClose={handleCancelRename}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIconContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const GetPublicPartnersQuery = gql`
  fragment PublicPartnerFragment on PublicPartner {
    advertisingProfileId
    createdAt
    id
    isConnected
    connectedStatus
    marketplace
    name
    type
    reasonPartnerName
    reasonPartnerUuid
    canBeDeleted
  }

  query PublicPartners {
    publicPartners {
      ...PublicPartnerFragment
    }
  }
`;

export const DeleteConnectionMutation = gql`
  mutation DeletePublicPartner($partnerId: String!) {
    deletePublicPartner(partnerId: $partnerId)
  }
`;

export const GetPowerBIReportPagesQuery = gql`
  fragment PowerBiReportPageFragment on PowerBIReportPage {
    reportId
    reportName
    groupId
    pageName
    displayName
    order
  }

  query GetPowerBIReportPages($reportId: String!, $groupId: String!) {
    listPowerBIReportPages(reportId: $reportId, groupId: $groupId) {
      ...PowerBiReportPageFragment
    }
  }
`;
