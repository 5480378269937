import { useFormikContext } from 'formik';
import { FC, useEffect, useMemo } from 'react';

import * as Fields from 'components/fields';
import { SelectOption } from 'components/fields';
import { Marketplace, MarketplaceFragment, MarketplaceUrlProductType, PublicPartnerType } from 'graphql/types';

import { ConnectAccountFormState } from './common';

export type ConnectAccountFormFieldsProps = {
  marketplaceByProduct: Record<PublicPartnerType, MarketplaceFragment[]>;
};

export const ConnectAccountFormFields: FC<ConnectAccountFormFieldsProps> = ({ marketplaceByProduct }) => {
  const { values, setFieldValue } = useFormikContext<ConnectAccountFormState>();

  const marketplaceOptions = useMemo(() => {
    return marketplaceByProduct[values.accountType].map(m => ({
      label: m.marketplace,
      value: m.marketplace,
    }));
  }, [values.accountType, marketplaceByProduct]);

  useEffect(() => {
    const matchingMarketplace = marketplaceByProduct[values.accountType].find(
      mbp => mbp.marketplace === values.marketplace
    );

    if (!matchingMarketplace) {
      const defaultOption =
        marketplaceByProduct[values.accountType].some(p => p.marketplace === Marketplace.Us)
          ? Marketplace.Us
          : marketplaceByProduct[values.accountType][0]?.marketplace;

      if (defaultOption) {
        setFieldValue('marketplace', defaultOption);
      }
    }
  }, [values.accountType, marketplaceByProduct, values.marketplace, setFieldValue]);

  return (
    <>
      <Fields.TextField label={'Brand Name'} name={'brandName'} required />
      <Fields.SelectField label={'Account Type'} name={'accountType'} options={ACCOUNT_TYPE_OPTIONS} required />
      <Fields.SelectField label={'Marketplace'} name={'marketplace'} options={marketplaceOptions} required />
    </>
  );
};

const ACCOUNT_TYPE_OPTIONS: SelectOption[] = [
  { label: 'Vendor Central', value: MarketplaceUrlProductType.Vendorcentral },
  { label: 'Seller Central', value: MarketplaceUrlProductType.Sellercentral },
  { label: 'Advertising', value: MarketplaceUrlProductType.Advertising },
];
