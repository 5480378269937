import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import styled from 'styled-components';

import { OAuthCallbackState } from './common';

export type OAuthErrorInfoProps = OAuthCallbackState;

export const OAuthErrorInfo: FC<OAuthErrorInfoProps> = ({ connect, success, errorMessage }) => {
  return (
    <>
      {!success && !errorMessage && (
        <StyledLoaderContainer>
          <CircularProgress />
        </StyledLoaderContainer>
      )}
      {!success && errorMessage && (
        <StyledContainer>
          <Typography color={'error'} variant={'subtitle2'}>
            Failed to connect due to error: {errorMessage}
          </Typography>
          <div>
            <Button onClick={connect}>Try Again</Button>
          </div>
        </StyledContainer>
      )}
    </>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledLoaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;
