import { Autocomplete, Button, TextField, Typography } from '@mui/material';
import { Modal } from 'components/modal/modal';
import { useCountries } from 'hooks/useCountries';

import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input';
import { CountryCode, getCountries } from 'libphonenumber-js';

export interface BillingInformationInputs {
  name: string;
  countryCode: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  phoneNumber: string | null;
}

type BillingInformationModalProps = {
  open: boolean;
  information: BillingInformationInputs | null,
  onRequestSave: (inputs: BillingInformationInputs) => void;
  onRequestClose?: () => void;
};

export const BillingInformationModal: FC<BillingInformationModalProps> = ({ open, information, onRequestSave, onRequestClose }) => {
  const [name, setName] = useState('');
  const [address1, setAddress1] = useState<string | null>(null);
  const [address2, setAddress2] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [state, setState] = useState<string | null>(null);
  const [postalCode, setPostalCode] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);

  const { countries, getCountryStates, getCountryByName } = useCountries();
  const [selectedCountry, setSelectedCountry] = useState('');
  const countryCodes = getCountries();
  const [phoneCountry, setPhoneCountry] = useState<CountryCode>('US');
  const [nationalNumber, setNationalNumber] = useState('');

  useEffect(() => {
    if (!information) {
      return;
    }

    setName(information.name);
    setAddress1(information.address1);
    setAddress2(information.address2);
    setCity(information.city);
    setPostalCode(information.postalCode);
    setNationalNumber(information.phoneNumber ?? '');
  }, [information]);

  useEffect(() => {
    const country = countries.find((c) => c.iso === 'US')
    setSelectedCountry(country?.name ?? '');
  }, [countries]);

  useEffect(() => {
    const country = getCountryByName(selectedCountry);
    const defaultCountry = countryCodes.find(c => country?.iso === c) as CountryCode;
    setPhoneCountry(defaultCountry ?? 'US');
  }, [selectedCountry, countryCodes, getCountryByName]);

  const countryOptions = useMemo(() => {
    return countries.map((c, index) => ({
      label: c.name, id: index + 1
    }));
  }, [countries]);

  const defaultCountryOption = useMemo(() => {
    const country = countries.find((c) => c.iso === information?.countryCode);
    return countryOptions.find((c) => c.label === country?.name);
  }, [countries, countryOptions, information]);

  const statesOptions = useMemo(() => {
    if (selectedCountry.length === 0) {
      return [];
    }

    const states = getCountryStates(selectedCountry);
    return states.map((s, index) => ({
      label: s.name, id: index + 1
    }));
  }, [selectedCountry, getCountryStates]);

  const defaultStateOption = useMemo(() => {
    return statesOptions.find((c) => c.label === information?.state);
  }, [statesOptions, information]);

  const handlePhoneChange = (value: string, info: MuiTelInputInfo) => {
    setNationalNumber(value);
    setPhoneNumber(info.numberValue);
  };

  const handleSave = () => {
    const information = {
      name: name,
      countryCode: phoneCountry,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      postalCode: postalCode,
      phoneNumber: phoneNumber,
    } as BillingInformationInputs;

    onRequestSave(information);
  };

  return (
    <Modal onRequestClose={onRequestClose} width={420} height={740} open={open} title='Billing information'>
      <StyledContainer>
        <InputLabel>Company Name</InputLabel>
        <TextField id="name"
          variant='outlined'
          size='small'
          defaultValue={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
          }}
        />
        <InputLabel sx={{ mt: '30px' }}>Address</InputLabel>
        <AddressContainer>
          <TextField id="address_1"
            variant='outlined'
            size='small'
            placeholder='Address line 1'
            InputLabelProps={{ shrink: false }}
            defaultValue={address1}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAddress1(event.target.value);
            }}
          />
          <TextField id="address_2"
            variant='outlined'
            size='small'
            placeholder='Address line 2'
            InputLabelProps={{ shrink: false }}
            defaultValue={address2}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAddress2(event.target.value);
            }}
          />
          <TextField id="city"
            variant='outlined'
            size='small'
            placeholder='City'
            InputLabelProps={{ shrink: false }}
            defaultValue={city}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCity(event.target.value);
            }}
          />
          <TextField id="postal_code"
            variant='outlined'
            size='small'
            placeholder='Postal code'
            InputLabelProps={{ shrink: false }}
            type='number'
            defaultValue={postalCode}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPostalCode(event.target.value);
            }}
          />
          <Autocomplete
            disablePortal
            id="combo-box-country"
            options={countryOptions}
            defaultValue={defaultCountryOption}
            onChange={(_event, newValue) => {
              setSelectedCountry(newValue?.label ?? '');
              setNationalNumber('');
            }}
            renderInput={(params) => <TextField {...params} variant='outlined' size='small' />}
          />
          <Autocomplete
            disablePortal
            id="combo-box-states"
            options={statesOptions}
            defaultValue={defaultStateOption}
            disabled={statesOptions.length === 0}
            renderInput={(params) => <TextField {...params} variant='outlined' size='small' />}
            onInputChange={(event, newInputValue) => {
              setState(newInputValue);
            }}
          />
        </AddressContainer>
        <InputLabel sx={{ mt: '30px' }}>Phone number</InputLabel>
        <HWrapper>
          <MuiTelInput
            defaultCountry={phoneCountry}
            forceCallingCode
            size='small'
            sx={{ flex: 1 }}
            value={nationalNumber}
            onChange={handlePhoneChange}
          />
        </HWrapper>
        <Button sx={{ mt: '40px' }} variant='contained' onClick={handleSave}>Save</Button>
        <Button sx={{ mt: '10px' }} variant='outlined' onClick={onRequestClose}>Cancel</Button>
      </StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const InputLabel = styled(Typography)({
  fontSize: 12,
  fontWeight: 400,
  marginBottom: '4px',
});

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const HWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;