import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { LogoutButton } from 'components/logoutButton/LogoutButton';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { gql } from '@apollo/client';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  DataReportNode,
  DataReportNodeType,
  PublicPartnersQuery,
  usePpManageYourDataNodesQuery,
  usePublicPartnersQuery,
} from 'graphql/types';
import { JSX } from 'react/jsx-runtime';
import { Button, Divider, IconButton, Menu, MenuItem, SvgIcon, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect } from 'react';
import { actions } from 'store/reducers/app';
import { CircularProgress } from '@mui/material';
import { BannerView } from 'components/banner/BannerView';
import '../../assets/css/fontawesome.css';
import '../../assets/css/brands.css';
import '../../assets/css/solid.css';
import styled from 'styled-components';
import { trackGoogleAnalyticsClickEvent } from 'helpers/analytics';

const minDrawerWidth = 140;
const maxDrawerWidth = 240;

const defaultData = [] as PublicPartnersQuery['publicPartners'];

export enum MenuItemType {
  Dashboard = 'dashboard',
  Connect = 'connect',
  Node = 'data',
  Account = 'account',
  Help = 'help',
  Reports = 'reports',
  Payment = 'payments',
}

export const Drawer: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [expand, setExpand] = useState(false);
  const [didCallPartners, setDidCallPartners] = useState(false);
  const [expandedRoots, setExpandedRoots] = useState<string[]>([]);
  const partnerId = useAppSelector((state) => state.app.partnerId);
  const partnerName = useAppSelector((state) => state.app.partnerName);
  const reloadManageNodes = useAppSelector((state) => state.app.reloadManageNodes);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();

  const { data: publicPartnersResponse, loading: partnerLoading, error: partnerError } = usePublicPartnersQuery();
  const {
    data: listNodesDataResponse,
    refetch: refetchManageYourDataNodesQuery,
    loading: isLoading,
  } = usePpManageYourDataNodesQuery({
    variables: {
      partnerId: partnerId ?? '',
    },
    skip: partnerLoading || !didCallPartners,
    fetchPolicy: 'no-cache',
  });
  const data = publicPartnersResponse?.publicPartners.filter((item) => !!item.reasonPartnerUuid) || defaultData;

  useEffect(() => {
    if (!partnerLoading && (!!data || !!partnerError)) {
      setDidCallPartners(true);
    }
    if (data.length > 0 && (!partnerId || partnerId?.length === 0)) {
      dispatch(actions.setPartnerId(data[0].id));
      dispatch(actions.setPartnerName(data[0].name));
      dispatch(actions.setPartnerUUID(data[0].reasonPartnerUuid ?? ''));
    }
    dispatch(actions.setReloadManageNodes(false));
  }, [data, dispatch, partnerId, partnerLoading, partnerError]);

  useEffect(() => {
    if (reloadManageNodes) {
      refetchManageYourDataNodesQuery({
        partnerId: partnerId ?? '',
      });
    }
  }, [reloadManageNodes, partnerId, refetchManageYourDataNodesQuery]);

  const selectedItem = useCallback(() => {
    const parts = location.pathname.slice(1).split('/');

    if (parts.length > 0) {
      if (parts[0] === MenuItemType.Connect) {
        return MenuItemType.Connect;
      } else if (parts[0] === MenuItemType.Payment) {
        return MenuItemType.Payment;
      } else if (parts[0].length > 0) {
        if (parts.length > 2 && parts[0] === 'account' && parts[1] === 'users' && parts[2] === 'brands') {
          return '/account/users';
        } else {
          return location.pathname;
        }
      }
    }

    return MenuItemType.Dashboard;
  }, [location]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    trackGoogleAnalyticsClickEvent('Partner DropDown Clicked', 'Drawer');
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (id: string) => {
    setAnchorEl(null);
    const selectedPartner = data.find((item) => item.id === id);
    if (selectedPartner && partnerId !== selectedPartner.id) {
      dispatch(actions.setPartnerId(selectedPartner.id));
      dispatch(actions.setPartnerName(selectedPartner.name));
      dispatch(actions.setPartnerUUID(selectedPartner.reasonPartnerUuid ?? ''));
    }
  };

  useEffect(() => {
    const setTabTitle = (title: string) => {
      document.title = title;
    };

    const _updateUserAccessLevel = (nodeType: DataReportNodeType) => {
      if (nodeType === DataReportNodeType.AccountUsersAndPermissions) {
        dispatch(actions.addToUserAccessLevels('ADMIN'));
      }
      if (nodeType === DataReportNodeType.AccountBilling) {
        dispatch(actions.addToUserAccessLevels('BILLING_ADMIN'));
      }
    }

    const listNodesData = listNodesDataResponse?.listNodesData || [];
    let isNodeItemSelected = false;
    dispatch(actions.clearUserAccessLevels(null));
    dispatch(actions.addToUserAccessLevels('MANAGE_DATA'));
    listNodesData.forEach((item) => {
      _updateUserAccessLevel(item.nodeType);

      if (item.nodeType === DataReportNodeType.Dashboard && selectedItem() === MenuItemType.Dashboard) {
        if (!expandedRoots.includes(item.nodeType) && expandedRoots.length === 0) {
          setExpandedRoots([item.nodeType]);
        }
        setTabTitle(item.pageTitle);
      }

      (item.childrenNodes || []).forEach((child) => {
        if (child.nodeType) {
          _updateUserAccessLevel(child.nodeType);

          if (selectedItem() === MenuItemType.Connect && child.nodeType === DataReportNodeType.ApiConnections) {
            // ApiConnection is started with 'connect'
            if (!expandedRoots.includes(item.nodeType) && expandedRoots.length === 0) {
              setExpandedRoots([item.nodeType]);
            }

            isNodeItemSelected = true;
            setTabTitle(child.pageTitle);
          } else if (selectedItem().toLowerCase() === child.urlPath?.toLowerCase()) {
            if (!expandedRoots.includes(item.nodeType) && expandedRoots.length === 0) {
              setExpandedRoots([item.nodeType]);
            }

            isNodeItemSelected = true;
            setTabTitle(child.pageTitle);
            // Attach nodeData if it's child of Root node
            if (item.nodeType === DataReportNodeType.Root) {
              const sNodeData = JSON.stringify(child.uploadFileDefinitions);
              dispatch(actions.setSelectedNodeData(sNodeData));
            }
          }
        }
      });
    });

    ///Redirect to root if path is not came from the api
    if (!isNodeItemSelected && !isLoading && didCallPartners) {
      if (selectedItem() !== MenuItemType.Dashboard) {
        // go to dashboard
        setExpandedRoots([]);
        console.warn('force navigate to home page');
        navigate('/');
      }
    }
  }, [listNodesDataResponse, selectedItem, dispatch, navigate, expandedRoots, isLoading, didCallPartners]);

  const getSelectedItemBanner = useCallback(() => {
    const listNodesData = listNodesDataResponse?.listNodesData || [];
    let selectedNodeBanner;
    for (const node of listNodesData) {
      if (node.nodeType === DataReportNodeType.Dashboard && selectedItem() === MenuItemType.Dashboard) {
        selectedNodeBanner = node.bannerAlarmDefinition || [];
        break;
      }

      for (const child of node.childrenNodes || []) {
        if (child.nodeType) {
          if (
            selectedItem().toLowerCase() === child.urlPath?.toLowerCase() ||
            (selectedItem() === MenuItemType.Connect && child.nodeType === DataReportNodeType.ApiConnections)
          ) {
            selectedNodeBanner = [...(child.bannerAlarmDefinition || []), ...(node.bannerAlarmDefinition || [])];
            break;
          }
        }
      }
    }
    return selectedNodeBanner || [];
  }, [selectedItem, listNodesDataResponse]);

  const drawerItemsList = useMemo(() => {
    const activeRoute = (path: string) => {
      const parts = location.pathname.slice(1).split('/');
      if (path === '/') {
        return !parts[0];
      } else {
        return parts[0] === path.slice(1);
      }
    };

    const onRootNodeSelected = (item: string) => {
      let roots = [...expandedRoots];
      if (roots.includes(item)) {
        roots = roots.filter((it) => it !== item);
      } else {
        roots = [item];
      }

      trackGoogleAnalyticsClickEvent(item, 'Drawer');
      setExpandedRoots(roots);
    };

    const isChildNodeSelected = (nodeType: DataReportNodeType) => {
      const parts = location.pathname.slice(1).split('/');
      if (parts.length > 0) {
        switch (nodeType) {
          case DataReportNodeType.Dashboard:
            return parts[0] === MenuItemType.Reports;
          case DataReportNodeType.Root:
            return parts[0] === MenuItemType.Node || parts[0] === MenuItemType.Connect;
          case DataReportNodeType.AccountRoot:
            return parts[0] === MenuItemType.Account;
          case DataReportNodeType.HelpRoot:
            return parts[0] === MenuItemType.Help;
          default:
            break;
        }
      }

      return false;
    };

    const createRootNode = (item: DataReportNode, showIcon: boolean) => {
      const isRootSelected =
        selectedItem() === MenuItemType.Dashboard && item.nodeType === DataReportNodeType.Dashboard;

      if (showIcon) {
        const fontAwesomeClasses = item.icon.fontAwesomeClasses || [];
        if (fontAwesomeClasses.length === 2) {
          return (
            <ListItem disablePadding key={`list_menu_${item.nodeType}`}>
              <ListItemButton
                key={`menu_${item.nodeType}`}
                component={NavLink}
                sx={ListItemBtnStyle}
                to={'/'}
                selected={activeRoute('/')}
                onClick={() => {
                  dispatch(actions.setSelectedNodeName(item.node));
                  onRootNodeSelected(item.nodeType);
                }}
              >
                <StyledIconRow isSelected={isRootSelected}>
                  <i className={`${fontAwesomeClasses[0]} ${fontAwesomeClasses[1]} fa-xl`}></i>
                  <StyledRowTypography sx={{ color: isRootSelected ? '#000' : '#23A4A4' }}>
                    {item.node}
                  </StyledRowTypography>
                </StyledIconRow>
              </ListItemButton>
            </ListItem>
          );
        } else {
          return null;
        }
      }

      const isChildSelected = expandedRoots.includes(item.nodeType) && isChildNodeSelected(item.nodeType);
      if (item.nodeType === DataReportNodeType.Dashboard) {
        return (
          <ListItem disablePadding key={`list_menu_${item.nodeType}`}>
            <ListItemButton
              key={`menu_${item.nodeType}`}
              component={NavLink}
              sx={ListItemBtnStyle}
              to={'/'}
              selected={activeRoute('/')}
              onClick={() => {
                dispatch(actions.setSelectedNodeName(item.node));
                onRootNodeSelected(item.nodeType);
              }}
            >
              <ListItemText
                primary={`+ ${item.node}`}
                sx={
                  selectedItem() === MenuItemType.Dashboard || isChildSelected
                    ? ListItemSelectedTextStyle
                    : ListItemTextStyle
                }
              />
            </ListItemButton>
          </ListItem>
        );
      }
      return (
        <ListItem disablePadding key={`list_menu_${item.nodeType}`}>
          <ListItemButton
            key={`menu_${item.nodeType}`}
            sx={ListItemBtnStyle}
            onClick={() => {
              dispatch(actions.setSelectedNodeName(item.node));
              onRootNodeSelected(item.nodeType);
            }
            }
          >
            <ListItemText
              primary={`+ ${item.node}`}
              sx={isChildSelected ? ListItemSelectedTextStyle : ListItemTextStyle}
            />
          </ListItemButton>
        </ListItem>
      );
    };

    const hasSupportedType = (item: DataReportNode) => {
      const supportedTypes = [
        DataReportNodeType.AccountBilling,
        DataReportNodeType.AccountRoot,
        DataReportNodeType.ApiConnections,
        DataReportNodeType.BannerAlarm,
        DataReportNodeType.Dashboard,
        DataReportNodeType.DashboardPbi,
        DataReportNodeType.FileUpload,
        DataReportNodeType.HelpReportProblem,
        DataReportNodeType.HelpRoot,
        DataReportNodeType.PowerbiReport,
        DataReportNodeType.Trackers,
        DataReportNodeType.DatabaseCredentials,
        DataReportNodeType.AccountUsersAndPermissions,
      ];

      return supportedTypes.includes(item.nodeType);
    };

    const getItemsNodes = (item: DataReportNode, showIcon: boolean) => {
      const nodesList: JSX.Element[] = [];
      const isExpand = expandedRoots.includes(item.nodeType);
      (item.childrenNodes || []).forEach((child, index) => {
        const fontAwesomeClasses = child.icon.fontAwesomeClasses || [];

        if (child.node && child.nodeType && !(child.hiddenInNavigationTree || false) && hasSupportedType(child)) {
          const isApiConnections = child.nodeType === DataReportNodeType.ApiConnections;
          const isNodeItemSelected = isApiConnections
            ? selectedItem().toLowerCase() === MenuItemType.Connect
            : selectedItem().toLowerCase() === child.urlPath?.toLowerCase();

          const routes = (child.urlPath || '').slice(1).split('/');

          if (routes.length > 0 && child.urlPath) {
            const toPath = isApiConnections ? `/${MenuItemType.Connect}/` : child.urlPath;

            if ((showIcon && fontAwesomeClasses.length === 2) || (!showIcon && isExpand)) {
              nodesList.push(
                <ListItem disablePadding key={`listmenu_${item.nodeType}_${child.nodeType}_${index}`}>
                  <ListItemButton
                    key={`menu_${item.nodeType}_${child.nodeType}_${index}`}
                    component={NavLink}
                    sx={ListItemBtnStyle}
                    to={toPath}
                    selected={activeRoute(isApiConnections ? `/${MenuItemType.Connect}` : routes[0])}
                    onClick={() => {
                      const nodeName = isApiConnections ? `/${MenuItemType.Connect}` : routes[0];
                      trackGoogleAnalyticsClickEvent(
                        nodeName,
                        'Drawer'
                      );
                      dispatch(actions.setSelectedNodeName(child.node));
                      if (item.nodeType === DataReportNodeType.Root && !isApiConnections) {
                        const sNodeData = JSON.stringify(child.uploadFileDefinitions);
                        dispatch(actions.setSelectedNodeData(sNodeData));
                      } else {
                        dispatch(actions.setSelectedNodeData(null));
                      }
                    }}
                  >
                    {showIcon && fontAwesomeClasses.length === 2 ? (
                      <StyledIconRow isSelected={isNodeItemSelected}>
                        <i className={`${fontAwesomeClasses[0]} ${fontAwesomeClasses[1]} fa-xl`}></i>
                        <StyledRowTypography sx={{ color: isNodeItemSelected ? '#000' : '#23A4A4' }}>
                          {child.node}
                        </StyledRowTypography>
                      </StyledIconRow>
                    ) : (
                      <ListItemText
                        primary={child.node}
                        sx={isNodeItemSelected ? ListItemSelectedTextStyle : ListItemTextStyle}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              );
            }
          }
        }
      });

      return nodesList;
    };

    let itemsList: JSX.Element[] = [];
    const listNodesData = listNodesDataResponse?.listNodesData || [];

    listNodesData.forEach((root) => {
      if (root.node && root.nodeType && !(root.hiddenInNavigationTree || false)) {
        const nodeList = getItemsNodes(root, !expand);
        const rootNode = createRootNode(root, !expand);
        if (rootNode) {
          itemsList.push(rootNode);
        }
        if (nodeList.length > 0) {
          if (expand) {
            itemsList.push(
              <Box
                key={`box_${root.nodeType}`}
                sx={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'auto', ml: 2 }}
              >
                {nodeList}
              </Box>
            );
          } else {
            itemsList = [...itemsList, ...nodeList];
          }
        }
      }
    });

    return itemsList;
  }, [expand, listNodesDataResponse, dispatch, expandedRoots, selectedItem, location.pathname]);

  const getBannerAlarm = () => {
    if (selectedItem().toLowerCase().includes('reports')) {
      return [];
    }

    const listNodesData = listNodesDataResponse?.listNodesData || [];
    const banner = listNodesData.find((item) => item.nodeType === DataReportNodeType.BannerAlarm);
    const selectedItemBanners = getSelectedItemBanner();

    const banners = [...selectedItemBanners, ...(banner?.bannerAlarmDefinition || [])];
    return banners;
  };

  const menusList = data.map((item, index) => {
    return (
      <MenuItem key={`dropdown_${index}`} onClick={() => handleClose(item.id)}>
        {item.name}
      </MenuItem>
    );
  });

  const drawerWidth = expand ? maxDrawerWidth : minDrawerWidth;
  return (
    <Box sx={{ display: 'flex', flex: 1 }}>
      <StyledDrawer open={expand} closed={!expand ? '1' : '0'} variant="permanent">
        <Box
          component={'img'}
          src="https://dm875zh7nwduu.cloudfront.net/images/ra-logo-black-content.png"
          sx={{
            width: drawerWidth - 48 < 200 ? drawerWidth - 32 : 200,
            mt: 2,
            ml: 2,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            overflow: 'auto',
            mt: 1,
            overflowWrap: 'anywhere',
          }}
        >
          {menusList.length > 0 && (
            <>
              <Typography
                sx={{
                  ml: '16px',
                  mb: '4px',
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                Connection:
              </Typography>
              <StyledDropDownButton
                sx={{ width: `${drawerWidth - 32}px` }}
                variant="outlined"
                href="#outlined-buttons"
                disabled={menusList.length === 1}
                endIcon={<KeyboardArrowDownIcon />}
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                {partnerName}
              </StyledDropDownButton>
              <Divider />
            </>
          )}
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            {menusList}
          </Menu>
          <List>
            {drawerItemsList}
          </List>
          <Divider sx={{ marginTop: 'auto' }} />
          <List>
            <ListItem disablePadding>
              <LogoutButton>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={'Logout'} />
              </LogoutButton>
            </ListItem>
          </List>
        </Box>
      </StyledDrawer>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          pl: 3,
          pr: 3,
          pb: 3,
          pt: 1,
          gap: 2,
          position: 'relative',
        }}
      >
        {getBannerAlarm().length > 0 && <BannerView data={getBannerAlarm()} />}
        {children}
        {(partnerLoading || isLoading) && (
          <CircularProgress
            size={40}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
            }}
          />
        )}
      </Box>
      <IconButton
        size="small"
        type={'button'}
        aria-label="expand"
        onClick={() => {
          setExpand(!expand);
        }}
        sx={{
          position: 'absolute',
          left: `${drawerWidth - 20}px`,
          top: '50%',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: (theme) => theme.palette.background.default,
          boxShadow: '1px 1px 9px #00000055',
        }}
      >
        <SvgIcon>{expand ? <ChevronLeftIcon /> : <ChevronRightIcon />}</SvgIcon>
      </IconButton>
    </Box>
  );
};

/* eslint-disable @typescript-eslint/indent */
const StyledDrawer = styled(MuiDrawer) <{ open: boolean; closed: string }>`
  width: 240px;
  flex-shrink: 0;
  whitespace: 'nowrap';
  ${(p) =>
    p.open &&
    `
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    width: 240px;
  `}
  ${(p) =>
    p.closed === '1' &&
    `
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    overflow-x: hidden;
    width: 140px;
  
  `}

  .MuiPaper-root {
    ${(p) =>
    p.open &&
    `
      transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      width: 240px;
    `}
    ${(p) =>
    p.closed === '1' &&
    `
      transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      overflow-x: hidden;
      width: 140px;
    `}
  }
`;
/* eslint-enable @typescript-eslint/indent */

const ListItemTextStyle = {
  '& .MuiListItemText-primary': {
    color: '#23A4A4',
    fontSize: 12,
    fontWeight: 800,
    overflow: 'hidden',
  },
};

const ListItemSelectedTextStyle = {
  '& .MuiListItemText-primary': {
    color: 'black',
    fontSize: 12,
    fontWeight: 800,
    overflow: 'hidden',
  },
};

const ListItemBtnStyle = {
  '&.Mui-selected': {
    backgroundColor: 'transparent',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
  ':hover': {
    backgroundColor: 'transparent',
  },
};

export const ManageYourDataNodesQuery = gql`
  query pp_manageYourDataNodes($partnerId: String!) {
    listNodesData(partnerId: $partnerId) {
      # L0 - root(s)
      ...dataNode
      childrenNodes {
        # L1
        ...dataNode
        childrenNodes {
          # L2
          ...dataNode
        }
      }
    }
  }

  fragment dataNode on DataReportNode {
    pageTitle
    node
    nodeType
    urlPath
    icon {
      fontAwesomeClasses
      httpIcon
    }
    hiddenInNavigationTree
    showPartnerSelectionDropdown
    uploadFileDefinitions {
      dataTypeName
      tableName
      hasDataUploaded
      lastUpdatedOn
      reportType
      fields {
        name
        regex
        regexErrorMessage
      }
      uniqueFields {
        fieldNames
      }
      downloadInstructions
    }
    bannerAlarmDefinition {
      id
      activatedOn
      activatedUntil
      canBeDiscarded
      titleHtml
      detailsHtml
      nodeTypePath
    }
    powerBIReportDefinition {
      name
      id
      groupId
    }
  }
`;

const StyledDropDownButton = styled(Button)({
  outlineColor: '#D9D9D9',
  textTransform: 'none',
  color: 'black',
  fontSize: 12,
  fontWeight: 600,
  marginLeft: '16px',
  marginBottom: '16px',
});

const StyledIconRow = styled.div<{ isSelected: boolean }>`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 16px;
  padding: 8px;
  color: #23a4a4;

  ${(p) =>
    p.isSelected &&
    `
    color: #000;
  `}
`;

const StyledRowTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
  width: '90px',
  textAlign: 'center',
});
