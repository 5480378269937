import { Button, Icon, IconButton, TableCell, Typography } from '@mui/material';
import { DataTableSource, DataTableSourceCheckType } from 'helpers/userPermissions';
import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { green, grey } from '@mui/material/colors';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { Warning } from '@mui/icons-material';
import { SaveDataSubscriptionModal } from './modals/SaveDataSubscriptionModal';
import { MissingPermissionsModal } from './modals/MissingPermissionsModal';
import { BackfillOrderModal } from './modals/BackfillOrderModal';

export const DataTablesView: FC = () => {
  const [data, setData] = useState<DataTableSource[]>([]);
  const [changed, setChanged] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [openMissingPermission, setOpenMissingPermission] = useState(false);
  const [openBackfill, setOpenBackfill] = useState(false);

  useEffect(() => {
    const data = [
      {
        checkType: DataTableSourceCheckType.checked,
        table: 'retail_analytics_sales',
        friendlyName: 'Retail Analytics Sales',
        updateFrequency: 'Daily',
        periods: 'Daily, Weekly, Monthly',
        description: 'Integer eget aliquet nibh praesent. Vitae turpis massased elementum...',
        enableBackfill: true,
      },
      {
        checkType: DataTableSourceCheckType.unchecked,
        table: 'retail_analytics_trafic',
        friendlyName: 'Retail Analytics Trafic',
        updateFrequency: 'Daily',
        periods: 'Daily, Weekly, Monthly',
        description: 'At quis risus sed vulputate odio ut enim blandit. Dui ut ornare lectus sit.',
        enableBackfill: true,
      },
      {
        checkType: DataTableSourceCheckType.warning,
        table: 'product_catalog',
        friendlyName: 'Product Catalog',
        updateFrequency: 'Daily',
        periods: 'Snapshot',
        description: 'Nisl condimentum id venenatis a condimentum vitae.',
        enableBackfill: false,
      },
    ];
    setData(data);
  }, []);

  const handleSaveChanges = () => {
    setOpenSave(false);
  };

  const handleClickSave = () => {
    setOpenSave(true);
  };

  const Columns = useMemo(() => {
    const handleCheck = (index: number) => {
      const newItem = data[index];
      if (newItem.checkType === DataTableSourceCheckType.warning) {
        setOpenMissingPermission(true);
      } else {
        newItem.checkType =
          newItem.checkType === DataTableSourceCheckType.checked
            ? DataTableSourceCheckType.unchecked
            : DataTableSourceCheckType.checked;
        const newData = data.map((item, itemIndex) => (itemIndex === index ? { ...item, ...newItem } : item));
        setData(newData);
        setChanged(true);
      }
    };

    const handleBackfill = (index: number) => {
      setOpenBackfill(true);
    };

    return [
      {
        label: 'User Email',
        name: 'checked',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customHeadRender: () => {
            return (
              <TableCell>
                <StyledIconContainer style={{ paddingLeft: 8 }}>
                  <CheckBoxIcon sx={{ color: green[500] }} />
                </StyledIconContainer>
              </TableCell>
            );
          },
          customBodyRenderLite: (dataIndex: number) => {
            const checkType = data[dataIndex].checkType;

            const childIcon = () => {
              switch (checkType) {
                case DataTableSourceCheckType.checked:
                  return <CheckBoxIcon />;
                case DataTableSourceCheckType.unchecked:
                  return <Icon />;
                case DataTableSourceCheckType.warning:
                  return <Warning />;
              }
            };

            return (
              <StyledIconContainer>
                <IconButton
                  aria-label="user-admin"
                  sx={{ color: checkType === DataTableSourceCheckType.warning ? grey[600] : green[500] }}
                  onClick={() => {
                    handleCheck(dataIndex);
                  }}
                >
                  {childIcon()}
                </IconButton>
              </StyledIconContainer>
            );
          },
        },
      },
      {
        label: 'Table',
        name: 'table',
      },
      {
        label: 'Friendly Name',
        name: 'friendlyName',
      },
      {
        label: 'Update Frequency',
        name: 'updateFrequency',
      },
      {
        label: 'Periods',
        name: 'periods',
      },
      {
        label: 'Description',
        name: 'description',
      },
      {
        label: 'Backfill',
        name: 'enableBackfill',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex: number) => {
            const enabled = data[dataIndex].enableBackfill;

            return (
              <StyledBackfillButton
                disabled={!enabled}
                variant={'contained'}
                onClick={() => {
                  handleBackfill(dataIndex);
                }}
              >
                Backfill
              </StyledBackfillButton>
            );
          },
        },
      },
    ];
  }, [data]);

  const options: MUIDataTableOptions = {
    print: false,
    download: false,
    selectableRowsHideCheckboxes: true,
  };

  return (
    <StyledContainer>
      <Typography
        textAlign={'left'}
        sx={{
          fontSize: 30,
          fontWeight: 800,
          color: '#000000',
        }}
      >
        Tables
      </Typography>
      <StyledButtonContainer>
        <StyledButton disabled={!changed} variant={'contained'} onClick={handleClickSave}>
          SAVE CHANGES
        </StyledButton>
      </StyledButtonContainer>
      <MUIDataTable title="" columns={Columns} data={data} options={options} />
      <StyledButtonContainer>
        <StyledButton disabled={!changed} variant={'contained'} onClick={handleClickSave}>
          SAVE CHANGES
        </StyledButton>
      </StyledButtonContainer>
      <SaveDataSubscriptionModal
        open={openSave}
        onSave={handleSaveChanges}
        onCancel={() => {
          setOpenSave(false);
        }}
      />
      <MissingPermissionsModal
        open={openMissingPermission}
        onOK={() => setOpenMissingPermission(false)}
        onContact={() => {
          setOpenMissingPermission(false);
        }}
      />
      <BackfillOrderModal
        open={openBackfill}
        onClose={() => {
          setOpenBackfill(false);
        }}
        onSubmit={() => {
          setOpenBackfill(false);
        }}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
  gap: 32px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

const StyledButton = styled(Button)({
  textTransform: 'none',
  width: '160px',
  height: '40px',
  backgroundColor: '#23A4A4',
});

const StyledIconContainer = styled.div`
  align-items: left;
  display: flex;
  gap: 8px;
`;

const StyledBackfillButton = styled(Button)({
  textTransform: 'none',
  width: '80px',
  height: '32px',
  backgroundColor: '#23A4A4',
});
