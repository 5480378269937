import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material';
import { FC, PropsWithChildren, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from 'models/environment';
import { ApolloClientProvider } from 'context/apolloClientContext';
import { MuiNotificationHub } from 'components/muiNotificationHub/MuiNotificationHub';
import { MuiNotificationContextProvider } from 'context/muiNotificationContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    success: {
      main: '#4caf50',
    },
    error: {
      main: '#f44336',
    },
  },
});

export const Providers: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = useCallback<NonNullable<Auth0ProviderOptions['onRedirectCallback']>>(
    (appState) => {
      navigate(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
    },
    [navigate]
  );

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={`${window.location.origin}`}
      onRedirectCallback={onRedirectCallback}
    >
      <HubspotProvider async>
        <ThemeProvider theme={theme}>
          <MuiNotificationContextProvider>
            <MuiNotificationHub />
            <ApolloClientProvider>{children}</ApolloClientProvider>
          </MuiNotificationContextProvider>
        </ThemeProvider>
      </HubspotProvider>
    </Auth0Provider>
  );
};
