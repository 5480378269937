import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { WizardStepProps } from './common';

export type WizardLayoutProps = WizardStepProps & {
  header?: (props: WizardStepProps) => JSX.Element | null;
};

export const WizardLayout: FC<PropsWithChildren<WizardLayoutProps>> = ({ children, header, ...stepProps }) => {
  const Header = header;

  return (
    <>
      {Header && (
        <StyledHeader>
          <Header {...stepProps} />
        </StyledHeader>
      )}
      <StyledMain>{children}</StyledMain>
    </>
  );
};

const StyledHeader = styled.header`
  padding: 16px;
  padding-bottom: 0;
`;

const StyledMain = styled.main`
  padding: 16px;
`;
