import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useMuiNotifications } from 'context/muiNotificationContext';
import { Notification } from './parts/Notification';
import { usePrevious } from 'hooks/usePreviousValue';

export const MuiNotificationHub: FC = () => {
  const { notifications, removeNotification } = useMuiNotifications();
  const [root, setRoot] = useState<HTMLDivElement | null>(null);

  const notification = useMemo(() => {
    return notifications[0];
  }, [notifications]);
  const previousNotification = usePrevious(notification);
  const timer = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (notification && previousNotification !== notification) {
      timer.current = window.setTimeout(() => {
        removeNotification();
      }, notification.duration || 2500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification, removeNotification]);

  useEffect(() => {
    const element = document.createElement('div');
    element.setAttribute('id', 'mui-notification-content');

    document.body.appendChild(element);
    setRoot(element);

    return () => {
      document.body.removeChild(element);
      clearTimeout(timer.current);
    };
  }, []);

  if (!notification || !root) {
    return null;
  }

  return createPortal(<Notification onRequestClose={removeNotification} {...notification} />, root);
};
