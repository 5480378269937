import { Button, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { BillingInformationInputs, BillingInformationModal } from '../modals/BillingInformationModal';
import { gql } from '@apollo/client';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useStripeGetBillingInformationQuery, useStripeSetBillingInformationMutation } from 'graphql/types';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { actions } from 'store/reducers/app';
import { useMuiNotifications } from 'context/muiNotificationContext';
import { useNavigate } from 'react-router-dom';

export const BillingInformationView: FC = () => {
  const navigate = useNavigate();
  const { createSuccessNotification, createFailureNotification } = useMuiNotifications();
  const partnerId = useAppSelector((state) => state.app.partnerId);
  const [setBillingInformationMutation, { loading }] = useStripeSetBillingInformationMutation();
  const [information, setInformation] = useState<BillingInformationInputs | null>(null);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useAppDispatch();

  const { data: informationResponse, refetch: refetchInformationQuery } = useStripeGetBillingInformationQuery({
    variables: {
      partnerId: partnerId ?? '',
    },
  });

  useEffect(() => {
    dispatch(actions.setSavingBillingInformation(loading));
  }, [loading, dispatch]);

  useEffect(() => {
    refetchInformationQuery();
  }, [partnerId, refetchInformationQuery]);

  useEffect(() => {
    const info = {
      name: informationResponse?.getBillingInformationStripe?.name,
      countryCode: informationResponse?.getBillingInformationStripe?.countryCode,
      address1: informationResponse?.getBillingInformationStripe?.addressLine1,
      address2: informationResponse?.getBillingInformationStripe?.addressLine2,
      city: informationResponse?.getBillingInformationStripe?.city,
      state: informationResponse?.getBillingInformationStripe?.state,
      postalCode: informationResponse?.getBillingInformationStripe?.postalCode,
      phoneNumber: informationResponse?.getBillingInformationStripe?.phoneNumber,
    } as BillingInformationInputs;
    setInformation(info);
  }, [informationResponse]);

  const handleSave = async (inputs: BillingInformationInputs) => {
    setShowModal(false);

    if (!partnerId) {
      return;
    }

    try {
      const result = await setBillingInformationMutation({
        variables: {
          partnerId: partnerId,
          name: inputs.name,
          phoneNumber: inputs.phoneNumber,
          addressLine1: inputs.address1,
          addressLine2: inputs.address2,
          city: inputs.city,
          postalCode: inputs.postalCode,
          state: inputs.state,
          countryCode: inputs.countryCode,
        },
      });

      if (result && result.data?.setBillingInformationStripe === true) {
        refetchInformationQuery();
        createSuccessNotification(`Updated billing information.`);
      }
    } catch (e) {
      createFailureNotification(`Failed to perform operation. Please contact support. ${e}`);
    }
  };

  const name = useMemo(() => {
    if (!informationResponse?.getBillingInformationStripe) {
      return '';
    }
    return informationResponse.getBillingInformationStripe.name;
  }, [informationResponse]);

  const address = useMemo(() => {
    const information = informationResponse?.getBillingInformationStripe;

    if (!information) {
      return '';
    }

    let addressLine = '';

    if (!!information.addressLine1) {
      addressLine = information.addressLine1;
    }

    if (!!information.addressLine2) {
      if (addressLine.length > 0) {
        addressLine = addressLine + ' ' + information.addressLine2;
      } else {
        addressLine = information.addressLine2;
      }
    }

    if (!!information.city) {
      if (addressLine.length > 0) {
        addressLine = addressLine + ' ' + information.city;
      } else {
        addressLine = information.city;
      }
    }

    let regionLine = '';

    if (!!information.state) {
      regionLine = information.state;
    }

    if (!!information.postalCode) {
      if (regionLine.length > 0) {
        regionLine = regionLine + ' ' + information.postalCode;
      } else {
        regionLine = information.postalCode;
      }
    }

    if (!!information.countryCode) {
      if (regionLine.length > 0) {
        regionLine = regionLine + ' ' + information.countryCode;
      } else {
        regionLine = information.countryCode;
      }
    }

    if (addressLine.length > 0) {
      return addressLine + ', ' + regionLine;
    } else {
      return regionLine;
    }
  }, [informationResponse]);

  const phoneNumber = useMemo(() => {
    let number = '';

    if (!!informationResponse?.getBillingInformationStripe?.phoneNumber) {
      number = informationResponse?.getBillingInformationStripe?.phoneNumber;
    }

    if (isValidPhoneNumber(number)) {
      return parsePhoneNumber(number).formatNational();
    } else {
      return number;
    }
  }, [informationResponse]);

  return (
    <>
      <StyledContainer>
        <BillingInfo title="Company name" value={name} />
        <BillingInfo title="Billing address" value={address} />
        <BillingInfo title="Phone number" value={phoneNumber} />
        <UpdateButton startIcon={<EditNoteIcon />} onClick={() => setShowModal(true)}>
          Update information
        </UpdateButton>
        <UpdateButton
          startIcon={<EditNoteIcon />}
          onClick={() =>
            navigate(`/help/report_problem`, {
              state: {},
            })
          }
        >
          Update billing contact
        </UpdateButton>
      </StyledContainer>
      <BillingInformationModal
        open={showModal}
        information={information}
        onRequestSave={(value) => handleSave(value)}
        onRequestClose={() => setShowModal(false)}
      />
    </>
  );
};

type BillingInfoProps = {
  title: string;
  value: string;
};
const BillingInfo: FC<BillingInfoProps> = ({ title, value }) => {
  return (
    <StyledHWrapper>
      <TitleTypography>{title}</TitleTypography>
      <ValueTypography>{value}</ValueTypography>
    </StyledHWrapper>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  padding-top: 8px;
  padding-bottom: 60px;
`;

const StyledHWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const TitleTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
  color: '#616161',
  width: '160px',
});

const ValueTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
  width: '160px',
});

const UpdateButton = styled(Button)({
  fontSize: 14,
  fontWeight: 400,
  width: 'fit-content',
  textTransform: 'none',
  color: '#424242',
  padding: 0,
  minHeight: 0,
  minWidth: 0,
  marginTop: '4px',
});

export const StripeGetBillingInformationQuery = gql`
  query StripeGetBillingInformation($partnerId: String!) {
    getBillingInformationStripe(partnerId: $partnerId) {
      name
      phoneNumber
      addressLine1
      addressLine2
      city
      postalCode
      state
      countryCode
    }
  }
`;

export const StripeSetBillingInformationMutation = gql`
  mutation StripeSetBillingInformation(
    $partnerId: String!
    $name: String!
    $phoneNumber: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $postalCode: String
    $state: String
    $countryCode: String
  ) {
    setBillingInformationStripe(
      partnerId: $partnerId
      billingInformation: {
        name: $name
        phoneNumber: $phoneNumber
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        city: $city
        postalCode: $postalCode
        state: $state
        countryCode: $countryCode
      }
    )
  }
`;
