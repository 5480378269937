import { Box, Button, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import '../../../../assets/css/fontawesome.css';
import '../../../../assets/css/brands.css';
import '../../../../assets/css/solid.css';
import { InvoiceDetailsModal } from '../modals/InvoiceDetailsModal';
import { gql } from '@apollo/client';
import { StripeCurrencyType, StripeInvoice, useStripeGetInvoiceHistoryQuery } from 'graphql/types';
import { useAppSelector } from 'store/hooks';
import moment from 'moment';

export const BillingInvoiceHistoryView: FC = () => {
  const partnerId = useAppSelector((state) => state.app.partnerId);
  const [invoice, setInvoice] = useState<StripeInvoice | null>(null);
  const [showModal, setShowModal] = useState(false);

  const {
    data: historyResponse,
    refetch: refetchHistoryQuery
  } = useStripeGetInvoiceHistoryQuery({
    variables: {
      partnerId: partnerId ?? '',
    },
  });

  useEffect(() => {
    refetchHistoryQuery();
  }, [partnerId, refetchHistoryQuery]);

  const invoices = useMemo(() => {
    if (!historyResponse?.getInvoiceHistoryStripe) {
      return [];
    }
    return historyResponse.getInvoiceHistoryStripe;
  }, [historyResponse]);

  return (
    <>
      <StyledContainer>
        {invoices.map((invoice) => (
          <DetailButton
            key={invoice.id}
            onClick={() => {
              setInvoice(invoice);
              setShowModal(true);
            }}>
            <InvoiceView invoice={invoice} />
          </DetailButton>
        ))}
      </StyledContainer>
      {invoice &&
        <InvoiceDetailsModal
          invoice={invoice}
          open={showModal}
          onRequestClose={() => setShowModal(false)}
        />
      }
    </>
  );
};

type InvoiceViewProps = {
  invoice: StripeInvoice;
}

const InvoiceView: FC<InvoiceViewProps> = ({ invoice }) => {
  const dateString = useMemo(() => {
    if (!invoice.dueDate) {
      return '';
    }
    return moment(invoice.dueDate).format('MMMM D, YYYY');
  }, [invoice]);

  const priceString = useMemo(() => {
    let symbol = '$';
    switch (invoice.currency) {
      case StripeCurrencyType.Usd:
        symbol = '$';
        break;
    }
    return symbol + (invoice.amountPaid / 100).toFixed(2).toString();
  }, [invoice]);

  const invoiceName = useMemo(() => {
    if (!invoice.accountName) {
      return '';
    }
    return invoice.accountName;
  }, [invoice]);

  const invoiceStatus = useMemo(() => {
    if (!invoice.status) {
      return '';
    }

    return invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1);;
  }, [invoice]);

  return (
    <StyledHWrapper>
      <ColumnContainer>
        <StyledTypography>{dateString}</StyledTypography>
        <i className="fa-solid fa-up-right-from-square fa-xs"></i>
      </ColumnContainer>
      <ColumnContainer>
        <StyledTypography>{priceString}</StyledTypography>
      </ColumnContainer>
      <ColumnContainer>
        <Box border={1} bgcolor='rgb(161, 235, 142, 0.5)' borderColor='rgb(161, 235, 142)' borderRadius='5px'>
          <StatusTypography>{invoiceStatus}</StatusTypography>
        </Box>
      </ColumnContainer>
      <ColumnContainer style={{ width: 240 }}>
        <StyledTypography>{invoiceName}</StyledTypography>
      </ColumnContainer>
    </StyledHWrapper>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;  
  gap: 16px;
  position: relative;
  padding-top: 8px;
  padding-bottom: 60px;
`;

const StyledHWrapper = styled.div`
  display: flex;
  flex-direction: row;  
  gap: 10px;
`;

const StyledTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
});

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;  
  gap: 8px;
  width: 160px;
  align-items: center;
`;

const StatusTypography = styled(Typography)({
  fontSize: 12,
  fontWeight: 400,
  paddingLeft: '8px',
  paddingRight: '8px',
  paddingTop: '2px',
  paddingBottom: '2px',
});

const DetailButton = styled(Button)({
  fontSize: 14,
  fontWeight: 400,
  width: 'fit-content',
  textTransform: 'none',
  color: '#424242',
  padding: 0,
  minHeight: 0,
  minWidth: 0,
  marginTop: '4px',
});

export const StripeGetInvoiceHistoryQuery = gql`
  query StripeGetInvoiceHistory($partnerId: String!) {
    getInvoiceHistoryStripe
    (
      partnerId: $partnerId
    )
    {
      id
      invoiceNumber
      receiptNumber
      dueDate
      periodStart
      periodEnd
      invoicePdf
      status
      currency
      amount
      amountPaid
      accountName
      customerName
      paymentMethodId
      receiptUrl
      cardType
      cardLast4
      cardExpirationMonth
      cardExpirationYear
      bankAccountType
      bankAccountLast4
      bankName
    }
  }
`;