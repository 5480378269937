import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useField } from 'formik';
import { FC } from 'react';

export type SelectOption = {
  label: string;
  value: string;
};

export type SelectFieldProps = {
  className?: string;
  fullWidth?: boolean;
  label: string;
  name: string;
  options: SelectOption[];
  required?: boolean;
};

export const SelectField: FC<SelectFieldProps> = ({ className, fullWidth = true, label, name, options, required }) => {
  const [fieldProps, { error }] = useField(name);

  const hasError = !!error;

  return (
    <FormControl fullWidth={fullWidth} error={hasError} required={required}>
      <InputLabel id={name} error={hasError} required={required}>
        {label}
      </InputLabel>
      <Select className={className} labelId={name} label={label} {...fieldProps} error={hasError} required={required}>
        {options.map(o => {
          return (
            <MenuItem key={o.value} value={o.value}>
              {o.label}
            </MenuItem>
          );
        })}
      </Select>
      {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
    </FormControl>
  );
};
