import { useAuth0 } from '@auth0/auth0-react';
import ListItemButton from '@mui/material/ListItemButton';
import { FC, PropsWithChildren } from 'react';

export type LogoutButtonProps = {
  className?: string;
};

export const LogoutButton: FC<PropsWithChildren<LogoutButtonProps>> = ({ children, className }) => {
  const { logout } = useAuth0();

  return (
    <ListItemButton className={className} onClick={() => logout({ returnTo: `${window.location.origin}` })}>
      {children}
    </ListItemButton>
  );
};
