import { Typography, Icon, IconButton, Button } from '@mui/material';
import { Modal } from 'components/modal/modal';
import { BackfillOrderSource, DataTableSourceCheckType } from 'helpers/userPermissions';
import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Warning } from '@mui/icons-material';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { green } from '@mui/material/colors';
import { BackfillOrderConfirmPurchaseModal } from './BackfillOrderConfirmPurchaseModal';

type BackfillOrderModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const BackfillOrderModal: FC<BackfillOrderModalProps> = ({ open, onClose, onSubmit }) => {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const [data, setData] = useState<BackfillOrderSource[]>([]);
  const [changed, setChanged] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(() => {
    const data = [
      { table: 'retail_analytics_sales', friendlyName: 'Retail Analytics Sales', periods: 'Daily, Weekly, Monthly', availableHistory: '1095 days, 6 weeks, 24 months', description: 'Integer eget aliquet nibh praesent. Vitae turpis massased elementum...', backfill: DataTableSourceCheckType.unchecked, price: '$35' },
      { table: 'retail_analytics_trafic', friendlyName: 'Retail Analytics Trafic', periods: 'Daily, Weekly, Monthly', availableHistory: '1095 days, 6 weeks, 24 months', description: 'At quis risus sed vulputate odio ut enim blandit. Dui ut ornare lectus sit.', backfill: DataTableSourceCheckType.unchecked, price: '$20' },
      { table: 'product_catalog', friendlyName: 'Product Catalog', periods: 'Snapshot', availableHistory: 'None', description: 'Nisl condimentum id venenatis a condimentum vitae.', backfill: DataTableSourceCheckType.warning, price: '' },
    ];
    setData(data);
  }, []);

  const handleClickSubmit = () => {
    setOpenConfirm(true);
  };

  const handleStart = () => {
    setOpenConfirm(false);
    onSubmit();
  };

  const Columns = useMemo(() => {
    const handleCheck = (index: number) => {
      const newItem = data[index];
      if (newItem.backfill === DataTableSourceCheckType.warning) {

      } else {
        newItem.backfill = newItem.backfill === DataTableSourceCheckType.checked ? DataTableSourceCheckType.unchecked : DataTableSourceCheckType.checked;
        const newData = data.map((item, itemIndex) => (itemIndex === index ? { ...item, ...newItem } : item));
        setData(newData);
        setChanged(true);
      }
    };

    return [
      {
        label: 'Table',
        name: 'table',
      },
      {
        label: 'Friendly Name',
        name: 'friendlyName',
      },
      {
        label: 'Periods',
        name: 'periods',
      },
      {
        label: 'Available History',
        name: 'availableHistory',
      },
      {
        label: 'Backfill?',
        name: 'backfill',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex: number) => {
            const checkType = data[dataIndex].backfill;

            const childIcon = () => {
              switch (checkType) {
                case DataTableSourceCheckType.checked:
                  return <CheckBoxIcon />;
                case DataTableSourceCheckType.unchecked:
                  return <Icon />;
                case DataTableSourceCheckType.warning:
                  return <Warning />;
              }
            };

            return (
              <StyledIconContainer>
                <IconButton aria-label="user-admin" sx={{ color: checkType === DataTableSourceCheckType.warning ? '#000' : green[500] }} disabled={checkType === DataTableSourceCheckType.warning} onClick={() => {
                  handleCheck(dataIndex);
                }}>
                  {childIcon()}
                </IconButton>
              </StyledIconContainer>
            );
          },
        },
      },
      {
        label: 'Price',
        name: 'price',
      },
    ];
  }, [data]);

  const options: MUIDataTableOptions = {
    print: false,
    download: false,
    search: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Modal width={windowWidth - 480} height={windowHeight - 180} open={open} onClickOutside={onClose} title='Historical Data Backfill Tool'>
      <StyledContainer>
        <StyledLabelContainer>
          <StyledNormalTypography>Use this tool to </StyledNormalTypography>
          <StyledBoldTypography>backfill historical data from Amazon into your database.</StyledBoldTypography>
        </StyledLabelContainer>
        <MUIDataTable title='' columns={Columns} data={data} options={options} />
        <StyledRightContainer>
          <StyledPriceContainer style={{ width: 280, marginRight: 80, marginTop: 12 }}>
            <StyledBoldTypography style={{ fontSize: 16 }}>Total price</StyledBoldTypography>
            <StyledBoldTypography style={{ fontSize: 16 }}>$35</StyledBoldTypography>
          </StyledPriceContainer>
        </StyledRightContainer>
        <StyledCenteredContainer>
          <StyledSubmitButton variant={'contained'} disabled={!changed} onClick={handleClickSubmit}>
            Submit backfill order
          </StyledSubmitButton>
        </StyledCenteredContainer>
        <BackfillOrderConfirmPurchaseModal open={openConfirm} onClose={() => { setOpenConfirm(false); }} onPurchase={handleStart} />
      </StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
  gap: 16px;
`;

const StyledLabelContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 4px;
`;

const StyledNormalTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
});

const StyledBoldTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 600,
});

const StyledIconContainer = styled.div`
  align-items: left;
  display: flex;
  gap: 8px;
`;

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledRightContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
`;

const StyledCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSubmitButton = styled(Button)({
  textTransform: 'none',
  width: '200px',
  height: '48px',
  backgroundColor: '#23A4A4',
  fontSize: 16,
  marginTop: 24,
});