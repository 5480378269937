import { FC, useState } from 'react';
import { Embed, models, Report } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import { Button } from '@mui/material';
import '../../../assets/css/fontawesome.css';
import '../../../assets/css/brands.css';
import '../../../assets/css/solid.css';
import { PowerBIDownloadModal } from './pbi/PowerBIDownloadModal';
import { trackGoogleAnalyticsClickEvent } from 'helpers/analytics';
import { useAppSelector } from 'store/hooks';
import { BillingCurrentPlanSeatsModal } from 'components/account/billing/modals/BillingCurrentPlanSeatsModal';

export type PowerBIReportViewProps = {
  reportId?: string;
  groupId?: string;
  embedUrl: string | undefined;
  accessToken: string | undefined;
};

export const PowerBIReportView: FC<PowerBIReportViewProps> = ({ embedUrl, accessToken, reportId, groupId }) => {
  const [openDownload, setOpenDownload] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const partnerId = useAppSelector((state) => state.app.partnerId);
  const partnerName = useAppSelector((state) => state.app.partnerName);
  const selectedNodeName = useAppSelector((state) => state.app.selectedNodeName);
  const userAccessLevels = useAppSelector((state) => state.app.userAccessLevels);
  const [showManageSeatsModal, setShowManageSeatsModal] = useState<boolean>(false);
  const [report, setReport] = useState<Report | undefined>(undefined);
  const reportClass = 'report-container';
  const reportConfig = {
    type: 'report',
    embedUrl: embedUrl,
    tokenType: models.TokenType.Embed,
    accessToken: accessToken,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: true,
        },
        pageNavigation: {
          visible: true,
        },
        selection: {
          visible: true,
        },
        syncSlicers: {
          visible: true,
        },
        visualizations: {
          expanded: false,
        },
      },
    },
  };

  return (
    <StyledContainer>
      {loaded && (
        <StyledTopContainer className="report-container-top">
          <LeftSideContainer>
            {userAccessLevels.includes('ADMIN') && (
              <StyledButton
                variant="text"
                onClick={() => {
                  trackGoogleAnalyticsClickEvent('Manage Seats', 'PowerBI Report');
                  setShowManageSeatsModal(true);
                }}
              >
                Manage Seats
              </StyledButton>
            )}
          </LeftSideContainer>
          <RightSideContainer>
            <StyledButton
              variant="text"
              onClick={() => {
                setOpenDownload(true);
              }}
            >
              Download
            </StyledButton>
          </RightSideContainer>
        </StyledTopContainer>
      )}
      <PowerBIEmbed
        embedConfig={reportConfig}
        cssClassName={reportClass}
        eventHandlers={
          new Map([
            [
              'loaded',
              function () {
                setLoaded(true);
              },
            ],
            [
              'rendered',
              function () {
                setLoaded(true);
              },
            ],
            [
              'error',
              function (event) {
                setLoaded(false);
              },
            ],
          ])
        }
        getEmbeddedComponent={(embedObject: Embed) => {
          setReport(embedObject as Report);
        }}
      />
      <PowerBIDownloadModal
        report={report}
        open={openDownload}
        reportId={reportId}
        groupId={groupId}
        onRequestClose={() => setOpenDownload(false)}
      />
      {showManageSeatsModal && partnerId && partnerName ? (
        <BillingCurrentPlanSeatsModal
          open={showManageSeatsModal}
          brandId={partnerId}
          brandName={partnerName}
          dashboardName={selectedNodeName ?? '<n/a>'}
          onClose={() => {
            setShowManageSeatsModal(false);
          }}
        />
      ) : null}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  .report-container-top {
    height: 38px;
  }
  .report-container {
    height: calc(100% - 40px);
  }
  flex-direction: column;
`;

const StyledTopContainer = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  margin-right: 20px;
  gap: 8px;
`;

const LeftSideContainer = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  margin-left: 20px;
  justify-content: flex-start;
  gap: 8px;
  width: 50%;
`;

const RightSideContainer = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  margin-right: 20px;
  justify-content: flex-end;
  gap: 8px;
  width: 50%;
`;

export const PBIMutations = gql`
  mutation PbiExport(
    $reportId: String!
    $groupId: String!
    $format: PowerBIReportExportFormat!
    $bookmarkState: String
    $pages: [PowerBIReportExportPageInput!]
  ) {
    exportPowerBIReport(
      reportId: $reportId
      groupId: $groupId
      format: $format
      bookmarkState: $bookmarkState
      pages: $pages
    ) {
      exportId
    }
  }

  mutation PbiExportRefreshStatus(
    $reportId: String!
    $groupId: String!
    $format: PowerBIReportExportFormat!
    $exportId: String!
  ) {
    refreshPowerBIReportExportStatus(reportId: $reportId, groupId: $groupId, format: $format, exportId: $exportId) {
      status
      exportCompletionPercentage
      downloadUrl
      reportName
    }
  }
`;

const StyledButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  textTransform: 'none',
});
