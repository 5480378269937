import Typography from '@mui/material/Typography';
import styled from 'styled-components';

import { AuthorizeButton } from 'components/authorizeButton/AuthorizeButton';
import { Value } from 'components/value/Value';
import { useConnectAccountContext } from 'context/connectAccountContext';
import { PublicPartnerType } from 'graphql/types';

export const ConnectAccountConfirmation = () => {
  const { brandName, accountType, marketplace } = useConnectAccountContext();

  let accountTypeString = 'N/A';
  switch (accountType) {
    case PublicPartnerType.Sellercentral:
      accountTypeString = 'Seller Central';
      break;

    case PublicPartnerType.Vendorcentral:
      accountTypeString = 'Vendor Central';
      break;

    case PublicPartnerType.Advertising:
      accountTypeString = 'Advertising';
      break;
  }

  const haveAllData = !!brandName && !!accountType && !!marketplace;

  return (
    <>
      <Typography variant={'subtitle2'}>You will now authorize Reason Automation to access your Amazon data</Typography>

      <StyledGrid>
        <Value label={'Brand'}>{brandName}</Value>
        <Value label={'Account Type'}>{accountTypeString}</Value>
        <Value label={'Marketplace'}>{marketplace}</Value>
      </StyledGrid>

      <AuthorizeButton
        disabled={!haveAllData}
        error={!haveAllData ? 'Missing information; go back and fill in missing fields' : ''}
      />
    </>
  );
};

const StyledGrid = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 4px;
  margin: 16px 0;
  padding: 0 24px;
`;
