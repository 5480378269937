import { Button, Typography } from '@mui/material';
import { Modal } from 'components/modal/modal';
import { FC } from 'react';
import styled from 'styled-components';
import { Warning } from '@mui/icons-material';
import { Link } from 'react-router-dom';

type MissingPermissionsModalProps = {
  open: boolean;
  onOK: () => void;
  onContact: () => void;
};

export const MissingPermissionsModal: FC<MissingPermissionsModalProps> = ({ open, onOK, onContact }) => {
  const headerNode = () => {
    return (
      <StyledHeaderContainer>
        <Warning sx={{ fontSize: 20 }} />
        <Typography sx={{ fontWeight: '500' }} variant={'h5'}>
          Missing permissions
        </Typography>
        <Warning sx={{ fontSize: 20 }} />
      </StyledHeaderContainer>
    );
  }
  return (
    <Modal width={640} height={480} open={open} onClickOutside={onOK} title={headerNode()}>
      <StyledContainer>
        <StyledNormalTypography>
          We are missing permissions to access this report for you. Please verify that you have granted the correct permissions.
        </StyledNormalTypography>
        <StyledAction style={{ marginTop: 24 }} to={'https://www.google.com'} target='_blank'>Vendor permissions guide</StyledAction>
        <StyledAction to={'https://www.apple.com'} target='_blank'>Seller permissions guide</StyledAction>
        <StyledAction to={'https://www.github.com'} target='_blank'>Advertising permissions guide</StyledAction>
        <StyledOKButton variant={'contained'} onClick={onOK}>
          OK
        </StyledOKButton>
        <StyledContactButton onClick={onContact}>
          Contact support
        </StyledContactButton>
      </StyledContainer>
    </Modal>
  );
};

const StyledHeaderContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: 12px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  gap: 16px;
`;

const StyledNormalTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
  textAlign: 'center',
});

const StyledAction = styled(Link)({
  fontSize: 16,
  fontWeight: 500,
  color: '#23A4A4',
});

const StyledOKButton = styled(Button)({
  textTransform: 'none',
  width: '200px',
  height: '48px',
  backgroundColor: '#23A4A4',
  fontSize: 16,
  marginTop: 24,
});

const StyledContactButton = styled(Button)({
  textTransform: 'none',
  width: '200px',
  height: '48px',
  color: '#000',
  fontSize: 16,
  fontWeight: 'bold',
  marginBottom: 'auto',
});